/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import style from "./pricing.module.scss";
import Mainnav from "../../components/mainNavbar/MainNav";
import Footer from "../../components/footer/footer";
import { useGetPlans } from "../../query/useSubscription";
import WordBreak from "../../components/wordbreak";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "../../context/authContext";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
const pricingData = [
  "Instant tap to get AI generated reviews",
  "Real-Time Tracking of Customer Reviews",
  "Hassle-Free Review Collection under 30 seconds",
  "Faster Reviews Without Manual Typing",
];

const Pricing = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    window.scrollTo(0, 0);
    navigate("/sign-up");
  };
  const { subscriptionPlan, token } = useAuth();
  const { data: plans } = useGetPlans();
  const UpdatedPlans = Array.isArray(plans?.data)
    ? plans.data.sort((a, b) => a.price - b.price)
    : [];
  const [selectedPlan, setSelectedPlan] = useState(0);

  // const currencyToSymbolCode = {
  //   INR: 8377,
  //   USD: 36,
  //   GBP: 163,
  // };

  // Currency symbol will be rendered in "en-US" locale for every currency.
  const planCurrency = UpdatedPlans[selectedPlan]?.currency;

  const cardPrice = () => {
    switch (planCurrency) {
      case "AED":
        return <span>30 AED</span>;
      case "USD":
        return <span>10 &#36;</span>;
      case "GBP":
        return <span>7 &#163;</span>;
      case "INR":
        return <span>500 &#8377;</span>;
    }
  };

  // const price =  {
  //   "3 Months": 2999,
  //   "6 Months": 1099,
  //   "1 Year": 599,
  // }

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Helmet>
        <title>Pricing - Magicreview</title>
        <link rel="canonical" href="https://www.magicreview.ai/pricing" />
      </Helmet>

      <div className={`w-full bg-white text-black`}>
        <Mainnav />
        <div className="font-poppins text-center font-medium text-3xl lg:text-5xl leading-tight">
          One simple fee, <WordBreak /> Unlimited AI Review.
        </div>
        <div className="font-poppins text-center text-[18px] mt-3">
          Pick the plan that suits your needs the most.
        </div>
        <section className="w-full relative font-poppins container text-[#333333] space-y-6 pt-10 z-10 pb-10 mt-10">
          <div className="bg-lightpurple shadow-sm rounded-[33px] max-w-[716px] w-full px-5 space-y-6 py-[70px] m-auto">
            <div className="w-[90%] lg:w-[70%] mx-auto flex flex-col justify-center items-center">
              <div className="bg-white w-full m-auto px-1 py-2 justify-center rounded-[10px] sm:rounded-[16px] flex flex-wrap xms:flex-nowrap gap-1 md:gap-2 items-center text-xs sm:text-sm md:text-base lg:text-lg text-nowrap ">
                {UpdatedPlans?.map((item, id) => {
                  return (
                    <span
                      key={id}
                      className={`rounded-[5px] sm:rounded-[8px]  flex justify-center items-center px-3 sm:px-5 py-2 sm:py-3 ${selectedPlan == id && "bg-newPrimary text-white"} cursor-pointer transition-all w-full xms:w-1/4 text-center duration-500`}
                      onClick={(e) => setSelectedPlan(id)}
                    >
                      {item?.short_text}
                    </span>
                  );
                })}
              </div>

              <div className="px-5 py-2 text-[#FF0000] border border-[#FF0000] rounded-[18px] mt-[44px] font-poppins text-base mb-[15px]">
                {UpdatedPlans[selectedPlan]?.secondary_text}
              </div>

              <h2 className="text-[#333333] font-poppins text-[40px] lg:text-[67px] font-extrabold text-center mt-3">
                {(UpdatedPlans[selectedPlan]?.discount !== 0
                  ? UpdatedPlans[selectedPlan]?.price_after_discount
                  : UpdatedPlans[selectedPlan]?.price
                )?.toLocaleString("en-US", {
                  style: "currency",
                  currency: planCurrency,
                })}
              </h2>
              {UpdatedPlans[selectedPlan]?.discount !== 0 ? (
                UpdatedPlans[selectedPlan]?.is_relative_discount_price ? (
                  <div className="flex gap-[31px] items-center font-poppins mb-[27px]">
                    <h2 className="text-[32px] text-[#333333] line-through">
                      {UpdatedPlans[selectedPlan]?.price?.toLocaleString(
                        "en-US",
                        { style: "currency", currency: planCurrency }
                      )}
                    </h2>
                    <button className="bg-[#73DF07] rounded-[9px] px-5 py-1 text-white text-[20px] ">
                      {UpdatedPlans[selectedPlan]?.discount?.toLocaleString(
                        "en-US"
                      )}
                      % OFF{" "}
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-[31px] items-center font-poppins mb-[27px]">
                    <h2 className="text-[26px] md:text-[32px] text-[#333333] line-through text-nowrap">
                      {UpdatedPlans[selectedPlan]?.price?.toLocaleString(
                        "en-US",
                        { style: "currency", currency: planCurrency }
                      )}
                    </h2>
                    <button className="bg-[#73DF07] rounded-[9px] px-5 py-1 text-white text-base  md:text-[20px] text-nowrap ">
                      {/* {UpdatedPlans[selectedPlan]?.discount?.toLocaleString(
                        "en-US",
                        { style: "currency", currency: planCurrency }
                      )}{" "} */}
                      {UpdatedPlans[selectedPlan]?.discount_percent}% OFF{" "}
                    </button>
                  </div>
                )
              ) : null}
            </div>

            <div className="flex gap-x-3 justify-center items-center lg:text-lg text-xs">
              <p className="text-[#FF0000]">NOTE:</p>
              <p className="text-[#1F1E1E] font-medium">
                MagicReview Card: {cardPrice()}/ Card
              </p>
            </div>

            <div className="max-w-[476.79px] mx-auto w-full">
              {pricingData.map((item, index) => (
                <div
                  key={uuidv4()}
                  className={`
      ${index === pricingData.length - 1 ? "border-none" : "border-b border-[#EAEAEA]"}
      flex font-poppins text-[#1F1E1E] lg:items-center items-start text-start gap-[13px] lg:text-lg text-xs w-full mb-3 pb-2`}
                >
                  <img
                    src="/check.svg"
                    alt="check"
                    className="w-[24px] h-[24px]"
                  />
                  {item}
                </div>
              ))}

              <button
                className="bg-[#4F75FA] border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white border  font-poppins w-full mt-10 py-3 rounded-[10px] text-white"
                onClick={handleNavigate}
              >
                Subscribe Now
              </button>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-[#4F75FA] w-full h-[40px] z-0 -mt-[150px] pb-[100px]"></div>
      <Footer pricing={true}/>
    </div>
  );
};

export default Pricing;
