/* eslint-disable react/prop-types */
import { useNavigate } from "react-router";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useEffect, useState } from "react";
import ConfettiComponent from "./ConfettiComponent";

const GetStartedModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const [fireConfetti, setFireConfetti] = useState(false);
  const userName =
    sessionStorage.getItem("username") && sessionStorage.getItem("username");

  useEffect(() => {
    setFireConfetti(true);
    // const timeoutId = setTimeout(() => {
    //   setFireConfetti(false);
    // }, 3000);

    // return () => {
    //   clearTimeout(timeoutId);
    // };
  }, []);
  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(!isOpen);
      }}
    >
      <DialogContent className="pb-0 md:max-w-4xl">
        <DialogHeader className={"hidden"}>
          <DialogTitle></DialogTitle>
        </DialogHeader>
        <div className="flex rounded-lg overflow-hidden font-pro lg:min-h-[80vh]">
          <div className="lg:w-1/2 hidden lg:flex bg-[#f0f5ff]  flex-col justify-center items-center">
            <img src="/welcome.png" alt="" className="w-full h-fit" />
          </div>
          <div className="lg:w-1/2 w-full flex  flex-col px-5 lg:px-10 lg:pt-16 lg:pb-8 pt-7 pb-5 justify-between">
            <div className="space-y-7 lg:w-[80%] w-full">
              <p className="font-semibold text-2xl lg:text-[38px] leading-[45px]">
                Welcome, <br />
                {userName || "User"}
              </p>
              <p className="font-[15px] text-[#4f4f4f] dark:text-white leading-[22px]">
                Your AI-powered assistant for generating reviews, optimizing
                SEO, and boosting your online presence.
              </p>
              <p className="font-[15px] text-[#4f4f4f] dark:text-white leading-[22px]">
                Get ready to simplify your content creation, drive more traffic,
                and increase customer engagement—all from one intuitive
                platform. Explore the tools at your disposal, track your
                progress, and let the magic happen!
              </p>
            </div>
            <div className="lg:w-[80%] w-full space-y-5">
              <div className="w-full h-[1.5px] bg-gray-100"></div>
              <div className="w-full flex items-center gap-3">
                <Button
                  className="font-poppins text-sm font-medium"
                  variant="outline"
                  onClick={() => navigate("/dashboard?referrer=verify-email")}
                >
                  Take a tour
                </Button>
                <Button
                  className="font-poppins text-sm font-medium w-full"
                  onClick={() => setIsOpen(false)}
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* <ConfettiComponent fire={fireConfetti} /> */}
      </DialogContent>
    </Dialog>
  );
};

export default GetStartedModal;
