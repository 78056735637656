import { useState } from "react";
import {
  useGetAirbnbSettings,
  useGetBookingSettings,
  useGetSettings,
  useGetTripSettings,
  useGetTrustPilotSettings,
  useGetYelpSettings,
  usePostAirbnbToggle,
  usePostBookingToggle,
  usePostEnableAirbnbSupport,
  usePostEnableBookingSupport,
  usePostEnableSupport,
  usePostEnableTripSupport,
  usePostEnableTrustPilotSupport,
  usePostEnableYelpSupport,
  usePostToggle,
  usePostTripToggle,
  usePostTrustPilotToggle,
  usePostYelpToggle,
  usePutUpdateAirbnbSettings,
  usePutUpdateBookingSettings,
  usePutUpdateSettings,
  usePutUpdateTripSettings,
  usePutUpdateTrustPilotSettings,
  usePutUpdateYelpSettings,
} from "../../../query/useAuthentication";
import Layout from "../component/Layout";
import LinkedAccount from "./components/LinkedAccount";
import Google from "../../../assets/reviewservices/google.svg";
import TrustPilot from "../../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../../assets/reviewservices/yelp.svg";
import Booking from "../../../assets/reviewservices/booking.svg";
import Airbnb from "../../../assets/reviewservices/airbnb.svg";
import TripAdvisor from "../../../assets/reviewservices/tripadvisor.svg";
import { toast } from "sonner";
import { Link } from "lucide-react";
import Loading from "../../../components/loading/loading";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";

const Integration = () => {
  const [isSupport, setIsSupport] = useState(false);
  const [isActiveGoogle, setIsActiveGoogle] = useState(false);
  const [isOpenGoogleModal, setIsOpenGoogleModal] = useState(false);
  const [googleLink, setGoogleLink] = useState("");

  const [isTrustPilotSupport, setIsTrustPilotSupport] = useState(false);
  const [isActiveTrustpilot, setIsActiveTrustpilot] = useState(false);
  const [isOpenTrustpilotModal, setIsOpenTrustpilotModal] = useState(false);
  const [trustpilotLink, setTrustpilotLink] = useState("");

  const [isYelpSupport, setIsYelpSupport] = useState(false);
  const [isActiveYelp, setIsActiveYelp] = useState(false);
  const [isOpenYelpModal, setIsOpenYelpModal] = useState(false);
  const [yelpLink, setYelpLink] = useState("");

  const [isBookingSupport, setIsBookingSupport] = useState(false);
  const [isActiveBooking, setIsActiveBooking] = useState(false);
  const [isOpenBookingModal, setIsOpenBookingModal] = useState(false);
  const [bookingLink, setBookingLink] = useState("");

  const [isAirSupport, setIsAirSupport] = useState(false);
  const [isActiveAir, setIsActiveAir] = useState(false);
  const [isOpenAirModal, setIsOpenAirModal] = useState(false);
  const [airLink, setAirLink] = useState("");

  const [isTripSupport, setIsTripSupport] = useState(false);
  const [isActiveTrip, setIsActiveTrip] = useState(false);
  const [isOpenTripModal, setIsOpenTripModal] = useState(false);
  const [tripLink, setTripLink] = useState("");

  const [isActiveCustom, setIsActiveCustom] = useState(false);
  const [isOpenCustomModal, setIsOpenCustomModal] = useState(false);
  const [customLink, setCustomLink] = useState("");

  const {
    data: settings,
    refetch: refetchSettings,
    isLoading,
    isFetching,
  } = useGetSettings({
    onSuccess: (res) => {
      if (res?.is_enabled === "False") {
        setIsSupport(false);
      } else {
        setIsSupport(true);
      }

      setIsActiveGoogle(res.active);
      setGoogleLink(res.review_url);
    },
    onError: (err) => {
      if (err.response.status === 403) {
        setIsSupport(false);
      }
    },
  });
  const { mutate: updateSettings, isLoading: updateSettingsLoading } =
    usePutUpdateSettings();
  const { mutate: enableSupport, isLoading: enableSupportLoading } =
    usePostEnableSupport();
  const { mutate: toggle, isLoading: toggleLoading } = usePostToggle();

  const { data: settingsYelp, refetch: refetchYelpSettings } =
    useGetYelpSettings({
      onSuccess: (res) => {
        if (res?.is_enabled === "False") {
          setIsYelpSupport(false);
        } else {
          setIsYelpSupport(true);
        }
        setIsActiveYelp(res.active);
        setYelpLink(res.review_url);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          setIsYelpSupport(false);
        }
      },
    });
  const { mutate: updateYelpSettings, isLoading: updateYelpSettingsLoading } =
    usePutUpdateYelpSettings();
  const { mutate: enableYelpSupport, isLoading: enableYelpSupportLoading } =
    usePostEnableYelpSupport();
  const { mutate: yelpToggle, isLoading: toggleYelpLoading } =
    usePostYelpToggle();

  const { data: settingsTrustPilot, refetch: refetchTrustPilotSettings } =
    useGetTrustPilotSettings({
      onSuccess: (res) => {
        if (res?.is_enabled === "False") {
          setIsTrustPilotSupport(false);
        } else {
          setIsTrustPilotSupport(true);
        }
        setIsActiveTrustpilot(res.active);
        setTrustpilotLink(res.review_url);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          setIsTrustPilotSupport(false);
        }
      },
    });
  const {
    mutate: updateTrustPilotSettings,
    isLoading: updateTrustPilotSettingsLoading,
  } = usePutUpdateTrustPilotSettings();
  const {
    mutate: enableTrustPilotSupport,
    isLoading: enableTrustPilotSupportLoading,
  } = usePostEnableTrustPilotSupport();
  const { mutate: trustPilotToggle, isLoading: toggleTrustPilotLoading } =
    usePostTrustPilotToggle();

  const { data: settingsBooking, refetch: refetchBookingSettings } =
    useGetBookingSettings({
      onSuccess: (res) => {
        if (res?.is_enabled === "False") {
          setIsBookingSupport(false);
        } else {
          setIsBookingSupport(true);
        }
        setIsActiveBooking(res.active);
        setBookingLink(res.review_url);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          setIsBookingSupport(false);
        }
      },
    });
  const {
    mutate: updateBookingSettings,
    isLoading: updateBookingSettingsLoading,
  } = usePutUpdateBookingSettings();
  const {
    mutate: enableBookingSupport,
    isLoading: enableBookingSupportLoading,
  } = usePostEnableBookingSupport();
  const { mutate: bookingToggle, isLoading: toggleBookingLoading } =
    usePostBookingToggle();

  const { data: settingsAirbnb, refetch: refetchAirbnbSettings } =
    useGetAirbnbSettings({
      onSuccess: (res) => {
        if (res?.is_enabled === "False") {
          setIsAirSupport(false);
        } else {
          setIsAirSupport(true);
        }
        setIsActiveAir(res.active);
        setAirLink(res.review_url);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          setIsAirSupport(false);
        }
      },
    });
  const {
    mutate: updateAirbnbSettings,
    isLoading: updateAirbnbSettingsLoading,
  } = usePutUpdateAirbnbSettings();
  const { mutate: enableAirbnbSupport, isLoading: enableAirbnbSupportLoading } =
    usePostEnableAirbnbSupport();
  const { mutate: airbnbToggle, isLoading: toggleAirbnbLoading } =
    usePostAirbnbToggle();

  const { data: settingsTrip, refetch: refetchTripSettings } =
    useGetTripSettings({
      onSuccess: (res) => {
        if (res?.is_enabled === "False") {
          setIsTripSupport(false);
        } else {
          setIsTripSupport(true);
        }
        setIsActiveTrip(res.active);
        setTripLink(res.review_url);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          setIsTripSupport(false);
        }
      },
    });
  const { mutate: updateTripSettings, isLoading: updateTripSettingsLoading } =
    usePutUpdateTripSettings();
  const { mutate: enableTripSupport, isLoading: enableTripSupportLoading } =
    usePostEnableTripSupport();
  const { mutate: tripToggle, isLoading: toggleTripLoading } =
    usePostTripToggle();

  const handleEnableSupport = () => {
    enableSupport(
      {},
      {
        onSuccess: (res) => {
          refetchSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEnableYelpSupport = () => {
    enableYelpSupport(
      {},
      {
        onSuccess: (res) => {
          refetchYelpSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEnableTrustPilotSupport = () => {
    enableTrustPilotSupport(
      {},
      {
        onSuccess: (res) => {
          refetchTrustPilotSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEnableBookingSupport = () => {
    enableBookingSupport(
      {},
      {
        onSuccess: (res) => {
          refetchBookingSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEnableAirbnbSupport = () => {
    enableAirbnbSupport(
      {},
      {
        onSuccess: (res) => {
          refetchAirbnbSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEnableTripSupport = () => {
    enableTripSupport(
      {},
      {
        onSuccess: (res) => {
          refetchTripSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleGoogle = () => {
    toggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchSettings();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleTrustpilot = () => {
    trustPilotToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchTrustPilotSettings();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };
  const toggleYelp = () => {
    yelpToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchYelpSettings();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleBooking = () => {
    bookingToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchBookingSettings();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleAirbnb = () => {
    airbnbToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchAirbnbSettings();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleTrip = () => {
    tripToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchTripSettings();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateSettings = () => {
    updateSettings(
      {
        review_url: googleLink,
        place_id: null,
      },
      {
        onSuccess: (res) => {
          refetchSettings();
          toast.success("Updated google review link successfully");
          setIsOpenGoogleModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateYelpSettings = () => {
    updateYelpSettings(
      {
        review_url: yelpLink,
      },
      {
        onSuccess: (res) => {
          refetchYelpSettings();
          toast.success("Updated yelp review link successfully");
          setIsOpenYelpModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateTrustPilotSettings = () => {
    updateTrustPilotSettings(
      {
        review_url: trustpilotLink,
      },
      {
        onSuccess: (res) => {
          refetchTrustPilotSettings();
          toast.success("Updated trust pilot link successfully");
          setIsOpenTrustpilotModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateBookingSettings = () => {
    updateBookingSettings(
      {
        review_url: bookingLink,
      },
      {
        onSuccess: (res) => {
          refetchBookingSettings();
          toast.success("Updated booking review link successfully");
          setIsOpenBookingModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateAirbnbSettings = () => {
    updateAirbnbSettings(
      {
        review_url: airLink,
      },
      {
        onSuccess: (res) => {
          refetchAirbnbSettings();
          toast.success("Updated airbnb review link successfully");
          setIsOpenAirModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateTripSettings = () => {
    updateTripSettings(
      {
        review_url: tripLink,
      },
      {
        onSuccess: (res) => {
          refetchTripSettings();
          toast.success("Updated Trip Advisor review link successfully");
          setIsOpenTripModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleGoogleLogin = (res) => {
    console.log("Google credential", res);
  };

  const handleGoogleAccess = useGoogleLogin({
    onSuccess: (res) => {
      console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    scope: "https://www.googleapis.com/auth/business.manage",
  });

  const toggleCustom = () => {
    setIsActiveCustom((prev) => !prev);
  };

  return (
    <Layout title="Integrations">
      <div className="my-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 lg:py-8 min-h-[calc(100vh-140px)] px-5 lg:px-[30.2px]">
        <div className="mt-5">
          <p className="font-pro font-semibold text-base">Link your accounts</p>
        </div>

        <hr className="mt-6" />
        {isLoading || isFetching ? (
          <Loading />
        ) : (
          <div
            className="
             grid grid-cols-1 md:grid-cols-2 gap-y-5 lg:gap-x-10 mt-10
          "
          >
            <LinkedAccount
              name={"Google My Business"}
              image={Google}
              isActiveLink={isActiveGoogle}
              setIsActiveLink={setIsActiveGoogle}
              isOpenModal={isOpenGoogleModal}
              setIsOpenmodal={setIsOpenGoogleModal}
              link={googleLink}
              setLink={setGoogleLink}
              toggle={toggleGoogle}
              isLoading={updateSettingsLoading}
              isEnableLoading={enableSupportLoading}
              isToggleLoading={toggleLoading}
              handleClick={handleUpdateSettings}
              isComingSoon={false}
              isSupport={isSupport}
              onEnableSupport={handleEnableSupport}
            />
            <LinkedAccount
              name={"Trustpilot"}
              image={TrustPilot}
              isActiveLink={isActiveTrustpilot}
              setIsActiveLink={setIsActiveTrustpilot}
              isOpenModal={isOpenTrustpilotModal}
              setIsOpenmodal={setIsOpenTrustpilotModal}
              link={trustpilotLink}
              setLink={setTrustpilotLink}
              toggle={toggleTrustpilot}
              isLoading={updateTrustPilotSettingsLoading}
              isEnableLoading={enableTrustPilotSupportLoading}
              isToggleLoading={toggleTrustPilotLoading}
              handleClick={handleUpdateTrustPilotSettings}
              isComingSoon={false}
              isSupport={isTrustPilotSupport}
              onEnableSupport={handleEnableTrustPilotSupport}
            />
            <LinkedAccount
              name={"Yelp"}
              image={Yelp}
              isActiveLink={isActiveYelp}
              setIsActiveLink={setIsActiveYelp}
              isOpenModal={isOpenYelpModal}
              setIsOpenmodal={setIsOpenYelpModal}
              link={yelpLink}
              setLink={setYelpLink}
              toggle={toggleYelp}
              isLoading={updateYelpSettingsLoading}
              isEnableLoading={enableYelpSupportLoading}
              isToggleLoading={toggleYelpLoading}
              handleClick={handleUpdateYelpSettings}
              isComingSoon={false}
              isSupport={isYelpSupport}
              onEnableSupport={handleEnableYelpSupport}
            />
            <LinkedAccount
              name={"Booking"}
              image={Booking}
              isActiveLink={isActiveBooking}
              setIsActiveLink={setIsActiveBooking}
              isOpenModal={isOpenBookingModal}
              setIsOpenmodal={setIsOpenBookingModal}
              link={bookingLink}
              setLink={setBookingLink}
              toggle={toggleBooking}
              isLoading={updateBookingSettingsLoading}
              isEnableLoading={enableBookingSupportLoading}
              isToggleLoading={toggleBookingLoading}
              handleClick={handleUpdateBookingSettings}
              isComingSoon={false}
              isSupport={isBookingSupport}
              onEnableSupport={handleEnableBookingSupport}
            />
            <LinkedAccount
              name={"Airbnb"}
              image={Airbnb}
              isActiveLink={isActiveAir}
              setIsActiveLink={setIsActiveAir}
              isOpenModal={isOpenAirModal}
              setIsOpenmodal={setIsOpenAirModal}
              link={airLink}
              setLink={setAirLink}
              toggle={toggleAirbnb}
              isLoading={updateAirbnbSettingsLoading}
              isEnableLoading={enableAirbnbSupportLoading}
              isToggleLoading={toggleAirbnbLoading}
              handleClick={handleUpdateAirbnbSettings}
              isComingSoon={false}
              isSupport={isAirSupport}
              onEnableSupport={handleEnableAirbnbSupport}
            />
            <LinkedAccount
              name={"Trip Advisor"}
              image={TripAdvisor}
              isActiveLink={isActiveTrip}
              setIsActiveLink={setIsActiveTrip}
              isOpenModal={isOpenTripModal}
              setIsOpenmodal={setIsOpenTripModal}
              link={tripLink}
              setLink={setTripLink}
              toggle={toggleTrip}
              isLoading={updateTripSettingsLoading}
              isEnableLoading={enableTripSupportLoading}
              isToggleLoading={toggleTripLoading}
              handleClick={handleUpdateTripSettings}
              isComingSoon={false}
              isSupport={isTripSupport}
              onEnableSupport={handleEnableTripSupport}
            />
            {/* <LinkedAccount
            name={"Custom Link"}
            icon={<Link className="w-5 h-5" />}
            isActiveLink={isActiveCustom}
            setIsActiveLink={setIsActiveCustom}
            isOpenModal={isOpenCustomModal}
            setIsOpenmodal={setIsOpenCustomModal}
            link={customLink}
            setLink={setCustomLink}
            toggle={toggleCustom}
            isLoading={false}
            handleClick={handleUpdateSettings}
            isComingSoon={false}
            isSupport={isSupport}
            onEnableSupport={handleEnableSupport}
          /> */}
            {/* <GoogleLogin
              onSuccess={(res) => handleGoogleLogin(res)}
              onError={(err) => console.log(err)}
              size="large"
              shape="pill"
              width={300}
              text="continue_with"
              useOneTap
              scope="https://www.googleapis.com/auth/business.manage"
            />
            <button onClick={(res) => handleGoogleAccess(res)}>
              Google Access
            </button> */}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Integration;
