/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useEffect, useRef, useState } from "react";
import {
  useGetEmployeeIntegrations,
  usePostAddEmployee,
  usePutUpdateEmployee,
} from "../../../../query/useEmployees";
import { toast } from "sonner";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  useDeleteTag,
  useGetQuestionSets,
  useGetTags,
  usePostAddTag,
} from "../../../../query/useEntity";
import { Asterisk, ChevronDown, Trash2 } from "lucide-react";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { Switch } from "../../../../components/ui/switch";
import { LogoLoading } from "../../../../components/loading/loading";

const EditStaffModal = ({
  openModal,
  setOpenModal,
  refetch,
  entityId,
  data,
  setData,
  // tagsFromApi,
  // refetchTags,
}) => {
  const tagBoxRef = useRef(null);
  const redirectBoxRef = useRef(null);
  const questionSetRef = useRef(null);
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [tags, setTags] = useState([]);
  const [questionSet, setQuestionSet] = useState(null);
  const [isShowName, setIsShowName] = useState(true);
  const [isVoiceReview, setIsVoiceReview] = useState(true);
  const [inputTag, setInputTag] = useState("");
  const [isOpenTagBox, setIsOpenTagBox] = useState(false);
  const [deleteTagId, setDeleteTagId] = useState();
  const [redirects, setRedirects] = useState([]);
  const [isOpenRedirectBox, setIsOpenRedirectBox] = useState(false);
  const [inputRedirect, setInputRedirect] = useState("");
  const [isOpenQuestionSetBox, setIsOpenQuestionSetBox] = useState(false);

  const { mutate: updateEmployee, isLoading: updateEmployeeLoading } =
    usePutUpdateEmployee();
  const { data: tagsFromApi, refetch: refetchTags } = useGetTags();
  const { data: emplyeeIntegrations } = useGetEmployeeIntegrations();
  const { mutate: addTag } = usePostAddTag();
  const { mutate: deleteTag, isLoading: deleteLoading } = useDeleteTag();

  const { data: questionSets } = useGetQuestionSets();

  const isLoading = updateEmployeeLoading;

  const extractedTags = tagsFromApi?.filter((item) => item.tag === inputTag);

  const handleAddTag = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!tags?.includes(inputTag) && inputTag.trim() !== "") {
        addTag(
          {
            tag: inputTag,
            entity: entityId,
          },
          {
            onSuccess: (res) => {
              refetchTags();
            },
          }
        );

        setTags([...tags, inputTag.trim()]);
        setInputTag("");
      }
    }
  };

  const handleAddClickTag = () => {
    if (!tags?.includes(inputTag) && inputTag.trim() !== "") {
      addTag(
        {
          tag: inputTag,
          entity: entityId,
        },
        {
          onSuccess: (res) => {
            refetchTags();
          },
        }
      );

      setTags([...tags, inputTag.trim()]);
      setInputTag("");
    }
  };

  const handleDeleteTag = (item) => {
    setDeleteTagId(item?.id);
    deleteTag(
      {
        name: item?.tag,
        entity: entityId,
        id: item?.id,
      },
      {
        onSuccess: (res) => {
          refetchTags();
          setTags((prev) => prev?.filter((tag) => tag !== item?.tag));
        },
      }
    );
  };

  const handleClick = () => {
    if (!name) {
      toast.error("Name field must not be blank");
      return;
    }
    if (label?.length > 50) {
      toast.error("Label field must have maximum 50 characters");
      return;
    }
    if (data) {
      updateEmployee(
        {
          id: data?.id,
          name: name,
          tags: tags || [],
          label: label,
          default_set: questionSet,
          show: isShowName,
          voice_review: isVoiceReview,
          redirect: redirects,
        },
        {
          onSuccess: (res) => {
            if (res.message) {
              toast.success(res.message);
            } else {
              toast.success("Link has been updated successfully");
            }
            refetch();
            setOpenModal();
          },
          onError: (err) => {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else if (err.response.data.tag) {
              toast.error(err.response.data.tag?.[0]);
            } else if (err.response.data.label) {
              toast.error(err.response.data.label?.[0]);
            } else if (err.respopnse.data.redirect?.[0]) {
              toast.error(err.response.data.redirect?.[0]);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    }
  };

  useEffect(() => {
    if (data) {
      setName(data?.name);
      setTags(data?.tags);
      setLabel(data?.label);
      setQuestionSet(data?.default_set);
      setIsShowName(data?.show);
      setIsVoiceReview(data?.voice_review);
      setRedirects(data?.redirect);
    }
  }, [data]);

  useOnClickOutside({ ref: tagBoxRef, setIsOpen: setIsOpenTagBox });
  useOnClickOutside({ ref: redirectBoxRef, setIsOpen: setIsOpenRedirectBox });
  useOnClickOutside({
    ref: questionSetRef,
    setIsOpen: setIsOpenQuestionSetBox,
  });

  if (isLoading) {
    return <LogoLoading />;
  }

  return (
    // <Modal
    //   dismissible
    //   show={openModal}
    //   onClose={() => {
    //     setOpenModal(false);
    //   }}
    //   size={"5xl"}
    //   className="z-[1000]"
    //   theme={{
    //     content: {
    //       base: "relative p-4 h-auto",
    //       inner: "bg-white dark:bg-darkBg rounded-lg",
    //     },
    //     header: {
    //       base: "flex items-start justify-between rounded-t-lg border-b p-5 dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
    //       close: {
    //         base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
    //       },
    //     },
    //   }}
    //   popup
    // >
    //   <Modal.Header>
    //     <p className="font-pro font-medium text-xl pt-1 px-5 pb-2">
    //       Magiclink Settings
    //     </p>
    //   </Modal.Header>
    //   <Modal.Body className="h-auto lg:w-[888px]">
    //     <div className="px-5 pb-5 grid grid-cols-1 lg:grid-cols-2 gap-5">
    //       <div className="flex justify-between items-center gap-5">
    //         <p className="font-pro dark:text-white">
    //           Display &quot;Name&quot; and &quot;Role&quot; on the review page
    //         </p>
    //         <Switch
    //           checked={isShowName}
    //           onCheckedChange={() => setIsShowName((prev) => !prev)}
    //         ></Switch>
    //       </div>
    //       <div className="flex justify-between items-center gap-5">
    //         <p className="font-pro dark:text-white">Enable voice review</p>
    //         <Switch
    //           checked={isVoiceReview}
    //           onCheckedChange={() => setIsVoiceReview((prev) => !prev)}
    //         ></Switch>
    //       </div>

    //       <div className="select-name">
    //         <div className="flex items-center gap-x-1.5">
    //           <p className="font-pro font-medium dark:text-white">Name</p>
    //           <Asterisk className="text-red-500 size-4" />
    //         </div>

    //         <input
    //           type="text"
    //           className="w-full border font-pro border-[#e7e4e4] rounded-lg mt-3 dark:bg-darkLightBg dark:border-[#454545] dark:text-white"
    //           placeholder="Enter name"
    //           value={name}
    //           onChange={(e) => setName(e.target.value)}
    //         />
    //       </div>
    //       <div className="select-role">
    //         <p className="font-pro font-medium dark:text-white">Role</p>
    //         <input
    //           type="text"
    //           className="w-full border font-pro border-[#e7e4e4] rounded-lg mt-3 dark:bg-darkLightBg dark:border-[#454545] dark:text-white"
    //           placeholder="Enter role"
    //           value={label}
    //           onChange={(e) => setLabel(e.target.value)}
    //         />
    //       </div>

    //       <div className="select-tags">
    //         <p className="font-pro font-medium dark:text-white">Tags</p>
    //         <div className="relative">
    //           <TagsInput
    //             value={tags}
    //             onChange={(tags) => setTags(tags)}
    //             inputValue={inputTag}
    //             onChangeInput={(value) => setInputTag(value)}
    //             className="w-full border font-pro !border-[#e7e4e4] dark:bg-darkLightBg dark:!border-[#454545] dark:text-white rounded-lg mt-3 px-3 pt-1"
    //             inputProps={{
    //               onKeyDown: handleAddTag,
    //               onFocus: () => setIsOpenTagBox(true),
    //             }}
    //             renderInput={({ onChange, value, ...other }) => (
    //               <input
    //                 type="text"
    //                 onChange={onChange}
    //                 // onKeyDown={handleAddTag}
    //                 value={value}
    //                 {...other}
    //                 placeholder=""
    //                 className="!border-0 w-24 focus:!border-0 focus:!outline-none focus:!appearance-none focus:!ring-0 dark:!bg-darkLightBg  dark:text-white"
    //               />
    //             )}
    //             renderTag={({
    //               tag,
    //               key,
    //               disabled,
    //               onRemove,
    //               classNameRemove,
    //               getTagDisplayValue,
    //               ...other
    //             }) => (
    //               <p
    //                 key={key}
    //                 {...other}
    //                 className="react-tagsinput-tag !bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !w-fit !text-black  dark:!text-white"
    //               >
    //                 {getTagDisplayValue(tag)}
    //                 {!disabled && (
    //                   <a
    //                     className={`${classNameRemove} !text-base before:!content-["x"] font-montserrat before:pl-2 font-medium`}
    //                     onClick={(e) => onRemove(key)}
    //                   />
    //                   // <X className="w-3.5 h-3.5" onClick={(e) => onRemove(key)} />
    //                 )}
    //               </p>
    //             )}
    //           />
    //           {isOpenTagBox && (
    //             <div
    //               className="absolute translate-y-3 left-0 w-full bg-white max-h-[200px] overflow-y-auto scrollbar-thin space-y-1 p-3 rounded-xl shadow-xl z-50 dark:bg-darkLightBg  dark:text-white"
    //               ref={tagBoxRef}
    //             >
    //               {tagsFromApi
    //                 ?.filter(
    //                   (item) =>
    //                     item?.tag
    //                       ?.toLowerCase()
    //                       ?.indexOf(inputTag?.toLowerCase()) > -1
    //                 )
    //                 ?.map((item) => (
    //                   <div
    //                     key={item}
    //                     className="flex justify-between items-center gap-x-1"
    //                   >
    //                     <div
    //                       className="w-full cursor-pointer hover:bg-gray-100 dark:hover:bg-darkBg rounded-md pt-1 px-1"
    //                       onClick={() => {
    //                         if (!tags?.includes(item?.tag)) {
    //                           setTags((prev) => [...prev, item?.tag]);
    //                         }
    //                       }}
    //                     >
    //                       <div className="!bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !w-fit !text-black dark:!text-white react-tagsinput-tag !block ">
    //                         {item?.tag}
    //                       </div>
    //                     </div>

    //                     {deleteLoading && item?.id === deleteTagId ? (
    //                       <div className="w-4 h-4 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
    //                     ) : (
    //                       <Trash2
    //                         className="w-4 h-4 cursor-pointer"
    //                         onClick={() => handleDeleteTag(item)}
    //                       />
    //                     )}
    //                   </div>
    //                 ))}
    //               {extractedTags?.length <= 0 && inputTag?.trim() !== "" ? (
    //                 <div
    //                   className="flex items-center gap-x-2 w-full cursor-pointer hover:bg-gray-100 rounded-md py-1 px-1"
    //                   onClick={handleAddClickTag}
    //                 >
    //                   <p>Create &quot;{inputTag}&quot;</p>
    //                 </div>
    //               ) : null}
    //             </div>
    //           )}
    //         </div>
    //       </div>

    //       <div className="">
    //         <p className="font-pro font-medium dark:text-white">Redirects</p>
    //         <div className="relative">
    //           <TagsInput
    //             value={redirects}
    //             onChange={(tags) => setRedirects(tags)}
    //             inputValue={inputRedirect}
    //             onChangeInput={(value) => setInputRedirect(value)}
    //             className="w-full border font-pro !border-[#e7e4e4] dark:bg-darkLightBg dark:!border-[#454545] dark:text-white rounded-lg mt-3 px-3 pt-1"
    //             inputProps={{
    //               // onKeyDown: handleAddTag,
    //               onFocus: () => setIsOpenRedirectBox(true),
    //             }}
    //             renderInput={({ onChange, value, ...other }) => (
    //               <input
    //                 type="text"
    //                 onChange={onChange}
    //                 // onKeyDown={handleAddTag}
    //                 value={value}
    //                 {...other}
    //                 placeholder=""
    //                 className="!border-0 w-24 focus:!border-0 focus:!outline-none focus:!appearance-none focus:!ring-0 dark:!bg-darkLightBg  dark:text-white"
    //               />
    //             )}
    //             renderTag={({
    //               tag,
    //               key,
    //               disabled,
    //               onRemove,
    //               classNameRemove,
    //               getTagDisplayValue,
    //               ...other
    //             }) => (
    //               <p
    //                 key={key}
    //                 {...other}
    //                 className="react-tagsinput-tag !bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !w-fit !text-black  dark:!text-white"
    //               >
    //                 {getTagDisplayValue(tag)}
    //                 {!disabled && (
    //                   <a
    //                     className={`${classNameRemove} !text-base before:!content-["x"] font-montserrat before:pl-2 font-medium`}
    //                     onClick={(e) => onRemove(key)}
    //                   />
    //                   // <X className="w-3.5 h-3.5" onClick={(e) => onRemove(key)} />
    //                 )}
    //               </p>
    //             )}
    //           />
    //           {isOpenRedirectBox && (
    //             <div
    //               className="absolute translate-y-3 left-0 w-full bg-white max-h-[200px] overflow-y-auto scrollbar-thin space-y-1 p-3 rounded-xl shadow-xl z-50 dark:bg-darkLightBg  dark:text-white"
    //               ref={redirectBoxRef}
    //             >
    //               {emplyeeIntegrations
    //                 ?.filter(
    //                   (item) =>
    //                     item
    //                       ?.toLowerCase()
    //                       ?.indexOf(inputRedirect?.toLowerCase()) > -1
    //                 )
    //                 ?.map((item) => (
    //                   <div
    //                     key={item}
    //                     className="flex justify-between items-center gap-x-1"
    //                   >
    //                     <div
    //                       className="w-full cursor-pointer hover:bg-gray-100 dark:hover:bg-darkBg rounded-md pt-1 px-1"
    //                       onClick={() => {
    //                         if (!redirects?.includes(item)) {
    //                           setRedirects((prev) => [...prev, item]);
    //                         }
    //                       }}
    //                     >
    //                       <div className="!bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !w-fit !text-black dark:!text-white react-tagsinput-tag !block ">
    //                         {item}
    //                       </div>
    //                     </div>
    //                   </div>
    //                 ))}
    //             </div>
    //           )}
    //         </div>
    //       </div>

    //       <div className="select-question-set">
    //         <p className="font-pro font-medium dark:text-white">Question set</p>
    //         <div className="w-full relative h-12" ref={questionSetRef}>
    //           <div
    //             className="px-5 h-full rounded-[7px] focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent flex items-center justify-between w-full bg-white cursor-pointer border mt-3 dark:bg-darkLightBg dark:border-[#454545] dark:text-white"
    //             onClick={() => setIsOpenQuestionSetBox((prev) => !prev)}
    //           >
    //             <p className="">
    //               {questionSet &&
    //               questionSets?.find((item) => item?.id === questionSet)
    //                 ?.name === "default"
    //                 ? "Default"
    //                 : questionSets?.find((item) => item?.id === questionSet)
    //                     ?.name}
    //             </p>
    //             <ChevronDown
    //               className={`w-5 h-5 ${isOpenQuestionSetBox && "rotate-180"} transition-all duration-200`}
    //             />
    //           </div>
    //           {isOpenQuestionSetBox && (
    //             <div className="absolute translate-y-3 left-0 w-full bg-white max-h-[200px] overflow-y-auto scrollbar-thin space-y-1 p-3 rounded-xl shadow-xl z-50 dark:bg-darkLightBg dark:text-white">
    //               {questionSets?.map((item) => (
    //                 <div
    //                   key={item?.id}
    //                   className="flex items-center justify-between group"
    //                 >
    //                   <p
    //                     className="w-full cursor-pointer group-hover:text-secondary capitalize"
    //                     onClick={() => {
    //                       setIsOpenQuestionSetBox(false);
    //                       setQuestionSet(item?.id);
    //                     }}
    //                   >
    //                     {item?.name}
    //                   </p>
    //                 </div>
    //               ))}
    //             </div>
    //           )}
    //         </div>
    //       </div>

    //       <div className="flex justify-end items-end gap-x-3">
    //         <button
    //           className="bg-white w-[121px] py-3 rounded-[7px] text-newPrimary border border-newPrimary dark:bg-darkLightBg"
    //           onClick={() => {
    //             setOpenModal(false);
    //           }}
    //         >
    //           Cancel
    //         </button>
    //         <button
    //           className="bg-newPrimary w-[121px] py-3 rounded-[7px] text-white border border-newPrimary hover:text-newPrimary hover:bg-white dark:hover:bg-darkLightBg"
    //           onClick={handleClick}
    //         >
    //           Save
    //         </button>
    //       </div>
    //     </div>
    //   </Modal.Body>
    // </Modal>
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(false);
      }}
    >
      <DialogContent className="lg:max-w-[888px]">
        <DialogHeader>Magiclink Settings</DialogHeader>

        <div className="px-6 grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div className="flex justify-between items-center gap-5">
            <p className="font-pro dark:text-white">
              Display &quot;Name&quot; and &quot;Role&quot; on the review page
            </p>
            <Switch
              checked={isShowName}
              onCheckedChange={() => setIsShowName((prev) => !prev)}
            ></Switch>
          </div>
          <div className="flex justify-between items-center gap-5">
            <p className="font-pro dark:text-white">Enable voice review</p>
            <Switch
              checked={isVoiceReview}
              onCheckedChange={() => setIsVoiceReview((prev) => !prev)}
            ></Switch>
          </div>

          <div className="select-name">
            <div className="flex items-center gap-x-1.5">
              <p className="font-pro font-medium dark:text-white">Name</p>
              <Asterisk className="text-red-500 size-4" />
            </div>

            <input
              type="text"
              className="w-full border font-pro border-[#e7e4e4] rounded-lg mt-3 dark:bg-darkLightBg dark:border-[#454545] dark:text-white"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="select-role">
            <p className="font-pro font-medium dark:text-white">Role</p>
            <input
              type="text"
              className="w-full border font-pro border-[#e7e4e4] rounded-lg mt-3 dark:bg-darkLightBg dark:border-[#454545] dark:text-white"
              placeholder="Enter role"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </div>

          <div className="select-tags">
            <p className="font-pro font-medium dark:text-white">Tags</p>
            <div className="relative">
              <TagsInput
                value={tags}
                onChange={(tags) => setTags(tags)}
                inputValue={inputTag}
                onChangeInput={(value) => setInputTag(value)}
                className="w-full border font-pro !border-[#e7e4e4] dark:bg-darkLightBg dark:!border-[#454545] dark:text-white rounded-lg mt-3 px-3 pt-1"
                inputProps={{
                  onKeyDown: handleAddTag,
                  onFocus: () => setIsOpenTagBox(true),
                }}
                renderInput={({ onChange, value, ...other }) => (
                  <input
                    type="text"
                    onChange={onChange}
                    // onKeyDown={handleAddTag}
                    value={value}
                    {...other}
                    placeholder=""
                    className="!border-0 w-24 focus:!border-0 focus:!outline-none focus:!appearance-none focus:!ring-0 dark:!bg-darkLightBg  dark:text-white"
                  />
                )}
                renderTag={({
                  tag,
                  key,
                  disabled,
                  onRemove,
                  classNameRemove,
                  getTagDisplayValue,
                  ...other
                }) => (
                  <p
                    key={key}
                    {...other}
                    className="react-tagsinput-tag !bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !w-fit !text-black  dark:!text-white"
                  >
                    {getTagDisplayValue(tag)}
                    {!disabled && (
                      <a
                        className={`${classNameRemove} !text-base before:!content-["x"] font-montserrat before:pl-2 font-medium`}
                        onClick={(e) => onRemove(key)}
                      />
                      // <X className="w-3.5 h-3.5" onClick={(e) => onRemove(key)} />
                    )}
                  </p>
                )}
              />
              {isOpenTagBox && (
                <div
                  className="absolute translate-y-3 left-0 w-full bg-white max-h-[200px] overflow-y-auto scrollbar-thin space-y-1 p-3 rounded-xl shadow-xl z-50 dark:bg-darkLightBg  dark:text-white"
                  ref={tagBoxRef}
                >
                  {tagsFromApi
                    ?.filter(
                      (item) =>
                        item?.tag
                          ?.toLowerCase()
                          ?.indexOf(inputTag?.toLowerCase()) > -1
                    )
                    ?.map((item) => (
                      <div
                        key={item}
                        className="flex justify-between items-center gap-x-1"
                      >
                        <div
                          className="w-full cursor-pointer hover:bg-gray-100 dark:hover:bg-darkBg rounded-md pt-1 px-1"
                          onClick={() => {
                            if (!tags?.includes(item?.tag)) {
                              setTags((prev) => [...prev, item?.tag]);
                            }
                          }}
                        >
                          <div className="!bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !w-fit !text-black dark:!text-white react-tagsinput-tag !block ">
                            {item?.tag}
                          </div>
                        </div>

                        {deleteLoading && item?.id === deleteTagId ? (
                          <div className="w-4 h-4 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                        ) : (
                          <Trash2
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => handleDeleteTag(item)}
                          />
                        )}
                      </div>
                    ))}
                  {extractedTags?.length <= 0 && inputTag?.trim() !== "" ? (
                    <div
                      className="flex items-center gap-x-2 w-full cursor-pointer hover:bg-gray-100 rounded-md py-1 px-1"
                      onClick={handleAddClickTag}
                    >
                      <p>Create &quot;{inputTag}&quot;</p>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>

          <div className="">
            <p className="font-pro font-medium dark:text-white">Redirects</p>
            <div className="relative">
              <TagsInput
                value={redirects}
                onChange={(tags) => setRedirects(tags)}
                inputValue={inputRedirect}
                onChangeInput={(value) => setInputRedirect(value)}
                className="w-full border font-pro !border-[#e7e4e4] dark:bg-darkLightBg dark:!border-[#454545] dark:text-white rounded-lg mt-3 px-3 pt-1"
                inputProps={{
                  // onKeyDown: handleAddTag,
                  onFocus: () => setIsOpenRedirectBox(true),
                }}
                renderInput={({ onChange, value, ...other }) => (
                  <input
                    type="text"
                    onChange={onChange}
                    // onKeyDown={handleAddTag}
                    value={value}
                    {...other}
                    placeholder=""
                    className="!border-0 w-24 focus:!border-0 focus:!outline-none focus:!appearance-none focus:!ring-0 dark:!bg-darkLightBg  dark:text-white"
                  />
                )}
                renderTag={({
                  tag,
                  key,
                  disabled,
                  onRemove,
                  classNameRemove,
                  getTagDisplayValue,
                  ...other
                }) => (
                  <p
                    key={key}
                    {...other}
                    className="react-tagsinput-tag !bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !w-fit !text-black  dark:!text-white"
                  >
                    {getTagDisplayValue(tag)}
                    {!disabled && (
                      <a
                        className={`${classNameRemove} !text-base before:!content-["x"] font-montserrat before:pl-2 font-medium`}
                        onClick={(e) => onRemove(key)}
                      />
                      // <X className="w-3.5 h-3.5" onClick={(e) => onRemove(key)} />
                    )}
                  </p>
                )}
              />
              {isOpenRedirectBox && (
                <div
                  className="absolute translate-y-3 left-0 w-full bg-white max-h-[200px] overflow-y-auto scrollbar-thin space-y-1 p-3 rounded-xl shadow-xl z-50 dark:bg-darkLightBg  dark:text-white"
                  ref={redirectBoxRef}
                >
                  {emplyeeIntegrations
                    ?.filter(
                      (item) =>
                        item
                          ?.toLowerCase()
                          ?.indexOf(inputRedirect?.toLowerCase()) > -1
                    )
                    ?.map((item) => (
                      <div
                        key={item}
                        className="flex justify-between items-center gap-x-1"
                      >
                        <div
                          className="w-full cursor-pointer hover:bg-gray-100 dark:hover:bg-darkBg rounded-md pt-1 px-1"
                          onClick={() => {
                            if (!redirects?.includes(item)) {
                              setRedirects((prev) => [...prev, item]);
                            }
                          }}
                        >
                          <div className="!bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !w-fit !text-black dark:!text-white react-tagsinput-tag !block ">
                            {item}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>

          <div className="select-question-set">
            <p className="font-pro font-medium dark:text-white">Question set</p>
            <div className="w-full relative h-12" ref={questionSetRef}>
              <div
                className="px-5 h-full rounded-[7px] focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent flex items-center justify-between w-full bg-white cursor-pointer border mt-3 dark:bg-darkLightBg dark:border-[#454545] dark:text-white"
                onClick={() => setIsOpenQuestionSetBox((prev) => !prev)}
              >
                <p className="">
                  {questionSet &&
                  questionSets?.find((item) => item?.id === questionSet)
                    ?.name === "default"
                    ? "Default"
                    : questionSets?.find((item) => item?.id === questionSet)
                        ?.name}
                </p>
                <ChevronDown
                  className={`w-5 h-5 ${isOpenQuestionSetBox && "rotate-180"} transition-all duration-200`}
                />
              </div>
              {isOpenQuestionSetBox && (
                <div className="absolute translate-y-3 left-0 w-full bg-white max-h-[200px] overflow-y-auto scrollbar-thin space-y-1 p-3 rounded-xl shadow-xl z-50 dark:bg-darkLightBg dark:text-white">
                  {questionSets?.map((item) => (
                    <div
                      key={item?.id}
                      className="flex items-center justify-between group"
                    >
                      <p
                        className="w-full cursor-pointer group-hover:text-secondary capitalize"
                        onClick={() => {
                          setIsOpenQuestionSetBox(false);
                          setQuestionSet(item?.id);
                        }}
                      >
                        {item?.name}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end items-end gap-x-3">
            <button
              className="bg-white w-[121px] py-3 rounded-[7px] text-newPrimary border border-newPrimary dark:bg-darkLightBg"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="bg-newPrimary w-[121px] py-3 rounded-[7px] text-white border border-newPrimary hover:text-newPrimary hover:bg-white dark:hover:bg-darkLightBg"
              onClick={handleClick}
            >
              Save
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditStaffModal;
