import { useEffect, useState } from "react";
import { toast } from "sonner";

import {
  useGetReviewPlatforms,
  usePostReviewQuestions,
  useReviewCopied,
} from "../../query/useReview";
import style from "./note.module.scss";
import { RefreshCcw } from "lucide-react";
import MainLogo from "../../components/mainlogo";
import Google from "../../assets/reviewservices/google.svg";
import TrustPilot from "../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../assets/reviewservices/yelp.svg";
import Booking from "../../assets/reviewservices/booking.svg";
import Airbnb from "../../assets/reviewservices/airbnb.svg";
import TripAdvisor from "../../assets/reviewservices/tripadvisor.svg";
import { useSearchParams } from "react-router-dom";
import CopyModal from "./components/CopyModal";
import CopyAndPostModal from "./components/CopyAndPostModal";
import { Button } from "../../components/ui/button";

const Note = () => {
  const [notes, setNotes] = useState([]);
  const [response, setResponse] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
  const { mutate: regenerateReviews } = usePostReviewQuestions({
    refetchOnMount: false,
  });
  const { mutate: copyReview, isSuccess: copyReviewSuccess } =
    useReviewCopied();

  const [params] = useSearchParams();
  const employeeId = params.get("employee");
  const voiceReview = params.get("voicereview");
  const staffName =
    localStorage.getItem("staffName") && localStorage.getItem("staffName");

  const { data: reviewPlatforms } = useGetReviewPlatforms(
    {
      entityId: reviewId,
      employeeId: employeeId,
    },
    {
      enabled: Boolean(reviewId),
    }
  );

  useEffect(() => {
    setNotes(JSON.parse(localStorage.getItem("reviews")));
    setReviewId(localStorage.getItem("reviewId"));
    setResponse(JSON.parse(localStorage.getItem("response")));
  }, []);

  const handleGoToLink = ({ reviewUrl }) => {
    window.location.href = reviewUrl;
  };

  const handlePostFeedback = ({ id, review, reviewUrl }) => {
    navigator.clipboard.writeText(review);
    // setIsOpenCopyModal(true);
    copyReview(
      {
        business_uuid: reviewId,
        review_uuid: id,
      },
      {
        onSuccess: (res) => {
          if (reviewUrl) {
            // setTimeout(() => {
            //   window.location.href = reviewUrl;
            // }, 4000);
            window.location.href = reviewUrl;
          }
        },
        onError: (err) => {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        },
      }
    );
  };

  const handleRegenerate = () => {
    setLoading(true);
    setResponse(JSON.parse(localStorage.getItem("response")));
    regenerateReviews(
      {
        business_uuid: reviewId,
        response: response,
        employee_code:
          employeeId !== "null" && employeeId && staffName !== "undefined"
            ? employeeId
            : null,
        staff_name: staffName !== "undefined" && staffName ? staffName : null,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          setNotes(data);
        },
        onError: (err) => {
          setLoading(false);
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  return (
    <div className="bg-white w-full text-black min-h-screen">
      <div
        className={`relative bg-white max-w-screen-2xl mx-auto h-auto py-5 lg:py-10 ${style.Note}`}
      >
        <MainLogo className={"justify-center"} />

        {loading ? (
          <div className="w-full h-[calc(100vh-340px)] flex flex-col justify-center items-center gap-5">
            <img
              loading="lazy"
              src="/mr-logo.gif"
              alt="Loading Logo"
              className="w-20 h-20"
            />
            <p className="animate-pulse">
              Hold on while we get your reviews ...
            </p>
          </div>
        ) : (
          <>
            {!voiceReview && (
              <div className="flex justify-center mb-5">
                <Button
                  className="group gap-3"
                  variant="ghost"
                  onClick={handleRegenerate}
                >
                  Regenerate new reviews
                  <RefreshCcw className="group-active:rotate-90 transition-all duration-200" />
                </Button>
              </div>
            )}
            <div className="w-[90%] mx-auto maincont flex flex-row flex-wrap gap-5 justify-center mb-10">
              {notes
                ?.sort((a, b) => a.review.length - b.review.length)
                ?.map((note, id) => {
                  return (
                    <>
                      <div
                        className={`${style.comment} flex flex-col justify-between`}
                        key={note?.review}
                      >
                        <p className="mb-3 select-none">{note?.review}</p>
                        <div className="flex flex-wrap gap-3 justify-end">
                          {/* {reviewPlatforms?.platform?.google && (
                          <div
                            className="cursor-pointer  focus:shadow-outline bg-white border border-secondary rounded-[7px] px-3 py-2 text-center flex items-center gap-x-3"
                            onClick={() =>
                              handlePostFeedback({
                                id: note?.uuid,
                                review: note?.review,
                                reviewUrl: reviewPlatforms?.platform?.google,
                              })
                            }
                          >
                            <img src={Google} alt="" />
                            <p className="font-heebo text-secondary">
                              Copy & Post
                            </p>
                          </div>
                        )} */}
                          {reviewPlatforms?.platform?.google && (
                            <Button
                              //   className="cursor-pointer  focus:shadow-outline bg-white border border-secondary rounded-[7px] px-3 py-2 text-center flex items-center justify-center gap-x-3 w-full"
                              className="gap-2"
                              variant={"outline"}
                              onClick={() =>
                                handlePostFeedback({
                                  id: note?.uuid,
                                  review: note?.review,
                                  reviewUrl: reviewPlatforms?.platform?.google,
                                })
                              }
                            >
                              <img src={Google} alt="" />
                              Copy & Post
                            </Button>
                          )}
                          {reviewPlatforms?.platform?.trustpilot && (
                            <Button
                              className="gap-2"
                              variant="outline"
                              onClick={() =>
                                handlePostFeedback({
                                  id: note?.uuid,
                                  review: note?.review,
                                  reviewUrl:
                                    reviewPlatforms?.platform?.trustpilot,
                                })
                              }
                            >
                              <img src={TrustPilot} alt="" />
                              Copy & Post
                            </Button>
                          )}
                          {reviewPlatforms?.platform?.yelp && (
                            <Button
                              className="gap-2"
                              variant="outline"
                              onClick={() =>
                                handlePostFeedback({
                                  id: note?.uuid,
                                  review: note?.review,
                                  reviewUrl: reviewPlatforms?.platform?.yelp,
                                })
                              }
                            >
                              <img src={Yelp} alt="" />
                              Copy & Post
                            </Button>
                          )}
                          {reviewPlatforms?.platform?.booking && (
                            <Button
                              className="gap-2"
                              variant="outline"
                              onClick={() =>
                                handlePostFeedback({
                                  id: note?.uuid,
                                  review: note?.review,
                                  reviewUrl: reviewPlatforms?.platform?.booking,
                                })
                              }
                            >
                              <img src={Booking} alt="" />
                              Copy & Post
                            </Button>
                          )}
                          {reviewPlatforms?.platform?.airbnb && (
                            <Button
                              className="gap-2"
                              variant="outline"
                              onClick={() =>
                                handlePostFeedback({
                                  id: note?.uuid,
                                  review: note?.review,
                                  reviewUrl: reviewPlatforms?.platform?.airbnb,
                                })
                              }
                            >
                              <img src={Airbnb} alt="" className="w-6 " />
                              Copy & Post
                            </Button>
                          )}
                          {reviewPlatforms?.platform?.tripadvisor && (
                            <Button
                              className="gap-2"
                              variant="outline"
                              onClick={() =>
                                handlePostFeedback({
                                  id: note?.uuid,
                                  review: note?.review,
                                  reviewUrl:
                                    reviewPlatforms?.platform?.tripadvisor,
                                })
                              }
                            >
                              <img src={TripAdvisor} alt="" className="w-6 " />
                              Copy & Post
                            </Button>
                          )}
                          {/* {reviewPlatforms?.platform &&
                            Object.keys(reviewPlatforms?.platform)?.length >
                              0 && (
                              <CopyAndPostModal
                                key={note?.review}
                                reviewPlatforms={reviewPlatforms}
                                note={note}
                                reviewId={reviewId}
                              />
                            )} */}
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            {/* {(reviewPlatforms?.platform?.google ||
              reviewPlatforms?.platform?.trustpilot ||
              reviewPlatforms?.platform?.yelp) && (
              <div className="flex justify-center mb-5">
                <CopyAndPostModal
                  // isOpenModal={isOpenCopyModal}
                  // setIsOpenModal={setIsOpenCopyModal}
                  reviewPlatforms={reviewPlatforms}
                />
              </div>
            )} */}
            {reviewPlatforms?.platform?.google && (
              <div className="flex justify-center mb-5">
                <Button
                  variant="ghost"
                  onClick={() => {
                    handleGoToLink({
                      reviewUrl: reviewPlatforms?.platform?.google,
                    });
                  }}
                >
                  Google Review Page
                </Button>
              </div>
            )}
          </>
        )}

        {isOpenCopyModal && (
          <CopyModal
            isOpenModal={isOpenCopyModal}
            setIsOpenmodal={setIsOpenCopyModal}
          />
        )}
      </div>
    </div>
  );
};

export default Note;
