import Apimanager from "./axios";
// import { useParams } from "react-router";
export const getReviewQuestions = async ({ entityId, staffId }) => {
  const res = await Apimanager.get(
    `/entity/questions/review/${entityId}/${staffId ? `?staff=${staffId}` : ""}`
  );
  return res.data;
};

export const postReviewQuestions = async (data) => {
  const res = await Apimanager.post(`/entity/generate_reviews`, data);
  return res.data;
};

export const postReviewQuestionsByVoice = async (data) => {
  const res = await Apimanager.post(`/entity/generate_reviews`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const getReviewUrl = async (entityId) => {
  const res = await Apimanager.get(`/entity/review_url/${entityId}`);
  return res.data;
};

export const reviewCopy = async (data) => {
  const res = await Apimanager.post(`/entity/review_copied`, data);
  return res.data;
};

export const getEntityReviews = async (params) => {
  const res = await Apimanager.get(`/entity/get_entity_reviews`, {
    params: params,
  });

  return res.data;
};

export const getCanEntityGenerateReviews = async (entityId) => {
  const res = await Apimanager.get(
    `/entity/can_entity_generate_reviews/${entityId}`
  );
  return res.data;
};

export const getEntityReviewPermssions = async ({ entityId, employeeId }) => {
  const res = await Apimanager.get(`/entity/${entityId}/review_permissions`, {
    params: {
      employee: employeeId,
    },
  });
  return res.data;
};

export const getReviewPlatforms = async (entityId, employeeId) => {
  const res = await Apimanager.get(`/entity/${entityId}/review_platforms`, {
    params: {
      employee: employeeId,
    },
  });
  return res.data;
};

export const getSeoKeywords = async (entityId) => {
  const res = await Apimanager.get(`/entity/${entityId}/get_entity_keywords`);
  return res.data;
};

export const getDownloadReviews = async (parmas) => {
  const res = await Apimanager.get(`/entity/download_entity_reviews`, {
    params: parmas,
  });

  return res.data;
};
