/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { CalendarDays } from "lucide-react";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useGetDownloadReviews } from "../../../../query/useReview";
import { toast } from "sonner";
import { LogoLoading } from "../../../../components/loading/loading";
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
} from "../../../../components/ui/dialog";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Label } from "../../../../components/ui/label";

const DateInput = forwardRef(({ value, onClick }, ref) => (
  <div className="relative w-full" onClick={onClick} ref={ref}>
    <input
      type="text"
      value={value}
      readOnly
      className="w-full border border-[#ebebeb] dark:border-[#454545] rounded px-5 py-3 dark:bg-darkLightBg"
      placeholder="Select"
    />
    <CalendarDays className="text-[#ebebeb] absolute right-5 top-1/2 -translate-y-1/2" />
  </div>
));

const DownloadModal = ({ openModal, setOpenModal }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [isIncludedPosted, setIsIncludedPosted] = useState(false);

  const { mutate: downloadReviews, isLoading } = useGetDownloadReviews();

  const handleClick = () => {
    downloadReviews(
      {
        only_posted: isIncludedPosted ? "True" : "False",
        from_time: startDate ? startDate?.toISOString() : null,
        to_time: endDate ? endDate?.toISOString() : null,
      },
      {
        onSuccess: (res) => {
          const blob = new Blob([res], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Reviews ${startDate?.toLocaleDateString("en-GB") || ""} - ${endDate?.toLocaleDateString("en-GB") || ""}.csv`;
          link.click();
          toast.success("Reviews downloaded successfully");
          setStartDate();
          setEndDate(new Date());
          setIsIncludedPosted(false);
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (isLoading) {
    return <LogoLoading />;
  }

  return (
    <Modal
      dismissible
      show={openModal}
      onClose={() => {
        setOpenModal(false);
        setStartDate();
        setEndDate(new Date());
        setIsIncludedPosted(false);
      }}
      className="z-[1000]"
      theme={{
        content: {
          base: "relative p-4 h-auto",
          inner: "dark:bg-darkBg bg-white rounded-lg",
        },
        header: {
          base: "flex items-start justify-between rounded-t-lg border-b p-5 dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
          close: {
            base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
          },
        },
      }}
      popup
    >
      <Modal.Header>
        <p className="font-pro font-medium text-lg px-5 pt-1">
          Download Reviews
        </p>
      </Modal.Header>
      <Modal.Body className="pt-5">
        <div className="px-2 font-pro space-y-5 dark:text-[#bfbfbf]">
          <div className="flex items-center gap-5">
            <div className="flex flex-col gap-3">
              <p>From / Reviews from</p>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={<DateInput />}
                maxDate={new Date()}
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>To / Reviews to</p>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                customInput={<DateInput />}
                maxDate={new Date()}
              />
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <Checkbox
              id="posted"
              checked={isIncludedPosted}
              onCheckedChange={() => {
                setIsIncludedPosted((prev) => !prev);
              }}
            />
            <Label htmlFor="posted">Download only posted reviews</Label>
          </div>
          <div className="flex justify-center items-center gap-x-3 mt-7">
            <button
              className="bg-white dark:bg-darkLightBg w-full py-3 rounded-[7px] text-newPrimary border border-newPrimary"
              onClick={() => {
                setOpenModal(false);
                setStartDate();
                setEndDate(new Date());
                setIsIncludedPosted(false);
              }}
            >
              Cancel
            </button>
            <button
              className="bg-newPrimary w-full py-3 rounded-[7px] text-white border border-newPrimary hover:bg-white hover:text-newPrimary dark:hover:bg-darkLightBg transition-all duration-200"
              onClick={handleClick}
            >
              {/* {isLoading ? (
            <div className="flex items-center justify-center">
              <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
            </div>
          ) : (
            "Download"
          )} */}
              Download
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    // <Dialog
    //   open={openModal}
    //   onOpenChange={() => {
    //     setOpenModal(!openModal);
    //     setStartDate();
    //     setEndDate(new Date());
    //     setIsIncludedPosted(false);
    //   }}
    // >
    //   <DialogContent>
    //     <DialogHeader className={"bg-[#f0f5ff]"}>
    //       <DialogTitle>Download Reviews</DialogTitle>
    //     </DialogHeader>
    //     <div className="px-2 font-pro space-y-5 dark:text-[#bfbfbf]">
    //       <div className="flex items-center gap-5">
    //         <div className="flex flex-col gap-3">
    //           <p>Date From</p>
    //           <DatePicker
    //             selected={startDate}
    //             onChange={(date) => setStartDate(date)}
    //             customInput={<DateInput />}
    //             maxDate={new Date()}
    //           />
    //         </div>
    //         <div className="flex flex-col gap-3">
    //           <p>Date To</p>
    //           <DatePicker
    //             selected={endDate}
    //             onChange={(date) => setEndDate(date)}
    //             customInput={<DateInput />}
    //             maxDate={new Date()}
    //           />
    //         </div>
    //       </div>
    //       <div className="flex items-center gap-x-3">
    //         <input
    //           type="checkbox"
    //           id="posted"
    //           checked={isIncludedPosted}
    //           onChange={() => {
    //             setIsIncludedPosted((prev) => !prev);
    //           }}
    //           className="appearance-none checked:!bg-secondary focus:appearance-none accent-secondary focus:accent-secondary text-secondary focus:ring-secondary focus:ring-1 cursor-pointer"
    //         />
    //         <label htmlFor="posted" className="cursor-pointer">
    //           Only Posted Reviews
    //         </label>
    //       </div>
    //       <div className="flex justify-center items-center gap-x-3 mt-7">
    //         <button
    //           className="bg-white dark:bg-darkLightBg w-full py-3 rounded-[7px] text-newPrimary border border-newPrimary"
    //           onClick={() => {
    //             setOpenModal(false);
    //             setStartDate();
    //             setEndDate(new Date());
    //             setIsIncludedPosted(false);
    //           }}
    //         >
    //           Cancel
    //         </button>
    //         <button
    //           className="bg-newPrimary w-full py-3 rounded-[7px] text-white border border-newPrimary hover:bg-white hover:text-newPrimary dark:hover:bg-darkLightBg transition-all duration-200"
    //           onClick={handleClick}
    //         >
    //           {/* {isLoading ? (
    //             <div className="flex items-center justify-center">
    //               <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
    //             </div>
    //           ) : (
    //             "Download"
    //           )} */}
    //           Download
    //         </button>
    //       </div>
    //     </div>
    //   </DialogContent>
    // </Dialog>
  );
};

export default DownloadModal;
