/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const RemainingSubscriptionWithoutMemo = ({ isSubuser }) => {
  const navigate = useNavigate();
  const [activeSubscription, setActiveSubscription] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      // setUserInfo(parsedUser);
      setActiveSubscription(parsedUser.profile?.has_active_subscription);
    }
  }, [localStorage.getItem("user")]);

  const handleGoToPayment = () => {
    isSubuser
      ? navigate("/dashboard/billing")
      : navigate("/dashboard/payment?savedCard=false");
  };

  return (
    <div className="flex flex-col items-center  justify-center mt-5 lg:mt-7/ rounded-[10px] min-h-[120px] mr-5 lg:mr-0">
      {!isSubuser && !activeSubscription && (
        <p className="text-[#4A4A4A] dark:text-white text-center font-pro text-[15px]">
          You have no subscription.
        </p>
      )}
      {
        <button
          className="bg-newPrimary text-white font-pro text-[15px] rounded-[55px] px-[30px] py-[10px] mt-[20px] hover:text-newPrimary hover:bg-white transition-all duration-200 border border-newPrimary dark:bg-darkLightBg"
          onClick={handleGoToPayment}
        >
          {isSubuser ? (
            "View Billing"
          ) : (
            <>{activeSubscription ? "View Plan" : "Buy Plan"}</>
          )}
        </button>
      }
    </div>
  );
};

const RemainingSubscription = React.memo(RemainingSubscriptionWithoutMemo);

export default RemainingSubscription;
