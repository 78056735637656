/* eslint-disable react/prop-types */
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { useEffect, useState } from "react";
import { Switch } from "../../../components/ui/switch";
import { usePostUpdateCreditLimit } from "../../../query/useMaster";
import { LogoLoading } from "../../../components/loading/loading";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";

const EditUserModal = ({ isOpenModal, setIsOpenModal, creditLimit, id }) => {
  const [maxCredit, setMaxCredit] = useState("");
  const query = useQueryClient();

  const { mutate: updateCreditLimit, isLoading: updateCreditLimitLoading } =
    usePostUpdateCreditLimit();

  const handleUpdateCreditLimit = () => {
    updateCreditLimit(
      {
        id: id,
        data: {
          limit: maxCredit,
        },
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setIsOpenModal();
          query.invalidateQueries(["getSubUsers"]);
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    setMaxCredit(creditLimit || 0);
  }, []);

  if (updateCreditLimitLoading) {
    return <LogoLoading />;
  }
  return (
    <Dialog open={isOpenModal} onOpenChange={() => setIsOpenModal(0)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit User Settings</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro">
          <div className="space-y-5">
            <div className="space-y-2">
              <p className="font-medium">Edit Max. Credit Cap</p>
              <Input
                value={maxCredit}
                onChange={(e) => setMaxCredit(e.target.value)}
              />
            </div>
            {/* <div className="grid grid-cols-3 w-full gap-5">
              <div className="col-span-2">
                <p className={`dark:text-white font-medium`}>Auto Reminder</p>
                <p className="text-sm text-[#525252] dark:text-white">
                  Setup auto reminder via email if credit reaches to last 10 %
                </p>
              </div>
              <div className="flex items-center gap-x-3">
                <Switch
                // disabled={toggleDirectReviewLoading}
                // checked={directReview?.direct_review}
                // onCheckedChange={handleToggleDirectReview}
                ></Switch>

                
              </div>
            </div>
            <div className="grid grid-cols-3 w-full gap-5">
              <div className="col-span-2">
                <p className={`dark:text-white font-medium`}>Set Limit</p>
                <p className="text-sm text-[#525252] dark:text-white">
                  Limit option for the user account
                </p>
              </div>
              <div className="flex items-center gap-x-3">
                <Switch
                // disabled={toggleDirectReviewLoading}
                // checked={directReview?.direct_review}
                // onCheckedChange={handleToggleDirectReview}
                ></Switch>

              </div>
            </div> */}
          </div>
          <div className="flex justify-end gap-3 mt-5">
            <Button variant="secondary" onClick={() => setIsOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={handleUpdateCreditLimit}>Confirm</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditUserModal;
