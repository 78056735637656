import { useQuery, useMutation, useInfiniteQuery } from "@tanstack/react-query";

import {
  getReviewQuestions,
  postReviewQuestions,
  getReviewUrl,
  reviewCopy,
  getEntityReviews,
  getCanEntityGenerateReviews,
  getReviewPlatforms,
  getEntityReviewPermssions,
  getSeoKeywords,
  postReviewQuestionsByVoice,
  getDownloadReviews,
} from "../api/reviewsApi";

export const useGetReviewQuestions = ({ entityId, staffId }, config) => {
  return useQuery({
    queryKey: ["getReviewQuestions", staffId],
    queryFn: () => getReviewQuestions({ entityId: entityId, staffId: staffId }),
    ...config,
  });
};

export const usePostReviewQuestions = (config) => {
  return useMutation({
    mutationKey: ["postReviewQuestions"],
    mutationFn: (data) => postReviewQuestions(data),
    ...config,
  });
};

export const usePostReviewQuestionsByVoice = (config) => {
  return useMutation({
    mutationKey: ["postReviewQuestionsByVoice"],
    mutationFn: (data) => postReviewQuestionsByVoice(data),
    ...config,
  });
};

export const useGetReviewUrl = (entityId, config) => {
  return useQuery({
    queryKey: ["getReviewUrl"],
    queryFn: () => getReviewUrl(entityId),
    ...config,
  });
};

export const useReviewCopied = (config) => {
  return useMutation({
    mutationKey: ["reviewCopy"],
    mutationFn: (data) => reviewCopy(data),
    ...config,
  });
};

export const useGetEntityReviews = ({
  entityId,
  limit,
  isAiGenerated,
  platforms,
  is_posted_ai_review,
}) => {
  return useInfiniteQuery({
    queryKey: [
      "getEntityReviews",
      entityId,
      isAiGenerated,
      platforms,
      is_posted_ai_review,
    ],
    queryFn: ({ pageParam = 0 }) => {
      const dataForGetEntityReviews = {
        entity_uuid: entityId,
        limit: limit || 5,
        index: pageParam,
        is_posted_ai_review,
        is_ai_generated: isAiGenerated === "aigenerated" && true,
        platforms: platforms,
      };

      if (isAiGenerated !== "aigenerated")
        delete dataForGetEntityReviews["is_ai_generated"];

      return getEntityReviews(dataForGetEntityReviews);
    },
    getNextPageParam: (lastPage, allPages) => {
      if (
        allPages?.flatMap((page) => page?.results)?.length <
        lastPage?.total_results
      ) {
        return allPages?.flatMap((page) => page?.results)?.length;
      }
    },
    keepPreviousData: true,
    enabled: Boolean(entityId),
  });
};

export const useGetCanEntityGenerateReviews = (entityId, config) => {
  return useQuery({
    queryKey: ["getCanEntityGenerateReviews"],
    queryFn: () => getCanEntityGenerateReviews(entityId),
    ...config,
  });
};

export const useGetEntityReviewPermissions = (
  { entityId, employeeId },
  config
) => {
  return useQuery({
    queryKey: ["getEntityReviewPermissions"],
    queryFn: () => getEntityReviewPermssions({ entityId, employeeId }),
    ...config,
  });
};

export const useGetReviewPlatforms = ({ entityId, employeeId }, config) => {
  return useQuery({
    queryKey: ["getReviewPlatforms"],
    queryFn: () => getReviewPlatforms(entityId, employeeId),
    ...config,
  });
};

export const useGetSeoKeywords = (entityId, config) => {
  return useQuery({
    queryKey: ["getSeoKeywords"],
    queryFn: () => getSeoKeywords(entityId),
    ...config,
  });
};

export const useGetDownloadReviews = () => {
  return useMutation({
    mutationKey: ["getDownloadReviews"],
    mutationFn: (params) => getDownloadReviews(params),
  });
};
