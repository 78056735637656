/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import Layout from "./component/Layout";
import React, { PureComponent, useEffect, useRef } from "react";
import { DashboardLoading } from "../../components/loading/loading";
import { useState } from "react";
import { useAuth } from "../../context/authContext";
import Chart2 from "./component/chart";
import NoPlanModal from "./component/PlanModal";
import { ExpiredPlanModal, SelectPlanModal } from "./component/PlanModal";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";

// import { Select } from "flowbite-react";
import Slider from "./component/slider";
import { useGetStats, useGetStatsGraph } from "../../query/useEntity";
import { Link, useSearchParams } from "react-router-dom";
import {
  useGetAirbnbSettings,
  useGetBookingSettings,
  useGetIntegrations,
  useGetIsSubUser,
  useGetTripSettings,
  useGetTrustPilotSettings,
  useGetYelpSettings,
  useUser,
} from "../../query/useAuthentication";
import { toast } from "sonner";
import {
  BarChart4,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CircleCheck,
  CircleCheckBig,
  CircleX,
  TriangleAlert,
  Mic,
  Sparkles,
  SquareCheckBig,
  Star,
} from "lucide-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useWindowSize from "../../hooks/useWindowSize";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/bundle";
import Google from "../../assets/reviewservices/google.svg";
import TrustPilot from "../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../assets/reviewservices/yelp.svg";
import Booking from "../../assets/reviewservices/booking.svg";
import TripAdvisor from "../../assets/reviewservices/tripadvisor.svg";
import { usePostVerifyPayment } from "../../query/useSubscription";
import { Modal } from "flowbite-react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { dayFormatter } from "../../utils/formatter";
import AiReviewPosted from "./routes/components/AiReviewPosted";
import { useGetAnnouncement } from "../../query/useAnnouncement";
import { useDarkMode } from "../../context/DarkModeProvider";
import { useGetSettings } from "../../query/useAuthentication";

const platforms = [
  {
    name: "Google",
    value: "google",
    image: Google,
  },
  {
    name: "Trustpilot",
    value: "trustpilot",
    image: TrustPilot,
  },
  {
    name: "Yelp",
    value: "yelp",
    image: Yelp,
  },
  {
    name: "Booking",
    value: "booking",
    image: Booking,
  },
  {
    name: "TripAdvisor",
    value: "tripadvisor",
    image: TripAdvisor,
  },
];

const Dashboard = () => {
  const { setUserInfo } = useAuth();
  const [parmas] = useSearchParams();
  const sessionId = parmas.get("session_id");
  const [selectedPlatforms, setSelectedPlatforms] = useState("google");
  const [isIntegrated, setIsIntegrated] = useState(true);

  const { darkMode } = useDarkMode();

  const { data: stats, isLoading: isStatsLoading } = useGetStats();

  const { data: isSubuser } = useGetIsSubUser();

  const { mutate: verifyPayment } = usePostVerifyPayment();

  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleSelectedServices = (platform) => {
    setSelectedPlatforms(platform);
  };

  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState({});

  useEffect(() => {
    if (sessionId) {
      verifyPayment(
        { session_id: sessionId },
        {
          onSuccess: (res) => {
            toast.success(res.message);
          },
          onError: (err) => {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    }
  }, [sessionId]);

  useEffect(() => {
    // Function to prevent back button by pushing the same state
    const preventBack = () => {
      window.history.pushState(null, null, window.location.href);
    };

    // Initially push state to prevent back navigation
    preventBack();

    // Add event listener to listen for back button press
    window.addEventListener("popstate", preventBack);

    return () => {
      // Clean up event listener when the component unmounts
      window.removeEventListener("popstate", preventBack);
    };
  }, []);

  useGetIntegrations({
    onSuccess: (res) => {
      setIsIntegrated(res?.value);
    },
  });

  const isMainLoading = isStatsLoading;

  return (
    <Layout>
      {/* <SelectPlanModal />    */}
      <div className="flex flex-col w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[21px] min-h-[calc(100vh-200px)] 3xl:min-h-[calc(100vh-150px)] px-5 lg:px-[30.2px]">
        {isSubuser?.data?.credits_exceed && (
          <div className="w-full rounded-[8px] bg-red-600 p-[0.8125rem] mb-3 flex  gap-2 sm:items-center text-white">
            <TriangleAlert className="h-5 w-5" />

            <p className="font-medium dark:font-semibold text-sm">
              {isSubuser?.data?.message}
            </p>
          </div>
        )}
        {!isIntegrated && (
          <div className="w-full rounded-[8px] bg-[#ffe07c] dark:bg-[#4d2600] text-[#2f1400] dark:text-[#fff2b4] p-[0.8125rem] mb-3 flex  gap-2 sm:items-center">
            <TriangleAlert className="h-5 w-5  dark:text-[#fff2b4]" />

            <div className="flex sm:flex-row flex-col justify-between sm:items-center gap-5 w-full">
              <p className="font-pro font-normal  text-sm">
                <span className="font-medium dark:font-semibold">
                  Add Google My Business link.
                </span>{" "}
                <br className="sm:hidden" />
                Unfortunately, you still haven&apos;t added your review link for
                your Business. Add the link to get an analysis of your reviews.
              </p>
              <Link
                to={"/dashboard/settings/integrations"}
                className=" text-[#6D3A01] dark:text-[#fcbd00] text-sm font-medium sm:ml-auto text-nowrap"
              >
                Add link
              </Link>
            </div>
          </div>
        )}

        {
          <div className="flex flex-col md:flex-row gap-5 w-full mb-5 font-pro">
            <div className="flex md:max-w-[250px] w-full flex-col rounded-[12px] p-3 bg-white dark:bg-darkBg shadow-sm 3xl:p-5 generated">
              <div className="flex items-center gap-x-3 mb-[15px]">
                {isMainLoading ? (
                  <Skeleton
                    circle
                    className="w-10 h-10 3xl:w-14 3xl:h-14"
                    containerClassName="flex-1"
                    inline
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <div className="p-2 rounded-full bg-[#E7924229] dark:bg-darkLightBg">
                    <Sparkles className="text-[#E79242] w-5 h-5" />
                  </div>
                )}
                {isMainLoading ? (
                  <Skeleton
                    containerClassName="flex-1"
                    inline
                    className="w-full"
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  // <p className="font-medium 3xl:text-lg">
                  //   AI-generated reviews
                  // </p>
                  <p className="text-base font-medium text-[#4D4D4D] dark:text-white">
                    AI-generated reviews
                  </p>
                )}
              </div>
              {isMainLoading ? (
                <Skeleton className="mt-5" baseColor={darkMode && "#262626"} />
              ) : (
                <div className="text-center">
                  <div className="mb-[4px]">
                    <p className="text-[34px] font-bold">
                      {" "}
                      {stats?.total_ai_generated_reviews || 0}
                    </p>
                  </div>
                  {/* <p className="text-[#888888] text-[13px]">
                    Total AI-generated reviews
                  </p> */}
                  {/* <Text variant="heading3xl">
                    {stats?.total_ai_generated_reviews || 0}
                  </Text>
                  <Text variant="headingMd">Total AI-generated reviews</Text> */}
                </div>
              )}
            </div>
            <div className="flex md:max-w-[250px] w-full flex-col rounded-[12px] p-3 3xl:p-5 bg-white dark:bg-darkBg shadow-sm redirected">
              <div className="flex items-center gap-x-3 mb-[15px]">
                {isMainLoading ? (
                  <Skeleton
                    circle
                    className="w-10 h-10 3xl:w-14 3xl:h-14"
                    containerClassName="flex-1"
                    inline
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <div className="p-2 rounded-full bg-[#1AAD5526] dark:bg-darkLightBg">
                    <SquareCheckBig className="text-[#1AAD55] w-5 h-5" />
                  </div>
                )}
                {isMainLoading ? (
                  <Skeleton
                    containerClassName="flex-1"
                    inline
                    className="w-full"
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <p className="text-base font-medium text-[#4D4D4D] dark:text-white">
                    AI reviews redirected
                  </p>
                )}
              </div>
              {isMainLoading ? (
                <Skeleton className="mt-5" baseColor={darkMode && "#262626"} />
              ) : (
                <div className="text-center">
                  <div className="mb-[4px]">
                    <p className="text-[34px] font-bold">
                      {stats?.total_ai_posted_reviews || 0}
                    </p>
                  </div>
                  {/* <p className="text-[#888888] text-[13px]">
                    Total redirected reviews
                  </p> */}
                </div>
              )}
            </div>
            <div className=" max-w-[250px] w-full flex-col rounded-[12px] p-3 bg-white dark:bg-darkBg shadow-sm 3xl:p-5 generated hidden">
              <div className="flex items-center gap-x-3 mb-[15px]">
                {isMainLoading ? (
                  <Skeleton
                    circle
                    className="w-10 h-10 3xl:w-14 3xl:h-14"
                    containerClassName="flex-1"
                    inline
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <div className="p-2 rounded-full bg-[#FFAF1933] dark:bg-darkLightBg">
                    <Star className="text-[#FFAF19] w-5 h-5" />
                  </div>
                )}
                {isMainLoading ? (
                  <Skeleton
                    containerClassName="flex-1"
                    inline
                    className="w-full"
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <p className="font-medium 3xl:text-lg">Google Ratings</p>
                )}
              </div>
              {isMainLoading ? (
                <Skeleton className="mt-5" baseColor={darkMode && "#262626"} />
              ) : (
                <div className="text-center">
                  <p className="text-[38px] font-semibold mb-[4px]">
                    {stats?.total_ai_posted_reviews || 0}
                  </p>
                  <p className="text-[#888888] text-base">On Google</p>
                </div>
              )}
            </div>
            <div className="flex md:max-w-[250px] w-full flex-col rounded-[12px] p-3 3xl:p-5 bg-white dark:bg-darkBg shadow-sm redirected">
              <div className="flex items-center gap-x-3 mb-[15px]">
                {isMainLoading ? (
                  <Skeleton
                    circle
                    className="w-10 h-10 3xl:w-14 3xl:h-14"
                    containerClassName="flex-1"
                    inline
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <div className="p-2 rounded-full bg-[#4F75FA26] dark:bg-darkLightBg">
                    <Mic className="text-[#2A49B8] w-5 h-5" />
                  </div>
                )}
                {isMainLoading ? (
                  <Skeleton
                    containerClassName="flex-1"
                    inline
                    className="w-full"
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <p className="text-base font-medium text-[#4D4D4D] dark:text-white text-nowrap">
                    Redirected voice reviews{" "}
                  </p>
                )}
              </div>
              {isMainLoading ? (
                <Skeleton className="mt-5" baseColor={darkMode && "#262626"} />
              ) : (
                <div className="text-center">
                  <div className="mb-[4px]">
                    <p className="text-[34px] font-bold">
                      {stats?.total_voice_review_redirected || 0}
                    </p>
                  </div>
                  {/* <p className="text-[#888888] text-[13px]">
                    Total redirected voice reviews
                  </p> */}
                </div>
              )}
            </div>
          </div>
        }
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div className=" w-full gap-5 font-pro font-medium">
            <AiReviewPosted />
          </div>
          <div className="p-6 flex flex-col rounded-[12px] bg-white dark:bg-darkBg w-full lg:max-w-[400px] shadow-sm">
            <header className="flex justify-between items-center">
              {isMainLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <>
                  <div className="font-pro">
                    <p className="text-lg font-semibold">Latest reviews</p>
                  </div>
                  <Link
                    to="/dashboard/reviews"
                    className="w-fit h-10 bg-[#f6f6ff] dark:bg-darkLightBg rounded-lg flex justify-center items-center px-5 text-[#615e83] dark:text-secondary font-poppins text-sm gap-x-2"
                  >
                    <p className="text-[10px] font-medium">View all</p>

                    <ChevronRight className="w-3 h-3" />
                  </Link>
                </>
              )}
            </header>
            <Slider isMainLoading={isMainLoading} />
          </div>
        </div>
        {/* <div className="grid grid-cols-7 gap-5 mt-5 font-pro">
          <div className="w-full col-span-5 grid grid-cols-3 gap-5">
            <div className="flex flex-col rounded-[12px] p-3 bg-white dark:bg-darkBg shadow-sm 3xl:p-5 generated gap-3">
              <div className="flex items-center gap-x-3">
                {isMainLoading ? (
                  <Skeleton
                    circle
                    className="w-10 h-10 3xl:w-14 3xl:h-14"
                    containerClassName="flex-1"
                    inline
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <div className="p-2 rounded-full bg-[#f5f7ff] dark:bg-darkLightBg">
                    <Sparkles className="text-[#4770FF] w-5 h-5" />
                  </div>
                )}
                {isMainLoading ? (
                  <Skeleton
                    containerClassName="flex-1"
                    inline
                    className="w-full"
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <p className="font-medium text-lg 3xl:text-lg">Reviews</p>
                )}
              </div>
              {isMainLoading ? (
                <Skeleton className="mt-5" baseColor={darkMode && "#262626"} />
              ) : (
                <>
                  <p className="text-center text-[38px] font-semibold 3xl:text-6xl font-bold">
                    {stats?.total_ai_generated_reviews || 0}
                  </p>
                  <p className="text-center text-sm text-[#888]">
                    redirected reviews
                  </p>
                </>
              )}
            </div>
            <div className="flex flex-col rounded-[12px] p-3 bg-white dark:bg-darkBg shadow-sm 3xl:p-5 generated gap-3">
              <div className="flex items-center gap-x-3">
                {isMainLoading ? (
                  <Skeleton
                    circle
                    className="w-10 h-10 3xl:w-14 3xl:h-14"
                    containerClassName="flex-1"
                    inline
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <div className="p-2 rounded-full bg-[#ffaf19]/20 dark:bg-darkLightBg">
                    <Star className="text-[#ffaf19] w-5 h-5" />
                  </div>
                )}
                {isMainLoading ? (
                  <Skeleton
                    containerClassName="flex-1"
                    inline
                    className="w-full"
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <p className="font-medium text-lg 3xl:text-xl">
                    Google Ratings
                  </p>
                )}
              </div>
              {isMainLoading ? (
                <Skeleton className="mt-5" baseColor={darkMode && "#262626"} />
              ) : (
                <>
                  <p className="text-center text-[38px] font-semibold 3xl:text-6xl font-bold">
                    {stats?.total_ai_generated_reviews || 0}
                  </p>
                  <p className="text-center text-sm text-[#888]">on Google</p>
                </>
              )}
            </div>
            <div className="flex flex-col rounded-[12px] p-3 bg-white dark:bg-darkBg shadow-sm 3xl:p-5 generated gap-3">
              <div className="flex items-center gap-x-3">
                {isMainLoading ? (
                  <Skeleton
                    circle
                    className="w-10 h-10 3xl:w-14 3xl:h-14"
                    containerClassName="flex-1"
                    inline
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <div className="p-2 rounded-full bg-[#00b67a]/20 dark:bg-darkLightBg">
                    <Mic className="text-[#00b67a] w-5 h-5" />
                  </div>
                )}
                {isMainLoading ? (
                  <Skeleton
                    containerClassName="flex-1"
                    inline
                    className="w-full"
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <p className="font-medium text-lg 3xl:text-xl">Reviews</p>
                )}
              </div>
              {isMainLoading ? (
                <Skeleton className="mt-5" baseColor={darkMode && "#262626"} />
              ) : (
                <>
                  <p className="text-center text-[38px] font-semibold 3xl:text-6xl font-bold">
                    {stats?.total_ai_generated_reviews || 0}
                  </p>
                  <p className="text-center text-sm text-[#888]">
                    redirected reviews
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="w-full col-span-2 flex flex-col gap-5">
            <div className="flex flex-col rounded-[12px] py-5 px-7 dark:bg-darkBg 3xl:py-5 generated gap-3 border-dashed border-image-wide-dashed border-2">
              <div className="flex items-center gap-x-3">
                {isMainLoading ? (
                  <Skeleton
                    containerClassName="flex-1"
                    inline
                    className="w-full"
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <p className="font-medium text-lg 3xl:text-xl">
                    Reviews today
                  </p>
                )}
              </div>
              {isMainLoading ? (
                <Skeleton className="mt-5" baseColor={darkMode && "#262626"} />
              ) : (
                <>
                  <p className="text-[38px] font-semibold 3xl:text-6xl font-bold">
                    {stats?.total_ai_generated_reviews || 0}
                  </p>
                  <p className="text-sm text-[#888]">redirected reviews</p>
                </>
              )}
            </div>
          </div>
          <div className="w-full col-span-5 flex ">
            <AiReviewPosted />
          </div>
          <div className="w-full col-span-2">
            <div className="p-5 lg:p-8 pb-5 3xl:p-12 flex flex-col rounded-[12px] bg-white dark:bg-darkBg w-full shadow-sm">
              <header className="flex justify-between items-center mb-1">
                {isMainLoading ? (
                  <Skeleton
                    containerClassName="flex-1"
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <>
                    <h1 className="font-pro text-[20px] 3xl:text-3xl">
                      Latest Review
                    </h1>
                    <Link
                      to="/dashboard/reviews"
                      className="w-fit h-10 bg-[#f6f6ff] dark:bg-darkLightBg rounded-lg flex justify-center items-center px-5 text-[#615e83] dark:text-secondary font-poppins text-sm gap-x-2"
                    >
                      View All
                      <ChevronRight className="w-5 h-5" />
                    </Link>
                  </>
                )}
              </header>
              <Slider isMainLoading={isMainLoading} />
            </div>
          </div>
        </div> */}
      </div>
    </Layout>
  );
};
export default Dashboard;
