import { useState, useRef, useEffect } from "react";
import { Dialog, Popover, Menu } from "@headlessui/react";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import MainLogo from "../mainlogo";
import RightArrow from "../../assets/icons/RightArrow";
import { useAuth } from "../../context/authContext";
import { motion } from "framer-motion";
import Intercom from '@intercom/messenger-js-sdk';
// import Logo from "../logo";
import NavLogo from "../../assets/NavLogo.svg";
const MainNav = () => {
  Intercom({
    app_id: 'ncew78xq',
  });
  const location = useLocation();
  const { token } = useAuth();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isHowItworks = location.pathname.includes("howitworks");
  const isReview = location.pathname.includes("payment");
  const companyDropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isPricing = location.pathname.includes("pricing");
  const isContact = location.pathname.includes("contact");
  const isAbout = location.pathname.includes("about");
  const isBlog = location.pathname.includes("blog");
  const { state } = location;
  const referrer = state?.prev;
  const profileId = state?.profileId;

  useEffect(() => {
    function handleClickOutside(event) {
      // Close the Platform dropdown if clicked outside
      if (
        companyDropdownRef.current &&
        !companyDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <header className="bg-white w-full sticky top-0 z-50 left-0">
      {mobileMenuOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40 lg:hidden"
          onClick={() => setMobileMenuOpen(false)} // Close menu on overlay click
        ></div>
      )}
      <nav className="bg-white w-full container" aria-label="Global">
        <div className="w-full flex items-center justify-between py-5">
          <motion.div
            className="flex lg:flex-1"
            whileTap={{
              scale: 0.95,
            }}
            // whileHover={{
            //   scale: 0.9,
            // }}
            // transition={{
            //   duration: 0.1,
            // }}
          >
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={"/"}
              aria-label="MagicReview"
              className=""
            >
              <MainLogo />
              {/* <Logo /> */}
            </Link>
          </motion.div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.25 6.72363H3.25"
                  stroke="#434343"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.25 12.7236H9.25"
                  stroke="#434343"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.25 18.7236H7.25"
                  stroke="#434343"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <Popover.Group className="hidden lg:flex lg:gap-x-9">
            <h2>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                href="#"
                to="/how-it-works"
                className={`text-sm font-semibold leading-6 text-[#101828] font-montserrat hover:text-newPrimary border border-white px-2 py-1 rounded-md hover:bg-[#1000C50A] active:scale-95 transition-all duration-300 ${
                  isHowItworks && "text-newPrimary"
                }`}
                aria-label="How it works"
              >
                How It works
              </Link>
            </h2>
            <h2>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/pricing"
                href="#"
                className={`text-sm font-semibold leading-6 text-[#101828] font-montserrat hover:text-newPrimary border border-white px-2 py-1 rounded-md hover:bg-[#1000C50A] active:scale-95 transition-all duration-300 ${
                  isPricing && "text-newPrimary"
                }`}
                aria-label="Pricing"
              >
                Pricing
              </Link>
            </h2>
            {/* <h2>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/about-us"
                href="#"
                className={`text-sm font-semibold leading-6 text-[#101828] font-montserrat hover:text-newPrimary border border-white px-2 py-1 rounded-md hover:bg-[#1000C50A] active:scale-95 transition-all duration-300 ${
                  isBlog && "text-newPrimary"
                }`}
                aria-label="Blog"
              >
                Company
              </Link>
            </h2> */}
            <Menu as="div" className="relative" ref={companyDropdownRef}>
              <Menu.Button
                className="inline-flex items-center text-sm font-semibold leading-6 text-[#101828] font-montserrat"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                Company
                <ChevronDownIcon
                  className={`-mr-1 ml-2 h-5 w-5 transform transition-transform ${
                    isDropdownOpen ? "rotate-180" : ""
                  }`}
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-10 mt-2 w-48 p-2 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="flex flex-col py-1 ">
                    {/* These are the dropdown links, add or change as needed */}

                    <Menu.Item>
                      <Link
                        to={"/about-us"}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        className={`flex text-sm items-center montserrat text-[#070707] font-[500] hover:text-newPrimary border border-white px-2 py-1 rounded-md hover:bg-[#1000C50A] active:scale-95 gap-3 transition-all duration-300`}
                      >
                        <img loading="lazy" src="/aboutus.svg" alt="" />
                        About Us
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to={"/blog"}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        className={`flex hover:text-newPrimary border border-white px-2 py-1 rounded-md hover:bg-[#1000C50A] active:scale-95 transition-all duration-300 text-sm items-center montserrat gap-3 text-[#070707] font-[500]`}
                      >
                        <img loading="lazy" src="/blog.svg" alt="" />
                        Blog
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to={"/contact-us"}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        className={`flex hover:text-newPrimary border border-white px-2 py-1 rounded-md hover:bg-[#1000C50A] active:scale-95 transition-all duration-300 text-sm items-center montserrat gap-3 text-[#070707] font-[500]`}
                      >
                        <img loading="lazy" src="/contactus.svg" alt="" />
                        Contact Us
                      </Link>
                    </Menu.Item>

                    {/* ... more items here ... */}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            {/* <h2>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                href="#"
                to="/payment"
                className={`text-sm font-semibold leading-6 text-[#101828] font-montserrat hover:text-newPrimary border border-white px-2 py-1 rounded-md hover:bg-[#1000C50A] active:scale-95 transition-all duration-300 ${
                  isReview && "text-newPrimary"
                }`}
                aria-label="AI Review Cards"
              >
                AI Review Cards
              </Link>
            </h2> */}
          </Popover.Group>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-2">
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={
                referrer === "register-entity"
                  ? `/register-entity?profileId=${profileId}`
                  : token
                    ? "/dashboard"
                    : "/sign-in"
              }
              className="flex items-center font-semibold gap-2 px-8 py-3 text-[#2D2D2D] bg-[#EEF1FF] rounded-[12px] hover:text-newPrimary transition-all duration-300 hover:bg-white cursor-pointer"
              aria-label="Sign In"
            >
              Login
            </Link>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={"/sign-up"}
              className="bg-newPrimary text-white py-2.5 px-5 rounded-[10px] flex items-center font-manrope text-base gap-2 font-semibold border border-newPrimary hover:text-newPrimary hover:bg-white transition-all duration-300 active:scale-95"
              aria-label="Sign Up"
            >
              Get Started <RightArrow />
            </Link>
          </div>
        </div>
      </nav>

      <div
        className={`fixed top-0 right-0 z-[1000] w-full max-w-[550px] h-screen transition-transform py-7  overflow-y-auto bg-white overflow-x-hidden lg:hidden 
    ${mobileMenuOpen ? "-translate-x-0 ease-out" : "translate-x-full ease-in"}
  `}
      >
        <div className="flex justify-between items-center mx-8">
          <MainLogo />

          <button
            type="button"
            className="rounded-md p-2.5 text-gray-700 mr-3"
            onClick={() => setMobileMenuOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-4 flow-root mx-10">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              <h2>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to="/"
                  href="#"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#101828] hover:text-[#4F75FA] hover:bg-[#F1F4FF] font-montserrat
                    ${
                      location.pathname === "/"
                        ? "text-[#4F75FA] bg-[#F1F4FF]"
                        : ""
                    }
                  `}
                  aria-label="How it works"
                >
                  Home
                </Link>
              </h2>
              <h2>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to="/how-it-works"
                  href="#"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#101828] hover:text-[#4F75FA] hover:bg-[#F1F4FF] font-montserrat
                    ${
                      location.pathname === "/how-it-works"
                        ? "text-[#4F75FA] bg-[#F1F4FF]"
                        : ""
                    }
                  `}
                  aria-label="How it works"
                >
                  How It works
                </Link>
              </h2>
              <h2>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to="/pricing"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#101828] hover:text-[#4F75FA] hover:bg-[#F1F4FF] font-montserrat
                    ${
                      location.pathname === "/pricing"
                        ? "text-[#4F75FA] bg-[#F1F4FF]"
                        : ""
                    }
                  `}
                  aria-label="Pricing"
                >
                  Pricing
                </Link>
              </h2>
              <Menu
                as="div"
                className="block w-full -mx-3"
                ref={companyDropdownRef}
              >
                <Menu.Button
                  className="items-center rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#101828] hover:text-[#4F75FA] hover:bg-[#F1F4FF] font-montserrat flex justify-between w-full"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  Company
                  <ChevronDownIcon
                    className={`h-5 w-5 transform transition-transform ${
                      isDropdownOpen ? "rotate-180" : ""
                    }`}
                    aria-hidden="true"
                  />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 translate-y-1"
                  enterTo="transform opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="transform opacity-100 translate-y-0"
                  leaveTo="transform opacity-0 translate-y-1"
                >
                  <Menu.Items className=" z-10 p-2  focus:outline-none ml-2">
                    <div className="flex flex-col py-1 ">
                      {/* These are the dropdown links, add or change as needed */}

                      <Menu.Item>
                        <Link
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                          to={"/about-us"}
                          className={`flex text-sm items-center montserrat text-[#070707] font-[500] hover:text-newPrimary border border-white px-3 py-2 rounded-md hover:bg-[#1000C50A] gap-3 active:scale-95 transition-all duration-300`}
                        >
                          <img loading="lazy" src="/aboutus.svg" alt="" />
                          About Us
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                          to={"/blog"}
                          className={`flex hover:text-newPrimary border border-white px-3 py-2 rounded-md hover:bg-[#1000C50A] active:scale-95 transition-all duration-300 text-sm gap-3 items-center montserrat text-[#070707] font-[500]`}
                        >
                          <img loading="lazy" src="/blog.svg" alt="" />
                          Blog
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                          to={"/contact-us"}
                          className={`flex hover:text-newPrimary border border-white px-3 py-2 rounded-md hover:bg-[#1000C50A] active:scale-95 transition-all duration-300 text-sm gap-3 items-center montserrat text-[#070707] font-[500]`}
                        >
                          <img loading="lazy" src="/contactus.svg" alt="" />
                          Contact Us
                        </Link>
                      </Menu.Item>

                      {/* ... more items here ... */}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              {/* <h2>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to="/payment"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#101828] hover:text-[#4F75FA] hover:bg-[#F1F4FF] font-montserrat
                    ${
                      location.pathname === "/payment"
                        ? "text-[#4F75FA] bg-[#F1F4FF]"
                        : ""
                    }
                  `}
                  aria-label="Pricing"
                >
                  AI Review Cards
                </Link>
              </h2> */}
            </div>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              className="py-6"
              to="/sign-in"
              aria-label="Sign Up"
            >
              <button className="bg-newPrimary text-white py-2 px-5 rounded-[10px] flex">
                Login
              </button>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default MainNav;
