/* eslint-disable react/prop-types */
import { Link, Mail, User } from "lucide-react";
import { useState } from "react";
import Account from "./Account";
import Overview from "./Overview";
import Theme from "./Theme";

const UserDetail = ({ user, currency }) => {
  const [selectedTab, setSelectedTab] = useState("overview");

  return (
    <div className="w-full lg:px-5 pb-5 font-pro">
      <div
        className={`flex flex-wrap lg:justify-between font-pro mb-[13px] w-full bg-white  dark:border-none rounded-[8px]  dark:bg-darkBg  px-2 h-[45px]`}
      >
        <div className="flex overflow-x-auto lg:overflow-hidden w-full scrollbar-none gap-x-2 py-1">
          {["overview", "account", "theme"].map((item) => (
            <>
              <button
                key={item}
                className={`p-2 text-center  flex-shrink-0 rounded-lg max-w-[150px] w-full capitalize  ${selectedTab === item ? " text-newPrimary  font-semibold text-sm bg-[#f0f5ff] dark:bg-darkLightBg" : "text-[#525252]  dark:text-darkGray text-[15px] font-medium"} `}
                onClick={() => setSelectedTab(item)}
              >
                {item}
              </button>
              {item !== "theme" && <div className="border-l-2 h-8"></div>}
            </>
          ))}
        </div>
      </div>

      <div className=" overflow-y-auto scrollbar-thin">
        {!user && (
          <p className="text-center mt-10 text-[#888] text-sm">No user!</p>
        )}
        {selectedTab === "overview" && user && (
          <Overview
            profile={user?.profile}
            entity={user?.entity}
            first_name={user?.first_name}
            last_name={user?.last_name}
            mail={user?.email}
            id={user?.id}
            key={user?.id}
            totalAmount={user?.total_amount}
            currency={currency}
          />
        )}
        {selectedTab === "account" && user && (
          <Account
            profile={user?.profile}
            entity={user?.entity}
            first_name={user?.first_name}
            last_name={user?.last_name}
            mail={user?.email}
            id={user?.id}
            key={user?.id}
          />
        )}
        {selectedTab === "theme" && user && (
          <Theme id={user?.id} key={user?.id} />
        )}
        {/* {selectedTab === "subscription" && user && (
          <Subscription
            profile={user?.profile}
            key={user?.id}
            email={user?.email}
          />
        )}
     
        {selectedTab === "prompt" && user && (
          <Prompt entityId={user?.entity?.id} />
        )} */}
      </div>
    </div>
  );
};

export default UserDetail;
