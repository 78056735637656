/* eslint-disable react/prop-types */
import {
  startTransition,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { toast } from "sonner";
// import { DashboardLoading } from "../../components/loading/loading";
import {
  useGetCanEntityGenerateReviews,
  useGetEntityReviewPermissions,
  useGetReviewPlatforms,
  useGetReviewQuestions,
  usePostReviewQuestions,
  usePostReviewQuestionsByVoice,
} from "../../../query/useReview";
import { useNavigate, useParams } from "react-router";
import {
  ArrowLeft,
  Asterisk,
  Check,
  ChevronDown,
  Lock,
  Mic,
  Monitor,
  Search,
  Smartphone,
} from "lucide-react";
import { Modal, Tooltip } from "flowbite-react";
import { useSearchParams } from "react-router-dom";
import MainLogo from "../../../components/mainlogo";
import { useGetPreviewQuestionsForSets } from "../../../query/useEntity";

const QuestionPreview = ({ isOpenModal, setIsOpenModal, questionSetId }) => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { id } = useParams();
  const [parmas] = useSearchParams();
  const employeeId = parmas.get("employee");

  const { mutate, isLoading: postReviewLoading } = usePostReviewQuestions();
  const { mutate: voiceMutate, isLoading: voiceReviewLoading } =
    usePostReviewQuestionsByVoice();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState({});
  const [isCanGenerateReviews, setIsCanGenerateReviews] = useState(false);
  const [isStaffManagementEnabled, setIsStaffManagementEnabled] =
    useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState();
  const [searchDropdown, setSearchDropdown] = useState("");
  const [staffName, setStaffName] = useState("");
  const [staffTags, setStaffTags] = useState([]);
  const [staffLabel, setStaffLabel] = useState("");
  const [staffId, setStaffId] = useState(null);
  const [staffShow, setStaffShow] = useState(false);
  const [entityName, setEntityName] = useState("");
  const [entityLogo, setEntityLogo] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [buttonColor, setButtonColor] = useState("");
  const [isHoverContinue, setIsHoverContinue] = useState(false);
  const [isHoverPost, setIsHoverPost] = useState(false);
  const [isHoverGenerate, setIsHoverGenerate] = useState(false);
  const [isHoverRecording, setIsHoverRecording] = useState(false);
  const [isDirectReview, setIsDirectReview] = useState(false);
  const [isVoiceReview, setIsVoiceReview] = useState(false);
  const [isOpenDropdownKeyword, setIsOpenDropdownKeyword] = useState(false);
  const [openDropdownKeyword, setOpenDropdownKeyword] = useState();
  const [searchDropdownKeyword, setSearchDropdownKeyword] = useState({});
  const [questions, setQuestions] = useState([]);
  const [categoryOrder, setCategoryOrder] = useState([]);
  const [isMobileView, setIsMobileView] = useState(false);

  useGetPreviewQuestionsForSets(
    {
      set: questionSetId,
    },
    {
      onSuccess: (res) => {
        setQuestions(res.data.map((item) => item.question));
        setCategoryOrder(res.tabs.category);
      },
    }
  );

  const handleAnswerClick = (questionIndex, answer, isMultipleResponses) => {
    if (!isMultipleResponses) {
      setSelectedAnswers((prev) => {
        let current = { ...prev };

        if (current[questionIndex]) {
          let options = [...current[questionIndex]];

          if (options.includes(answer)) {
            // If the answer is already in the array, remove it
            options = [];
          } else {
            // If the answer is not in the array, add it
            options[0] = answer;
          }

          current[questionIndex] = options;
        } else {
          // If there are no answers for the question yet, create a new array with the answer
          current[questionIndex] = [answer];
        }

        return current;
      });
    } else {
      setSelectedAnswers((prev) => {
        let current = { ...prev };

        if (current[questionIndex]) {
          let options = [...current[questionIndex]];

          if (options.includes(answer)) {
            // If the answer is already in the array, remove it
            options = options.filter((item) => item !== answer);
          } else {
            // If the answer is not in the array, add it
            options.push(answer);
          }

          current[questionIndex] = options;
        } else {
          // If there are no answers for the question yet, create a new array with the answer
          current[questionIndex] = [answer];
        }

        return current;
      });
    }
  };

  // const categories = questions?.map((item) => item.category);
  // const categories = useMemo(
  //   () => [
  //     ...new Set(
  //       questions?.map((item) => {
  //         return { category: item.category };
  //       })
  //     ),
  //   ],
  //   [questions]
  // );
  const categories = useMemo(
    () => categoryOrder?.map((item) => item.category),
    [categoryOrder]
  );

  const keywords = useMemo(
    () => [...new Set(questions?.map((item) => item.keyword))],
    [questions]
  );

  const categoriesWithDropDown = useMemo(
    () =>
      categoryOrder
        ?.map((item) => {
          return {
            category: item.category,
            isDropdown: item?.is_dropdown,
            isMandatory: item?.is_mandatory,
          };
        })
        ?.filter(
          (item, i, arr) =>
            arr.findIndex((a) => a.category === item.category) === i
        )
        ?.concat({
          category: null,
          isDropdown: false,
          isMandatory: false,
        }),
    [categoryOrder]
  );

  const handleSelectCategory = (item) => {
    if (selectedCategory.includes(item)) {
      setSelectedCategory((prev) =>
        prev.filter((previousItem) => previousItem !== item)
      );
      setSelectedKeywords((prev) => {
        let current = { ...prev };
        delete current[item];
        return current;
      });
    } else {
      setSelectedCategory((prev) => {
        let current = [...prev];
        current.push(item);
        return current;
      });
    }
  };

  const handleKeywordClick = (category, keyword) => {
    if (selectedKeywords[category]?.includes(keyword)) {
      let notIncludedKeywords = selectedKeywords[category].filter(
        (item) => item !== keyword
      );
      setSelectedKeywords((prev) => {
        let current = { ...prev };
        let currentKeywords = [...current[category]];
        currentKeywords = notIncludedKeywords;
        current[category] = currentKeywords;
        return current;
      });
    } else {
      setSelectedKeywords((prev) => {
        let current = { ...prev };
        let currentKeywords = [...current[category]];
        currentKeywords.push(keyword);
        current[category] = currentKeywords;
        return current;
      });
    }
  };

  useEffect(() => {
    setSelectedCategory(
      categoriesWithDropDown
        ?.filter((item) => item?.isMandatory)
        ?.map((item) => item?.category)
        ?.concat(null)
    );
  }, [categoriesWithDropDown]);

  useEffect(() => {
    if (selectedCategory.length > 0) {
      selectedCategory.forEach((category) => {
        if (!selectedKeywords[category]) {
          setSelectedKeywords((prev) => {
            let current = { ...prev };
            current[category] = [];
            return current;
          });
        }
      });
    }
  }, [selectedCategory]);

  const handleNext = () => {
    const mandatoryCategories = categoriesWithDropDown?.filter(
      (item) => item?.isMandatory
    );

    const selectedAllMandatoryCategory = mandatoryCategories?.filter(
      (item) =>
        selectedCategory?.includes(item?.category) &&
        (questions
          ?.filter((q) => q?.category === item?.category)
          ?.map((item) => item?.keyword)
          ?.filter((item) => item)?.length > 0
          ? selectedKeywords[item?.category]?.length > 0
          : true)
    );
    if (mandatoryCategories?.length !== selectedAllMandatoryCategory?.length) {
      toast.error("Select at least one keyword for mandatory categories");
      return;
    }
    window.scrollTo(0, 0);
    setStep(2);
    // window.history.pushState("#step2", "Step 2", document.location + "#step2");
    Object.keys(selectedKeywords).forEach((key) => {
      if (selectedKeywords[key].length === 0) {
        // const keyword = questions?.find(
        //   (item) => item.category === key
        // ).keyword;
        setSelectedKeywords((prev) => {
          let current = { ...prev };
          current[key] = [null];
          return current;
        });
      }
    });
    // if (selectedCategory.length === 0) {
    //   setSelectedCategory(categories);
    //   categories.forEach((category) => {
    //     const keyword = questions?.find(
    //       (item) => item.category === category
    //     ).keyword;
    //     setSelectedKeywords((prev) => {
    //       let current = { ...prev };
    //       current[category] = [keyword];
    //       return current;
    //     });
    //   });
    // }
    // };
  };

  const sortedQuestions = useMemo(() => {
    return questions?.sort((a, b) => {
      // If 'a.category' is null and 'b.category' is not, put 'a' after 'b'
      if (a.category === null && b.category !== null) {
        return 1;
      }
      // If 'b.category' is null and 'a.category' is not, put 'b' after 'a'
      if (a.category !== null && b.category === null) {
        return -1;
      }
      // If both 'a.category' and 'b.category' are null or both are non-null, keep their order

      return categories?.indexOf(a.category) - categories?.indexOf(b.category);
    });
  }, [questions, categories]);

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function () {
        setStep(1);
      },
      false
    );

    return () => {
      window.removeEventListener(
        "popstate",
        function () {
          setStep(1);
        },
        false
      );
    };
  }, []);

  useEffect(() => {
    if (categoryOrder?.length === 0) {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [categoryOrder]);

  return (
    <Modal
      dismissible
      popup
      show={isOpenModal}
      onClose={() => {
        setIsOpenModal(false);
      }}
      size={"8xl"}
      className="z-[1000]"
      theme={{
        content: {
          base: "relative w-full p-4 h-auto",
          inner: "bg-white dark:bg-darkBg rounded-lg",
        },
        header: {
          base: "flex items-start justify-between rounded-t-lg border-b p-5 dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
          close: {
            base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
          },
        },
      }}
    >
      <Modal.Header>
        <p className="font-pro font-medium text-xl pt-1 px-5">
          Preview Question Set
        </p>
      </Modal.Header>
      <Modal.Body className="h-[88dvh]">
        <div className="absolute top-[90px] left-1/2 -translate-x-1/2 lg:flex items-center bg-lightpurple dark:bg-darkLightBg rounded-md  hidden">
          {step === 2 && (
            <button
              onClick={() => setStep((prev) => prev - 1)}
              className=" lg:flex p-2 w-[48px] h-[48px] items-center justify-center"
            >
              <ArrowLeft className="size-5" />
            </button>
          )}
          <button
            onClick={() => setIsMobileView((prev) => !prev)}
            className={` lg:flex  p-2 w-[48px] h-[48px] items-center justify-center ${step === 2 && "border-l"}`}
          >
            {isMobileView ? (
              <Monitor className="size-5" />
            ) : (
              <Smartphone className="size-5" />
            )}
          </button>
        </div>
        <div
          className={`min-h-screen py-10 px-5  text-black ${isMobileView ? "max-w-md px-5 py-10 mx-auto" : "lg:p-20 px-5 py-10"}`}
          style={{
            backgroundColor: bgColor || "#7190FF",
          }}
        >
          <div
            className={`bg-white min-h-[90vh] rounded-[16px]   space-y-5 ${isMobileView ? "py-8 px-5" : "lg:p-12 py-8 px-5"}`}
          >
            {step === 1 && (
              <>
                {postReviewLoading || voiceReviewLoading ? (
                  <div className="w-full h-[70vh] flex flex-col justify-center items-center gap-5">
                    <img
                      loading="lazy"
                      src="/mr-logo.gif"
                      alt="Loading Logo"
                      className="w-20 h-20"
                    />
                    <p className="animate-pulse">
                      Hold on while we get your reviews ...
                    </p>
                  </div>
                ) : (
                  <>
                    {entityLogo ? (
                      <div className="flex flex-col justify-center items-center mb-5">
                        <img
                          src={entityLogo}
                          className="w-[116px] h-[116px] rounded-full"
                          loading="eager"
                        />
                        <p
                          className={`text-[22px] lg:text-[28px] leading-[30px] text-[#434343] font-poppins font-semibold text-center mt-3 hidden`}
                        >
                          {entityName || ""}
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center mb-5">
                        <img
                          src="/new-logo.svg"
                          className="w-[90px] h-[90px]"
                        />
                        <p
                          className={`text-[34px] -tracking-[1px] leading-[30px] text-[#434343] font-avertaBold text-center mt-3`}
                        >
                          magicreview
                        </p>
                        <p className="font-heebo text-[#353535] mt-1.5">
                          Review Made Easy!
                        </p>
                      </div>
                    )}

                    <p className="font-medium text-[28px] font-heebo -tracking-[1px] leading-[36px]">
                      What would you like <br className="lg:hidden" /> to review
                      on?
                    </p>
                    <div className="w-full space-y-5">
                      <div
                        className={`w-full grid  gap-5 ${isMobileView ? "grid-cols-1" : "grid-cols-1 lg:grid-cols-2"}`}
                      >
                        {categoriesWithDropDown
                          // ?.filter((item) => item?.category !== null)
                          ?.map((category, i) => (
                            <div key={i} className="flex flex-col space-y-5">
                              {category?.category && (
                                <button
                                  // key={i}
                                  onClick={() =>
                                    handleSelectCategory(category?.category)
                                  }
                                  className={`flex items-center justify-between px-4 py-4 border rounded-lg text-[#4A4A4A] w-full font-heebo  ${selectedCategory?.includes(category?.category) ? "bg-secondary/30 border-secondary" : "border-[#bbb]"}`}
                                >
                                  <div className="flex items-center">
                                    <span
                                      className={`w-5 h-5 border rounded-full flex items-center justify-center mr-2  ${selectedCategory?.includes(category?.category) ? "bg-secondary border-secondary" : "border-[#bbb]"}`}
                                    >
                                      {selectedCategory.includes(
                                        category?.category
                                      ) ? (
                                        <svg
                                          width="12"
                                          height="10"
                                          viewBox="0 0 12 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M0.757324 6.16621L3.72686 9.13574L11.1507 1.71191"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    {category?.category}
                                  </div>
                                  {category?.isMandatory && (
                                    <Asterisk className="text-red-500" />
                                  )}
                                </button>
                              )}

                              {selectedCategory.length > 0 &&
                                selectedCategory.includes(
                                  category?.category
                                ) && (
                                  <>
                                    {category?.isDropdown ? (
                                      <div className="w-full relative mt-3">
                                        <button
                                          className="w-full h-14 bg-white rounded-lg flex justify-between items-center px-5 text-[#4A4A4A] font-heebo text-lg"
                                          style={{
                                            border: `${buttonColor} 1px solid`,
                                          }}
                                          onClick={() => {
                                            if (
                                              openDropdownKeyword !==
                                              category?.category
                                            ) {
                                              setIsOpenDropdownKeyword(true);
                                              setOpenDropdownKeyword(
                                                category?.category
                                              );
                                            } else {
                                              setIsOpenDropdownKeyword(
                                                (prev) => !prev
                                              );
                                              setOpenDropdownKeyword(
                                                category?.category
                                              );
                                            }
                                          }}
                                        >
                                          <p>
                                            {selectedKeywords[
                                              category?.category
                                            ]?.length
                                              ? selectedKeywords[
                                                  category?.category
                                                ]?.join(", ")
                                              : "Select keyword"}
                                          </p>
                                          {/* <div className="flex flex-wrap w-full">
                                      <p className="w-auto">
                                        {selectedKeywords[
                                          category?.category
                                        ]?.join(", ")}
                                      </p>
                                      <div className="relative w-auto">
                                        <input
                                          type="text"
                                          value={searchDropdownKeyword}
                                          onChange={(e) =>
                                            setSearchDropdownKeyword(
                                              e.target.value
                                            )
                                          }
                                          className="w-full font-pro border-none focus:appearance-none focus:outline-none focus:ring-0 focus:border-none"
                                          placeholder={
                                            !selectedKeywords[
                                              category?.category
                                            ]?.length &&
                                            "Search & Select Keyword"
                                          }
                                        />
                                      </div>
                                    </div> */}
                                          <ChevronDown
                                            className={`w-5 h-5 ${isOpenDropdownKeyword && openDropdownKeyword === category?.category && "rotate-180"} transition-all duration-200`}
                                          />
                                        </button>
                                        {isOpenDropdownKeyword &&
                                          openDropdownKeyword ===
                                            category?.category && (
                                            <>
                                              <div
                                                className="w-full p-5 rounded-lg absolute top-16 left-0 space-y-3 z-50 text-left max-h-[200px] overflow-y-auto scrollbar-thin shadow-xl bg-white"
                                                style={{
                                                  border: `${buttonColor} 1px solid`,
                                                }}
                                              >
                                                <div className="relative w-full">
                                                  <input
                                                    type="text"
                                                    value={
                                                      searchDropdownKeyword?.[
                                                        category?.category
                                                      ]
                                                    }
                                                    onChange={(e) =>
                                                      setSearchDropdownKeyword({
                                                        [category?.category]:
                                                          e.target.value,
                                                      })
                                                    }
                                                    className="w-full border font-pro border-[#e7e4e4] rounded-lg"
                                                    placeholder="Search"
                                                  />
                                                  <Search className="text-[#615E83] w-5 h-5 absolute right-3 top-1/2 -translate-y-1/2" />
                                                </div>

                                                {[
                                                  ...new Set(
                                                    questions
                                                      ?.filter(
                                                        (item) =>
                                                          item.category ===
                                                          category?.category
                                                      )
                                                      ?.filter(
                                                        (item) =>
                                                          item.keyword !== null
                                                      )
                                                      .map(
                                                        (item) => item.keyword
                                                      )
                                                  ),
                                                ]
                                                  ?.filter(
                                                    (item) =>
                                                      item
                                                        ?.toLowerCase()
                                                        ?.indexOf(
                                                          (
                                                            searchDropdownKeyword?.[
                                                              category?.category
                                                            ] || ""
                                                          )?.toLowerCase()
                                                        ) > -1
                                                  )
                                                  ?.map((keyword, i) => (
                                                    <p
                                                      key={keyword}
                                                      onClick={() => {
                                                        handleKeywordClick(
                                                          category?.category,
                                                          keyword
                                                        );
                                                        setIsOpenDropdownKeyword(
                                                          false
                                                        );
                                                      }}
                                                      className={`cursor-pointer hover:bg-gray-100 px-2 flex items-center justify-between rounded py-1`}
                                                      style={{
                                                        color:
                                                          selectedKeywords[
                                                            category?.category
                                                          ]?.includes(
                                                            keyword
                                                          ) && buttonColor,
                                                      }}
                                                    >
                                                      {keyword}
                                                      {selectedKeywords[
                                                        category?.category
                                                      ]?.includes(keyword) && (
                                                        <Check className="w-4 h-4" />
                                                      )}
                                                    </p>
                                                  ))}
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    ) : (
                                      <div className="flex flex-wrap gap-2">
                                        {[
                                          ...new Set(
                                            questions
                                              ?.filter(
                                                (item) =>
                                                  item.category ===
                                                  category?.category
                                              )
                                              ?.filter(
                                                (item) => item.keyword !== null
                                              )
                                              .map((item) => item.keyword)
                                          ),
                                        ]?.map((keyword, i) => (
                                          <button
                                            onClick={() =>
                                              handleKeywordClick(
                                                category?.category,
                                                keyword
                                              )
                                            }
                                            key={i}
                                            className={`text-center px-3.5 py-2 border  rounded-[8px] ${
                                              selectedKeywords[
                                                category?.category
                                              ]?.includes(keyword)
                                                ? "border-[#ba9a46] bg-[#fffaea] text-[#ba9a46]"
                                                : "border-[#4a4a4a]"
                                            }`}
                                          >
                                            {keyword}
                                          </button>
                                        ))}
                                      </div>
                                    )}
                                  </>
                                )}
                            </div>
                          ))}
                      </div>

                      <div className="w-full flex lg:flex-row-reverse flex-col gap-5">
                        {!isDirectReview ? (
                          <button
                            className={`w-full py-4 rounded-lg  text-center lg:text-[20px] active:scale-95 transition-all duration-200 border border-secondary bg-secondary hover:bg-white text-white hover:text-secondary`}
                            onClick={handleNext}
                          >
                            Continue
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {step === 2 && (
              <>
                {postReviewLoading || voiceReviewLoading ? (
                  <div className="w-full h-[70vh] flex flex-col justify-center items-center gap-5">
                    <img
                      loading="lazy"
                      src="/mr-logo.gif"
                      alt="Loading Logo"
                      className="w-20 h-20"
                    />
                    <p className="animate-pulse">
                      Hold on while we get your reviews ...
                    </p>
                  </div>
                ) : (
                  <>
                    {entityLogo ? (
                      <div className="flex flex-col justify-center items-center mb-5">
                        <img
                          src={entityLogo}
                          className="w-[116px] h-[116px] rounded-full"
                          loading="eager"
                        />
                        <p
                          className={`text-[22px] lg:text-[28px] leading-[30px] text-[#434343] font-poppins font-semibold text-center mt-3 hidden`}
                        >
                          {entityName || ""}
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center mb-5">
                        <img
                          src="/new-logo.svg"
                          className="w-[90px] h-[90px]"
                        />
                        <p
                          className={`text-[34px] -tracking-[1px] leading-[30px] text-[#434343] font-avertaBold text-center mt-3`}
                        >
                          magicreview
                        </p>
                        <p className="font-heebo text-[#353535] mt-1.5">
                          Review Made Easy!
                        </p>
                      </div>
                    )}

                    <div className="flex flex-col gap-2">
                      {sortedQuestions
                        ?.filter(
                          (item) =>
                            selectedCategory?.includes(item.category) ||
                            item?.category === null
                        )
                        ?.filter((item) =>
                          // item?.category === null
                          //   ? item
                          //   : selectedKeywords[item.category]?.includes(
                          //       item.keyword
                          //     )
                          item?.category === null && item?.keyword === null
                            ? item
                            : selectedKeywords[item.category]?.includes(
                                item.keyword
                              )
                        )
                        ?.map((item, questionIndex) => {
                          return item?.question ? (
                            item?.show_as === "DROPDOWN" ? (
                              <div key={questionIndex} className="mb-5">
                                <div className="flex items-center gap-x-2">
                                  <p className="font-medium text-[28px] font-heebo -tracking-[1px] leading-[36px]">
                                    {item.question}
                                  </p>
                                  {item?.is_mandatory && (
                                    <Asterisk className="text-red-500" />
                                  )}
                                </div>
                                <div className="w-full relative mt-5">
                                  <button
                                    className="w-full h-14 bg-white rounded-lg flex justify-between items-center px-5 text-[#4A4A4A] font-heebo text-lg"
                                    style={{
                                      border: `${buttonColor} 1px solid`,
                                    }}
                                    onClick={() => {
                                      if (openDropdownId !== item?.id) {
                                        setIsOpenDropdown(true);
                                        setOpenDropdownId(item?.id);
                                      } else {
                                        setIsOpenDropdown((prev) => !prev);
                                        setOpenDropdownId(item?.id);
                                      }
                                    }}
                                  >
                                    <p>
                                      {selectedAnswers[item?.id]?.length
                                        ? item?.has_multiple_responses
                                          ? selectedAnswers[item?.id]?.join(
                                              ", "
                                            )
                                          : selectedAnswers[item?.id]?.[0]
                                        : "Select response"}
                                    </p>
                                    <ChevronDown
                                      className={`w-5 h-5 ${isOpenDropdown && openDropdownId === item?.id && "rotate-180"} transition-all duration-200`}
                                    />
                                  </button>
                                  {isOpenDropdown &&
                                    openDropdownId === item?.id && (
                                      <>
                                        <div
                                          className="w-full p-5 rounded-lg absolute top-16 left-0 space-y-3 z-50 text-left max-h-[200px] overflow-y-auto scrollbar-thin shadow-xl bg-white"
                                          style={{
                                            border: `${buttonColor} 1px solid`,
                                          }}
                                        >
                                          <div className="relative w-full">
                                            <input
                                              type="text"
                                              value={searchDropdown}
                                              onChange={(e) =>
                                                setSearchDropdown(
                                                  e.target.value
                                                )
                                              }
                                              className="w-full border font-pro border-[#e7e4e4] rounded-lg"
                                              placeholder="Search"
                                            />
                                            <Search className="text-[#615E83] w-5 h-5 absolute right-3 top-1/2 -translate-y-1/2" />
                                          </div>

                                          {item?.response_options
                                            ?.filter(
                                              (item) =>
                                                item
                                                  ?.toLowerCase()
                                                  ?.indexOf(
                                                    searchDropdown?.toLowerCase()
                                                  ) > -1
                                            )
                                            ?.map((answer, i) => (
                                              <p
                                                key={answer}
                                                onClick={() => {
                                                  handleAnswerClick(
                                                    // item.category + item.keyword,
                                                    item.id,
                                                    answer,
                                                    item?.has_multiple_responses
                                                  );
                                                  setIsOpenDropdown(false);
                                                }}
                                                className={`cursor-pointer hover:bg-gray-100 px-2 flex items-center justify-between rounded py-1`}
                                                style={{
                                                  color:
                                                    selectedAnswers[
                                                      item.id
                                                    ]?.includes(answer) &&
                                                    buttonColor,
                                                }}
                                              >
                                                {answer}
                                                {selectedAnswers[
                                                  item.id
                                                ]?.includes(answer) && (
                                                  <Check className="w-4 h-4" />
                                                )}
                                              </p>
                                            ))}
                                        </div>
                                      </>
                                    )}
                                </div>
                              </div>
                            ) : (
                              <div
                                className="flex flex-col rounded-2xl bg-white mb-5"
                                key={questionIndex}
                              >
                                <div className="flex items-center gap-x-2">
                                  <p className="font-medium text-[28px] font-heebo -tracking-[1px] leading-[36px]">
                                    {item.question}
                                  </p>
                                  {item?.is_mandatory && (
                                    <Asterisk className="text-red-500" />
                                  )}
                                </div>
                                <div
                                  className={`grid  gap-4 mt-5 ${isMobileView ? "grid-cols-1" : "grid-cols-1 lg:grid-cols-2"}`}
                                >
                                  {item.response_options.map((answer, i) => (
                                    <button
                                      key={answer}
                                      onClick={() =>
                                        handleAnswerClick(
                                          // item.category + item.keyword,
                                          item.id,
                                          answer,
                                          item?.has_multiple_responses
                                        )
                                      }
                                      // className={`flex items-center px-4 py-4 border rounded-lg text-[#4A4A4A] ${
                                      //   selectedAnswers[item.id]?.includes(
                                      //     answer
                                      //   ) && "border-secondary bg-[#ECEAFF]"
                                      // }`}
                                      className={`flex items-center px-4 py-4 border rounded-lg text-[#4A4A4A] ${
                                        selectedAnswers[item.id]?.includes(
                                          answer
                                        )
                                          ? "bg-secondary/30 border-secondary"
                                          : "border-[#bbb]"
                                      }`}
                                      // style={{
                                      //   backgroundColor: selectedAnswers[
                                      //     item.id
                                      //   ]?.includes(answer)
                                      //     ? (buttonColor + 50 || "")
                                      //     : "white",
                                      //   border: `${
                                      //     selectedAnswers[item.id]?.includes(
                                      //       answer
                                      //     )
                                      //       ? buttonColor
                                      //       : "#bbb"
                                      //   } 1px solid`,
                                      // }}
                                    >
                                      <span
                                        className={`w-5 h-5 border rounded-full flex items-center justify-center mr-2  ${
                                          selectedAnswers[item.id]?.includes(
                                            answer
                                          )
                                            ? "bg-secondary border-secondary"
                                            : "border-[#bbb]"
                                        }`}
                                      >
                                        {selectedAnswers[item.id]?.includes(
                                          answer
                                        ) ? (
                                          <svg
                                            width="12"
                                            height="10"
                                            viewBox="0 0 12 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M0.757324 6.16621L3.72686 9.13574L11.1507 1.71191"
                                              stroke="white"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                      {answer}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            )
                          ) : null;
                        })}
                    </div>
                  </>
                )}
              </>
            )}
            {step === 3 && (
              <>
                {entityLogo ? (
                  <div className="flex flex-col justify-center items-center mb-5">
                    <img
                      src={entityLogo}
                      className="w-[116px] h-[116px] rounded-full"
                      loading="eager"
                    />
                    <p
                      className={`text-[22px] lg:text-[28px] leading-[30px] text-[#434343] font-poppins font-semibold text-center mt-3 hidden`}
                    >
                      {entityName || ""}
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center mb-5">
                    <img src="/new-logo.svg" className="w-[90px] h-[90px]" />
                    <p
                      className={`text-[34px] -tracking-[1px] leading-[30px] text-[#434343] font-avertaBold text-center mt-3`}
                    >
                      magicreview
                    </p>
                    <p className="font-heebo text-[#353535] mt-1.5">
                      Review Made Easy!
                    </p>
                  </div>
                )}
                {/* <VoiceReview
                  entityId={id}
                  setStep={setStep}
                  keywords={selectedKeywords}
                /> */}
              </>
            )}
            <div className="md:flex items-center justify-center gap-5 hidden pt-10 ">
              <div className="h-[1px] w-full bg-[#ebebeb]"></div>
              <div className="flex items-center justify-between flex-shrink-0">
                <p className="font-poppins text-[#888] text-xl flex-shrink-0">
                  Powered by
                </p>
                <MainLogo className={"flex-shrink-0"} />
              </div>

              {/* <div className="flex items-center gap-x-2 flex-shrink-0">
              <img
                src={"/new-logo.svg"}
                alt="MagicReview Logo"
                className="w-[50px] flex-shrink-0"
              />
              <h1 className="font-avertaBold font-bold text-[30px] text-[#434343] -tracking-[1%]">
                magicreview
              </h1>
            </div> */}
              <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div>
            </div>
            <div className="md:hidden pt-10">
              <div className="flex items-center justify-center gap-5 ">
                <div className="h-[1px] w-full bg-[#ebebeb]"></div>
                <p className="font-poppins text-[#888] text-sm flex-shrink-0">
                  Powered by
                </p>
                <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div>
              </div>

              <div className="flex items-center justify-center gap-x-2 flex-shrink-0 mt-3">
                <img
                  src={"/new-logo.svg"}
                  alt="MagicReview Logo"
                  className="w-[30px] flex-shrink-0"
                />
                <h1 className="font-avertaBold font-bold text-[18px] text-[#434343] -tracking-[1%]">
                  magicreview
                </h1>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuestionPreview;
