/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Switch } from "@headlessui/react";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { Modal, Tooltip } from "flowbite-react";
import { Asterisk, CirclePlus, CircleX, Pencil, X } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "sonner";
import { LoadingButton } from "../../../components/loading/loading";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import {
  useDeleteQuestionCategories,
  useDeleteQuestionKeyword,
  useGetQuestionCategories,
  useGetQuestionKeywords,
  useGetQuestionSets,
  usePostAddEntityQuestion,
  usePostAddQuestionCategories,
  usePostAddQuestionKeyword,
  usePostAddQuestionSet,
  usePutEditQuestionCategories,
  usePutEditQuestionKeyword,
} from "../../../query/useEntity";
import { DeleteModal } from "./modal";
import QuestionSetDropdownForModal from "./QuestionSetDropdownForModal";
import CategoryDropdownForModal from "./CategoryDropdownForModal";
import KeywordDropdownForModal from "./KeywordDropdownForModal";
import ResponseOptionsForModal from "./ResponseOptionsForModal";
import Joyride, { STATUS } from "react-joyride";
import CustomTooltipForJoyride from "./CustomTooltipForJoyride";
import { useSearchParams } from "react-router-dom";

const AddQuestionModal = ({ openAddModal, setOpenAddModal, questionSet }) => {
  const [params] = useSearchParams();
  const referer = params.get("referrer");
  const [question, setQuestion] = useState({
    question: "",
    category_link: null,
    keyword_link: null,
    response_options: ["Excellent", "Good", "Average", "Bad"],
    has_multiple_responses: false,
    show_as: "CHIPS",
    sets: [],
    is_mandatory: false,
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [selectedQuestionSets, setSelectedQuestionSets] = useState([]);
  const [isOpenDeleteCategory, setIsOpenDeleteCategory] = useState(false);
  const [deleteCategorySlug, setDeleteCategorySlug] = useState("");
  const [isOpenDeleteKeyword, setIsOpenDeleteKeyword] = useState(false);
  const [deleteKeywordSlug, setDeleteKeywordSlug] = useState("");
  const [error, setError] = useState("");

  const joyRideStepsForAddQuestions = [
    {
      title: "Question Set",
      content: "Create new question sets or select existing ones.",
      target: ".question-set",
      disableBeacon: true,
    },
    {
      title: "Category & Keyword",
      content:
        "Add or select categories and keywords. These are optional but can help target specific areas.",
      target: ".category",
    },
    {
      title: "Enter Question",
      content:
        "Click to start adding a question that you would like to ask your customers.",
      target: ".add-question",
    },
    {
      title: "Add Responses",
      content: "Add new, delete, or edit existing responses of your choice.",
      target: ".add-responses",
    },
    {
      title: "Import Bulk Responses",
      content:
        "Upload a .txt file with line-separated responses for quick bulk import.",
      target: ".bulk-responses",
    },
    {
      title: "Display Responses",
      content:
        "Show responses as chips or in dropdown format, allowing multiple selections per question.",
      target: ".display-responses",
      referrer: "Close Add Question",
      referrerFunc: function () {
        setOpenAddModal(false);
      },
    },
  ];

  const [step, setStep] = useState({
    run: false,
    steps: joyRideStepsForAddQuestions,
    stepIndex: 0,
  });

  useEffect(() => {
    if (referer === "verify-email") {
      setStep((prev) => {
        return { ...prev, run: true };
      });
    }
  }, []);

  const { data: categories, refetch: refetchCategory } =
    useGetQuestionCategories();
  const { mutate: deleteCategory, isLoading: deleteCategoryLoading } =
    useDeleteQuestionCategories();

  const { data: keywords, refetch: refetchKeyword } = useGetQuestionKeywords();
  const { mutate: deleteKeyword, isLoading: deleteKeywordLoading } =
    useDeleteQuestionKeyword();

  const { data: questionSets, refetch: refetchQuestionSets } =
    useGetQuestionSets();

  const { mutate, isLoading } = usePostAddEntityQuestion();
  const queryClient = useQueryClient();

  const handleDeleteCategory = () => {
    deleteCategory(deleteCategorySlug, {
      onSuccess: (res) => {
        toast.success("Deleted category successfully");
        refetchCategory();
        setIsOpenDeleteCategory(false);
        const deleteCategory = deleteCategorySlug?.split("-")?.join(" ");
        if (deleteCategory === selectedCategory?.toLowerCase()) {
          setSelectedCategory(null);
          setQuestion((prev) => {
            const current = { ...prev };
            current.category_link = null;
            return current;
          });
        }
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleDeleteKeyword = () => {
    deleteKeyword(deleteKeywordSlug, {
      onSuccess: (res) => {
        toast.success("Deleted keyword successfully");
        refetchKeyword();
        setIsOpenDeleteKeyword(false);
        const deleteKeyword = deleteKeywordSlug?.split("-")?.join(" ");
        if (deleteKeyword === selectedKeyword?.toLowerCase()) {
          setSelectedKeyword(null);
          setQuestion((prev) => {
            const current = { ...prev };
            current.keyword_link = null;
            return current;
          });
        }
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handlePush = () => {
    mutate(question, {
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ["getDefaultQuestions"] });
        queryClient.invalidateQueries({
          queryKey: ["getDefaultQuestionsForSets"],
        });
        setOpenAddModal(false);
        toast.success("Question added successfully");
        setError("");
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else if (err.response.data.category_link) {
          toast.error(err.response.data.category_link[0]);
        } else if (err.response.data.keyword_link) {
          toast.error(err.response.data.keyword_link[0]);
        } else if (err.response.data["question:"]) {
          toast.error(err.response.data["question:"][0]);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  useEffect(() => {
    if (questionSet && selectedQuestionSets?.length === 0) {
      setSelectedQuestionSets([{ name: questionSet }]);
      setQuestion((prev) => {
        let current = { ...prev };
        current.sets = [{ name: questionSet }];
        return current;
      });
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStep((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  if (isLoading) {
    return <LoadingButton />;
  }

  return (
    <>
      <Modal
        popup
        dismissible={isOpenDeleteCategory || isOpenDeleteKeyword ? false : true}
        show={openAddModal}
        onClose={() => {
          setOpenAddModal(false);
        }}
        size={"5xl"}
        className="z-[1000]"
        theme={{
          content: {
            base: "relative p-4 h-auto overflow-y-hidden",
            inner: "bg-white dark:bg-darkBg rounded-lg",
          },
          sizes: {
            sm: "max-w-sm",
            md: "max-w-md",
            lg: "max-w-lg",
            xl: "max-w-xl",
            "2xl": "max-w-2xl",
            "3xl": "max-w-3xl",
            "4xl": "max-w-4xl",
            "5xl": "max-w-5xl",
            "6xl": "max-w-6xl",
            "7xl": "max-w-7xl",
          },
          header: {
            base: "flex items-start justify-between rounded-t-lg border-b p-5 dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
            close: {
              base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
            },
          },
        }}
      >
        <Modal.Header>
          <header className="flex items-center mb-3 mt-5 px-[24px] sm:px-[35px] ">
            <h1 className=" text-[24px] font-medium font-pro ">Add Question</h1>
          </header>
        </Modal.Header>
        <Modal.Body className=" px-[24px] sm:px-[35px] flex flex-col scrollbar-none font-pro lg:w-[800px]">
          <div className="h-[70vh] overflow-y-auto scrollbar-thin p-1">
            {/* <button
              onClick={() => {
                setStep((prev) => {
                  return {
                    ...prev,
                    run: true,
                  };
                });
              }}
            >
              Start tour
            </button> */}
            <QuestionSetDropdownForModal
              questionSets={questionSets}
              selectedQuestionSets={selectedQuestionSets}
              setSelectedQuestionSets={setSelectedQuestionSets}
              setQuestion={setQuestion}
              error={error}
              setError={setError}
            />
            <div className="category">
              <CategoryDropdownForModal
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setQuestion={setQuestion}
                isOpenDeleteCategory={isOpenDeleteCategory}
                setIsOpenDeleteCategory={setIsOpenDeleteCategory}
                setDeleteCategorySlug={setDeleteCategorySlug}
                categories={categories}
                refetchCategory={refetchCategory}
              />
              <KeywordDropdownForModal
                selectedKeyword={selectedKeyword}
                setSelectedKeyword={setSelectedKeyword}
                setQuestion={setQuestion}
                isOpenDeleteKeyword={isOpenDeleteKeyword}
                setIsOpenDeleteKeyword={setIsOpenDeleteKeyword}
                setDeleteKeywordSlug={setDeleteKeywordSlug}
                keywords={keywords}
                refetchKeyword={refetchKeyword}
              />
            </div>

            {/* <div className="py-2 grid grid-cols-2 lg:flex lg:flex-wrap gap-[19px]">
              {question?.response_options?.map((answer, index) => (
                <div className="relative w-full lg:w-auto" key={index}>
                  <span
                    className={`w-5 h-5 border rounded-full mr-2 bg-white border-[#c2c2c2] absolute top-5 left-3`}
                  ></span>
                  <input
                    value={answer}
                    onChange={(e) => {
                      handleResponseOptionChange(e.target.value, index);
                    }}
                    className={`flex items-center px-10 py-4 border rounded-lg w-full lg:w-auto text-[#4A4A4A]  font-pro text-base border-[#c2c2c2] focus:border-secondary focus:appearance-none focus:ring-1 focus:ring-secondary
                 `}
                  />
                  <CircleX
                    className="w-4 h-4 absolute top-1.5 right-2 cursor-pointer"
                    onClick={() => handleResponseOptionDelete(index)}
                  />
                </div>
              ))}
              <div
                className="text-black/70 flex justify-center items-center cursor-pointer"
                onClick={handleAddOption}
              >
                <CirclePlus
                  className="w-9 h-9 text-[#6e6e6e]"
                  strokeWidth="1.5"
                />
              </div>
            </div>

            <div className="mt-5 mb-5 lg:mb-10 flex items-center gap-5 flex-wrap">
              <input
                type="file"
                hidden
                ref={inputRef}
                onChange={handleFileChange}
                accept=".txt"
              />
              <Tooltip
                content="Only accepts .txt file"
                theme={{
                  target: "w-fit",
                  arrow: {
                    base: "absolute z-10 h-2 w-2 rotate-45",
                    style: {
                      dark: "bg-gray-900 dark:bg-gray-700",
                      light: "bg-white",
                      auto: "bg-white dark:bg-gray-700",
                    },
                    placement: "-4px",
                  },
                }}
              >
                <button
                  className="flex items-center justify-center rounded-[7px] border border-secondary text-white text-center py-3 px-3 bg-[#7190FF] hover:bg-white hover:text-secondary transition-all duration-200"
                  onClick={() => inputRef?.current?.click()}
                >
                  Import bulk responses
                </button>
              </Tooltip>
              <p className="text-[#888]">(Line seperated responses)</p>
            </div>

            <div className="flex items-center gap-x-3 my-5">
              <p>Show response options as:</p>
              <div className="flex items-center gap-x-3">
                <div className="flex items-center gap-x-3">
                  <input
                    type="radio"
                    name="show-as"
                    id="chips"
                    checked={question?.show_as === "CHIPS"}
                    onClick={() =>
                      setQuestion((prev) => {
                        let current = { ...prev };
                        current.show_as = "CHIPS";
                        return current;
                      })
                    }
                    className="cursor-pointer"
                    readOnly
                  />
                  <label htmlFor="chips" className="cursor-pointer">
                    Chips
                  </label>
                </div>
                <div className="flex items-center gap-x-3">
                  <input
                    type="radio"
                    name="show-as"
                    id="dropdown"
                    checked={question?.show_as === "DROPDOWN"}
                    className="cursor-pointer"
                    onClick={() =>
                      setQuestion((prev) => {
                        let current = { ...prev };
                        current.show_as = "DROPDOWN";
                        return current;
                      })
                    }
                    readOnly
                  />
                  <label htmlFor="dropdown" className="cursor-pointer">
                    Dropdown
                  </label>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-x-3 my-5">
              <label htmlFor="responses">Multiple Responses:</label>
              <Switch
                checked={question?.has_multiple_responses}
                onChange={() =>
                  setQuestion((prev) => {
                    let current = { ...prev };
                    if (current.has_multiple_responses) {
                      current.has_multiple_responses = false;
                    } else {
                      current.has_multiple_responses = true;
                    }
                    return current;
                  })
                }
                className={`${question?.has_multiple_responses ? "bg-[#3183FF]" : "bg-[#8E8B8B]"}
                relative h-6 w-12  shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
              >
                <span className="sr-only">Toggle Multiple Responses</span>
                <span
                  aria-hidden="true"
                  className={`${question?.has_multiple_responses ? "translate-x-6" : "translate-x-0"}
                  pointer-events-none inline-block h-5 w-5  transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            </div> */}
            <ResponseOptionsForModal
              question={question}
              setQuestion={setQuestion}
            />

            <div className="flex flex-wrap lg:gap-2 justify-between lg:justify-end">
              <button
                className="flex items-center justify-center rounded-[7px] border border-newPrimary text-newPrimary text-base text-center font-medium py-[10px]  px-[40px] bg-white dark:bg-darkLightBg gap-[6px] mt-[20px] w-[49.5%] lg:w-auto"
                onClick={() => {
                  setOpenAddModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="flex items-center justify-center rounded-[7px] border border-newPrimary text-white text-base text-center font-medium py-[10px]  px-[40px] bg-newPrimary hover:bg-white hover:text-newPrimary gap-[6px] mt-[20px] w-[49.5%] lg:w-auto transition-all duration-200 dark:hover:bg-darkLightBg"
                onClick={() => {
                  handlePush();
                }}
                disabled={isLoading || selectedQuestionSets?.length === 0}
              >
                {/* {isLoading ? <LoadingButton /> : "Save"} */}
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <DeleteModal
        openModal={isOpenDeleteCategory}
        setOpenModal={setIsOpenDeleteCategory}
        title={`Are you sure want to delete the category "${categories?.find((item) => item?.category_slug === deleteCategorySlug)?.category}"`}
        handleDelete={handleDeleteCategory}
        isLoading={deleteCategoryLoading}
      />
      <DeleteModal
        openModal={isOpenDeleteKeyword}
        setOpenModal={setIsOpenDeleteKeyword}
        title={`Are you sure want to delete the keyword "${keywords?.find((item) => item?.keyword_slug === deleteKeywordSlug)?.keyword}"`}
        handleDelete={handleDeleteKeyword}
        isLoading={deleteKeywordLoading}
      />
      <Joyride
        callback={handleJoyrideCallback}
        run={step.run}
        steps={step.steps}
        continuous={true}
        // stepIndex={step.stepIndex}
        scrollToFirstStep
        showProgress
        showSkipButton
        // disableScrolling={true}
        styles={{
          options: {
            primaryColor: "#7190FF",
            zIndex: 10000,
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      />
    </>
  );
};

export default AddQuestionModal;
