import Skeleton from "react-loading-skeleton";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { useGetBillingStats } from "../../../query/useMaster";
import Layout from "../component/Layout";
import { dayFormatter, nextDay } from "../../../utils/formatter";
import NonContinuousProgressBar from "../../master/components/NonContinuousProgressBar";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Infinity } from "lucide-react";
import { useGetSubUserBilling } from "../../../query/useAuthentication";

const SubUserBilling = () => {
  const { darkMode } = useDarkMode();

  const { data: billingStats, isLoading: billingStatsLoading } =
    useGetSubUserBilling();

  const data = [
    { name: "A", value: billingStats?.total_usage_used, color: "#4F75FA" },
    {
      name: "B",
      value:
        billingStats?.usage_limit - billingStats?.total_usage_used < 0
          ? 0
          : billingStats?.usage_limit - billingStats?.total_usage_used,
      color: "#F0E5FC",
    },
  ];

  return (
    <Layout title={"Billing"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-140px)] px-5 lg:px-[30.2px] font-pro ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:max-w-[890px]">
          <div className="flex flex-col w-full lg:max-w-[430px] gap-5">
            <div className="flex w-full flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none h-full">
              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <>
                  <p className="font-medium text-lg">Total Credits Used</p>
                  <p className="text-sm text-[#808080]">
                    From{" "}
                    {billingStats?.start_date &&
                      dayFormatter(billingStats?.start_date)}{" "}
                    -{" "}
                    {billingStats?.end_date &&
                      dayFormatter(billingStats?.end_date)}
                  </p>
                </>
              )}
              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  height={50}
                  inline
                  className="w-full mt-5"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <>
                  <p className="font-semibold text-[38px] mt-8 mb-3">
                    {billingStats?.total_usage_used}{" "}
                    {/* <span className="text-sm text-[#808080] font-normal">of -</span> */}
                  </p>
                  <NonContinuousProgressBar
                    progress={
                      (billingStats?.total_usage_used /
                        billingStats?.usage_limit) *
                      100
                    }
                  />
                </>
              )}
            </div>
            <div className="flex w-full flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none h-full">
              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <>
                  <p className="font-medium text-lg">Current Charge</p>
                  <p className="text-sm text-[#808080]">
                    From{" "}
                    {billingStats?.start_date &&
                      dayFormatter(billingStats?.start_date)}{" "}
                    -{" "}
                    {billingStats?.end_date &&
                      dayFormatter(billingStats?.end_date)}
                  </p>
                </>
              )}
              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  height={50}
                  inline
                  className="w-full mt-5"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-semibold text-[38px] mt-8 mb-3">
                  {billingStats?.total_charges?.toLocaleString("en-US", {
                    style: "currency",
                    currency: billingStats?.currency,
                  })}
                </p>
              )}
              {/* <Progress
                    value={50}
                    className="h-1.5 bg-[#ffaf19]/20"
                    indicatorColor="#ffaf19"
                  /> */}
            </div>
          </div>
          <div className="flex flex-col w-full lg:max-w-[430px] gap-5">
            <div className="flex w-full flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full mt-5"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-medium text-lg">Next billing date</p>
              )}

              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  height={50}
                  inline
                  className="w-full mt-5"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-semibold text-[38px] mt-5 mb-3">
                  {billingStats?.end_date && nextDay(billingStats?.end_date)}
                </p>
              )}
            </div>
            <div className="flex w-full flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none h-full">
              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  height={50}
                  inline
                  className="w-full mt-5"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <>
                  <p className="font-medium text-lg">Credit Limit</p>
                  <p className="text-sm text-[#808080]">
                    Maximum available credit
                  </p>
                </>
              )}
              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  height={70}
                  inline
                  className="w-full mt-5"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <div className="w-full relative">
                  <ResponsiveContainer
                    width="100%"
                    height={140}
                    className="w-full xl:scale-110"
                  >
                    <PieChart className="my-0 lg:mt-10 mx-auto">
                      <Pie
                        dataKey="value"
                        startAngle={180}
                        endAngle={0}
                        data={data}
                        cx={"50%"}
                        cy={"75%"}
                        innerRadius={80}
                        outerRadius={90}
                        fill="#7190FF"
                        stroke="none"
                      >
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                  <div className="flex flex-col justify-center items-center absolute top-1/2 translate-y-[30%] left-1/2 -translate-x-1/2">
                    <p className="font-semibold text-[28px]">
                      {billingStats?.usage_limit}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SubUserBilling;
