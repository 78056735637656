import Mainnav from "../../components/mainNavbar/MainNav";
import Footer from "../../components/footer/footer";
import { toast } from "sonner";
import { useState } from "react";
import { Helmet } from "react-helmet";
import WordBreak from "../../components/wordbreak";
import { useNavigate } from "react-router";
import AboutUsImg1 from "/aboutUsImg1.png";
import AboutUsImg2 from "/aboutUsImg2.svg";
import AboutUsIcon1 from "/aboutUsIcon1.svg";
// import { v4 as uuidv4 } from 'uuid';
import { v4 as uuidv4 } from "uuid";
const AboutUs = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const delays = [0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2]; // Array of delays
  const valuesData = [
    {
      img: AboutUsIcon1,
      title: "Vision",
      desc: "Our vision extends beyond the present. We aspire to become the go-to platform for one-to-one outreach, setting the industry standard for effectiveness.",
    },
    {
      img: AboutUsIcon1,
      title: "Team work",
      desc: "Our team is dedicated to streamlining your B2B sales Journey through our AI-driven, hyper-personalized email outreach, ensuring your business flourishes.",
    },
    {
      img: AboutUsIcon1,
      title: "Excellence",
      desc: "MagicPitch is committed to being the best in the market when it comes to outreach strategies. ",
    },
    {
      img: AboutUsIcon1,
      title: "Responsibility",
      desc: "Their goal is to help clients promote products or services effectively, build brand awareness, and achieve business objectives.",
    },
  ];

  // const handleSubmit = async () => {
  //   setIsLoading(true);

  //   try {
  //     if (!emailRegex.test(email)) {
  //       toast.error("Invalid Email");
  //       setIsLoading(false);
  //       return;
  //     }

  //     if (email === "") {
  //       setIsLoading(false);
  //       toast.error("Email is required");
  //       return;
  //     }
  //     const data = {
  //       email: email,
  //     };
  //     const result = await SubscribeApi(data);

  //     if (result.status === 201) {
  //       toast.success("Subscribed Successfully");
  //       setEmail("");
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleNavigate = () => {
    navigate("/contact-us");
    window.scrollTo(0, 0);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);

    if (e.target.value === "") {
      setEmailError("Email is required");
    } else {
      setEmailError(null);
    }
  };
  return (
    <div className="flex flex-col min-h-screen w-full bg-white">
      <Helmet>
        <title>About Us - Magicreview</title>
        <meta
          name="description"
          content="Discover how magicreview simplifies feedback collection with innovative solutions. Our platform enhances engagement and drives growth for businesses of all sizes."
        />
        <link rel="canonical" href="https://www.magicreview.ai/about-us" />
      </Helmet>

      <Mainnav />
      <div className="w-full h-full container flex flex-col py-8 lg:py-14 mb-24">
        <img
          src="/magicnobglogo.svg"
          alt="MagicReview"
          className="w-[150px] lg:w-[204px] mx-auto"
        />
        <h2 className="font-poppins text-[#333333] font-semibold text-3xl md:text-5xl text-center tracking-wide mb-[30px] lg:-mt-[50px] -mt-[20px]">
          About our company
        </h2>
        <p className="font-poppins text-[#333333]  font-normal text-[17px] text-center">
          Welcome to MagicReview, where our AI-powered rapid review tool makes
          collecting reviews quick and easy.
          <WordBreak /> At MagicReview, we're dedicated to simplifying the
          feedback process for businesses and customers alike.
          <WordBreak /> Our goal is to change how reviews are gathered, making
          it efficient and effective.
        </p>
      </div>
      <div className="bg-[#F1F4FF] w-full py-24">
        <div className="flex w-full h-full flex-row flex-wrap justify-between gap-10 items-center container">
          <div className="lg:w-[45%] w-full">
            <img
              loading="lazy"
              src={AboutUsImg1}
              className="w-full"
              alt="mpTeam"
            />
          </div>

          <div className="text-[#333333] font-poppins lg:w-[45%] w-full">
            <h1 className="text-[37px] font-semibold mb-[31px]">Our Journey</h1>
            <p className="text-base">
              MagicReview was started in April 2020, with a bright idea born
              from observing the struggles businesses face in collecting
              valuable reviews. Our team recognized the common challenge of
              customers often neglecting to leave reviews due to the tiring
              process. Determined to make a change, MagicReview was created to
              streamline this process using AI and help businesses with genuine,
              SEO-optimized review collections.
            </p>
            <button
              className="mt-5 text-white bg-newPrimary hover:bg-white border hover:text-newPrimary  border-newPrimary  transition-all duration-300 active:scale-95 py-3 px-6 rounded-[7px]"
              onClick={handleNavigate}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>

      <div className="flex w-full h-full flex-row flex-wrap-reverse justify-between gap-10 items-center py-24 container">
        <div className="text-[#333333] font-poppins lg:w-[45%] w-full">
          <h1 className="text-[37px] font-semibold mb-[31px]">What We Do</h1>
          <p className="text-base">
            MagicReview is an AI-powered rapid review tool that utilizes NFC and
            QR technology to initiate the review process. it efficiently gathers
            customer feedback through MCQs. Our trained AI then transforms these
            responses into SEO-optimized reviews. MagicReview encourages more
            feedback and attracts customers with each review process taking just
            30 seconds. We're simplifying the review collection process for
            businesses worldwide.
          </p>
          <button
            className="mt-5 text-white bg-newPrimary hover:bg-white border hover:text-newPrimary  border-newPrimary  transition-all duration-300 active:scale-95 py-3 px-6 rounded-[7px]"
            onClick={handleNavigate}
          >
            Contact Us
          </button>
        </div>
        <div className="lg:w-[45%] w-full">
          <img
            loading="lazy"
            src={AboutUsImg2}
            className="w-full"
            alt="mpTeam"
          />
        </div>
      </div>
      <div className="bg-[#F1F4FF] w-full py-24">
        <div className="container">
          <div className="text-[#333333] font-poppins w-full flex flex-col items-center text-center lg:w-[59%] mx-auto ">
            <h1 className="text-[37px] font-semibold mb-[31px]">Our Mission</h1>
            <p className="text-base">
              Our goal at MagicReview is simple: to give businesses the tools
              they need to succeed in a tough market. We're changing how
              feedback is collected, making it easy for both businesses and
              customers. With innovation, simplicity, and a focus on real
              feedback, we're helping businesses everywhere thrive. Come join us
              in our mission to change the way feedback works and utilize the
              power of customer reviews with MagicReview.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
