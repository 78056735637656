import Mainnav from "../../components/mainNavbar/MainNav";
import Footer from "../../components/footer/footer";
import { Helmet } from "react-helmet";
import { useRef } from "react";
import { useNavigate } from "react-router";
import RightArrow from "../../assets/icons/RightArrow";
import WordBreak from "../../components/wordbreak";
import { motion } from "framer-motion";
const Howitworks = () => {
  const order = useRef(null);
  const customer = useRef(null);
  const link = useRef(null);
  const respond = useRef(null);
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };
  const analysis = useRef(null);
  const tailored = useRef(null);

  const videoRef = useRef(null);

  const scrollToVideo = () => {
    videoRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      // block: "start",
    });

    // Start playing the video (remove controls attribute if not needed)
    videoRef.current.play();
  };
  const stepsData = [
    {
      title: "Tap",
      img: "/how1.svg",
      description:
        "Enable customers to effortlessly share feedback by tapping/ scanning the QR/NFC Card on their phone.",
    },
    {
      title: "Choose",
      img: "/how2.svg",
      description:
        "Help customers to provide targeted feedback by selecting specific review categories.",
    },
    {
      title: "Answer",
      img: "/how3.svg",
      description:
        "Rapidly collect feedback through straightforward multiple-choice questions (MCQs).",
    },
    {
      title: "Review",
      img: "/how4.svg",
      description:
        "Deliver high-quality, AI-generated reviews based on customer feedback, ensuring consistency and reliability in the review process.",
    },
  ];

  return (
    <div className="">
      <Helmet>
        <title>MagicReview Explained : How Our AI Works</title>
        <meta
          name="description"
          content="Learn  how MagicReview advanced AI technology works to simplify your review  generation process effortlessly and enhance your business growth"
        />
        <link rel="canonical" href="https://www.magicreview.ai/how-it-works" />
      </Helmet>
      <div className="relative bg-white w-full min-h-screen text-left">
        <Mainnav />
        <div className="flex flex-col items-center  p-4 lg:mb-[2.375rem]">
          {/* <p className=" text-base text-primary font-opensans font-bold text-center">
            How it Works
          </p> */}
          <motion.img
            src="/magicnobglogo.svg"
            alt="MagicReview"
            className="w-[150px] lg:w-[204px]"
            initial={{ opacity: 0.5, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.2, ease: "easeIn" }}
          />
          <motion.h1
            className="text-2xl lg:text-[50px]  text-[#333333] text-center font-semibold font-poppins lg:leading-tight lg:-tracking-[1.296px] -mt-[20px] lg:-mt-[50px] mb-2"
            initial={{ opacity: 0.5, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.2, ease: "easeIn" }}
          >
            How it Works?
          </motion.h1>

          <motion.h3
            className="text-[#2D2D2D] font-poppins text-[17px] font-normal text-center mb-2"
            initial={{
              opacity: 0,
              y: 30,
            }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.2, delay: 0.2 }}
          >
            Help your customers craft better reviews and increase the{" "}
            <WordBreak /> visibility of your business on Google search.
          </motion.h3>
        </div>

        <motion.div
          initial={{
            opacity: 0,
            scale: 0,
          }}
          whileInView={{
            opacity: 1,
            scale: 1,
          }}
          viewport={{ once: true }}
          transition={{
            duration: 0.3,
          }}
        >
          <iframe
            id="videoSection"
            ref={videoRef}
            className="rounded-2xl object-cover flex align-middle justify-center mx-auto max-w-[750px] w-[90%] lg:h-96 h-48"
            src="https://www.youtube.com/embed/V-ZplHf441E?si=oytsxSVXYQn0ulYm" // replace VIDEO_ID with your YouTube video's ID
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </motion.div>

        {/* <div
          className="
      flex flex-col items-center justify-center
      w-[90%] mx-auto mt-20 mb-20 
      "
        >
          <motion.h2
            className="text-center text-[#333333] font-opensans font-medium lg:leading-[67.2px] lg:-tracking-[1.296px] text-2xl lg:text-5xl"
            initial={{ opacity: 0.5, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.3, ease: "easeIn" }}
          >
            How It Works
          </motion.h2>
          <p className="text-center font-poppins text-[#2D2D2D]">
            Trusted By 500+ Companies over UAE{" "}
          </p>
        </div> */}
        <div className="bg-[#F1F4FF] py-[118px] mt-[130px] hidden md:block">
          <div className="container hidden md:grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[61px] ">
            {stepsData.map((step, index) => (
              <div key={index} className="flex flex-col font-poppins">
                <div className="flex items-center gap-4 mb-[25px]">
                  <div className="flex items-center w-full bg-[#4F75FA] rounded-[9px] py-2 px-2 pr-4 gap-[10px] w-fit text-white">
                    <img
                      src={step.img}
                      alt={step.title}
                      className="w-[31px] "
                    />
                    Step {index + 1}
                  </div>
                  <div className="border border-dotted  w-full border-[#C3C3C3]"></div>
                </div>

                <h3 className="text-lg lg:text-[28px] font-semibold text-[#333333] mb-[10px]">
                  {step.title}
                </h3>
                <p className="text-sm lg:text-base text-[#2D2D2D]">
                  {step.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full container space-y-5 mb-32">
          <div className="w-full  space-y-5">
            <div className="w-full font-poppins  lg:py-20 space-y-10 py-14 bg-white flex flex-row flex-wrap items-center justify-between">
              <div className="lg:w-[38%] w-full space-y-5 md:space-y-7 lg:pr-[42px]">
                <h2 className="font-semibold text-[24px] md:text-[45px] text-[#333333]">
                  1. Tap
                </h2>
                <p className="tracking-wide text-sm md:text-lg text-[#2D2D2D]">
                  Enable customers to effortlessly share feedback by
                  tapping/scanning the QR/NFC Card on their phone.
                </p>
                <p className="tracking-wide text-sm md:text-lg text-[#2D2D2D]">
                  Save time and boost engagement with the tap-to-submit feature,
                  enhancing customer participation.
                </p>
                <button
                  className="flex items-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white"
                  onClick={() => handleNavigate("/sign-up")}
                >
                  Get Started <RightArrow />
                </button>
              </div>
              <div className="lg:w-[56%] w-full">
                <img loading="lazy" src="/tap.svg" className="w-full" />
              </div>
            </div>

            <div className="font-poppins  space-y-10 lg:py-20 py-14 flex  flex-row flex-wrap-reverse items-center justify-between gap-10  w-full">
              <div className="lg:w-[56%] w-full">
                <div className="bg-[#F5F7FF] w-full relative rounded-[30px] ">
                  <img
                    loading="lazy"
                    src="/choose.svg"
                    className="mx-auto pt-10 w-[80%] lg:w-[50%] relative "
                  />
                  <img
                    src="/arrow.svg"
                    className="absolute top-[121px] -right-10 lg:flex hidden"
                  />
                </div>
              </div>
              <div className="lg:w-[38%] w-full space-y-4 lg:pr-10 text-[#2D2D2D]">
                <h2 className="font-semibold text-[25px] md:text-[45px] leading-tight text-[#333333]">
                  2. Choose
                </h2>
                <p className="tracking-wide text-sm md:text-lg text-[#2D2D2D]">
                  Help customers to provide targeted feedback by selecting
                  specific review categories.
                </p>
                <p className="tracking-wide text-sm md:text-lg text-[#2D2D2D]">
                  Ensure effective communication of customer opinions by
                  offering personalized choices.
                </p>
                <button
                  className="flex items-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white"
                  onClick={() => handleNavigate("/sign-up")}
                >
                  Get Started <RightArrow />
                </button>
              </div>
            </div>

            <div className="w-full font-poppins  lg:py-20 space-y-10 py-14 bg-white flex flex-row flex-wrap items-center justify-between">
              <div className="lg:w-[38%] w-full space-y-5 md:space-y-7 lg:pr-[42px]">
                <h2 className="font-semibold text-[24px] md:text-[45px] text-[#333333]">
                  3. Answer
                </h2>
                <p className="tracking-wide text-sm md:text-lg text-[#2D2D2D]">
                  Rapidly collect feedback through straightforward
                  multiple-choice questions (MCQs).
                </p>
                <p className="tracking-wide text-sm md:text-lg text-[#2D2D2D]">
                  Simplifying the process for customers to express their
                  opinions using MCQs boost engagement.
                </p>
                <button
                  className="flex items-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white"
                  onClick={() => handleNavigate("/sign-up")}
                >
                  Get Started <RightArrow />
                </button>
              </div>
              <div className="lg:w-[56%] w-full">
                <div className="bg-[#4F75FA]  rounded-[30px] w-full text-center">
                  <img
                    loading="lazy"
                    src="/answer.svg"
                    className="mx-auto pt-10 w-full"
                  />
                </div>
              </div>
            </div>

            <div className="font-poppins  space-y-10 lg:py-20 py-14 flex  flex-row flex-wrap-reverse items-center justify-between gap-10">
              <div className="lg:w-[56%] w-full">
                <div className="bg-[#F5F7FF] w-full relative rounded-[30px] ">
                  <img
                    loading="lazy"
                    src="/review.svg"
                    className="mx-auto pt-10 w-[80%] lg:w-[65%] relative "
                  />
                  <img
                    src="/arrow.svg"
                    className="absolute top-[121px] -right-10 lg:flex hidden"
                  />
                </div>
              </div>
              <div className="lg:w-[38%] w-full space-y-4 lg:pr-[50px]">
                <h2 className="font-semibold text-[25px] md:text-[45px] leading-tight text-[#333333]">
                  4. Review
                </h2>
                <p className="tracking-wide text-sm md:text-lg text-[#2D2D2D]">
                  Deliver high-quality, AI-generated reviews based on customer
                  feedback, ensuring consistency and reliability in the review
                  process.
                </p>
                <p className="tracking-wide text-sm md:text-lg text-[#2D2D2D]">
                  Build trust in the review process by providing well-crafted
                  human-like reviews generated by AI, reflecting the
                  authenticity of customer experiences.
                </p>
                <button
                  className="flex items-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white"
                  onClick={() => handleNavigate("/sign-up")}
                >
                  Get Started <RightArrow />
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer
          order={order}
          customer={customer}
          link={link}
          respond={respond}
          analysis={analysis}
          tailored={tailored}
        />
      </div>

      <div
        dangerouslySetInnerHTML={{
          __html: `
          <script type="application/ld+json">{
            "@context": "http://schema.org",
            "@type": "VideoObject",
            "name": "What is Magicreview | AI Powered Review Card",
            "description": "Magicreview is an AI powered review cards that helps you to get instant reviews from your customer thereby increasing the customer reviews, sales and revenue of your business.  About This Video :   MagiicReview, getting detailed feedback from your customers is now easy. No more unclear reviews, just clear insights to boost your business!  🔑 Easy Tap and Scan Process 🔑 Rapid Feedback in Just 30 Seconds 🔑 SEO-Optimized Reviews for Improved Ranking 🔑 Customize Review Templates for Authentic Feedback 🔑 Human-like AI Reviews Capturing Genuine Feedback 🔑 Track Feedback in Real-Time to Stay Updated 🔑 Increase in New Customers & Revenue Growth  Dm us to purchase !!   https://www.magicreview.ai/pricing  https://www.magicreview.ai/  #CustomerFeedback #FeedbackTool #FeedbackTool #GoogleReview #AIReviewCards    Enjoyed this video? Don't forget to hit the like button and subscribe to our channel for more helpful tips and insights! leave a comment below! Thanks for watching!",
            "thumbnailUrl": "https://i.ytimg.com/vi/V-ZplHf441E/default.jpg",
            "uploadDate": "2024-02-29T07:23:04Z",
            "duration": "PT34S",
            "embedUrl": "https://www.youtube.com/embed/V-ZplHf441E",
            "interactionCount": "103"
          }</script><!--Video Markup Code Generated by https://videoschema.com/ -->
          
          `,
        }}
      ></div>
    </div>
  );
};

export default Howitworks;
