import Apimanager from "./axios";

export const signInApi = async (data) => {
  const res = await Apimanager.post("/accounts/login", {
    email: data.email,
    password: data.password,
  });
  return res.data;
};

export const signUpApi = async (data) => {
  const res = await Apimanager.post("/accounts/register", data);
  return res.data;
};

export const googleSignInApi = async (data) => {
  const res = await Apimanager.post("/accounts/google_login", data);
  return res.data;
};

export const verifyEmailApi = async (data) => {
  const res = await Apimanager.post(`/accounts/${data?.id}/verify_email`, {
    code: data?.code,
  });
  return res.data;
};

export const resendVerifyEmailApi = async (data) => {
  const res = await Apimanager.post(`/accounts/${data?.id}/resent_email_code`);
  return res.data;
};

export const registerEntityApi = async (data) => {
  const res = await Apimanager.post(`/accounts/${data?.id}/entity_register`, {
    first_name: data?.first_name,
    last_name: data?.last_name,
    entity: data?.entity,
    referral_code: data?.referral_code,
    phone_number: data?.phone_number,
  });
  return res.data;
};

export const onboardedApi = async () => {
  const res = await Apimanager.post(`/accounts/onboarded`);
  return res.data;
};

export const getUserApi = async () => {
  const res = await Apimanager.get("/accounts/");

  return res.data;
};

export const updateUserApi = async (data) => {
  const res = await Apimanager.put("/accounts/update", data);
  return res.data;
};

export const updateUserProfileApi = async (data) => {
  const res = await Apimanager.put("/accounts/update/pfp", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
};

export const createResetPasswordSession = async (data) => {
  const res = await Apimanager.post("/accounts/send_code", data);
  return res.data;
};

export const verifyCode = async (data) => {
  const res = await Apimanager.post("/accounts/verify_code", data);
  return res.data;
};

export const postResetPassword = async (data) => {
  const res = await Apimanager.post("/accounts/reset_password", data);
  return res.data;
};

export const getReferralsApi = async (params) => {
  const res = await Apimanager.get("/accounts/referrals", {
    params: params,
  });
  return res.data;
};

export const getIndustryTypes = async (params) => {
  const res = await Apimanager.get("/misc/industries", {
    params: params,
  });
  return res.data;
};

export const getIntegrations = async () => {
  const res = await Apimanager.get("/entity/integration");
};

export const getSettings = async () => {
  const res = await Apimanager.get("/entity/integrations/google");
  return res.data;
};

export const putUpdateSettings = async (data) => {
  const res = await Apimanager.put("/entity/integrations/google", data);
  return res.data;
};

export const postEnableSupport = async () => {
  const res = await Apimanager.post("/entity/integrations/google/enable");
  return res.data;
};

export const postToggle = async () => {
  const res = await Apimanager.post("/entity/integrations/google/toggle");
  return res.data;
};

export const getYelpSettings = async () => {
  const res = await Apimanager.get("/entity/integrations/yelp");
  return res.data;
};

export const putUpdateYelpSettings = async (data) => {
  const res = await Apimanager.put("/entity/integrations/yelp", data);
  return res.data;
};

export const postEnableYelpSupport = async () => {
  const res = await Apimanager.post("/entity/integrations/yelp/enable");
  return res.data;
};

export const postYelpToggle = async () => {
  const res = await Apimanager.post("/entity/integrations/yelp/toggle");
  return res.data;
};

export const getTrustPilotSettings = async () => {
  const res = await Apimanager.get("/entity/integrations/trustpilot");
  return res.data;
};

export const putUpdateTrustPilotSettings = async (data) => {
  const res = await Apimanager.put("/entity/integrations/trustpilot", data);
  return res.data;
};

export const postEnableTrustPilotSupport = async () => {
  const res = await Apimanager.post("/entity/integrations/trustpilot/enable");
  return res.data;
};

export const postTrustPilotToggle = async () => {
  const res = await Apimanager.post("/entity/integrations/trustpilot/toggle");
  return res.data;
};

export const getBookingSettings = async () => {
  const res = await Apimanager.get("/entity/integrations/booking");
  return res.data;
};

export const putUpdateBookingSettings = async (data) => {
  const res = await Apimanager.put("/entity/integrations/booking", data);
  return res.data;
};

export const postEnableBookingSupport = async () => {
  const res = await Apimanager.post("/entity/integrations/booking/enable");
  return res.data;
};

export const postBookingToggle = async () => {
  const res = await Apimanager.post("/entity/integrations/booking/toggle");
  return res.data;
};

export const getAirbnbSettings = async () => {
  const res = await Apimanager.get("/entity/integrations/airbnb");
  return res.data;
};

export const putUpdateAirbnbSettings = async (data) => {
  const res = await Apimanager.put("/entity/integrations/airbnb", data);
  return res.data;
};

export const postEnableAirbnbSupport = async () => {
  const res = await Apimanager.post("/entity/integrations/airbnb/enable");
  return res.data;
};

export const postAirbnbToggle = async () => {
  const res = await Apimanager.post("/entity/integrations/airbnb/toggle");
  return res.data;
};

export const getTripSettings = async () => {
  const res = await Apimanager.get("/entity/integrations/tripadvisor");
  return res.data;
};

export const putUpdateTripSettings = async (data) => {
  const res = await Apimanager.put("/entity/integrations/tripadvisor", data);
  return res.data;
};

export const postEnableTripSupport = async () => {
  const res = await Apimanager.post("/entity/integrations/tripadvisor/enable");
  return res.data;
};

export const postTripToggle = async () => {
  const res = await Apimanager.post("/entity/integrations/tripadvisor/toggle");
  return res.data;
};

export const getNotifications = async () => {
  const res = await Apimanager.get("/accounts/notifications");
  return res.data;
};

export const postUpdateReferralCode = async (data) => {
  const res = await Apimanager.post("/accounts/referrals/update_code", data);
  return res.data;
};

export const postToggleStaffManagement = async () => {
  const res = await Apimanager.post(
    "/accounts/settings/toggle_staff_management"
  );
  return res.data;
};

export const getFreeTrial = async () => {
  const res = await Apimanager.get("/accounts/get_set_free_trial");
  return res.data;
};

export const postFreeTrial = async () => {
  const res = await Apimanager.post("/accounts/get_set_free_trial");
  return res.data;
};

export const getMasterAccountAccess = async () => {
  const res = await Apimanager.get("/accounts/get_master_account_access");
  return res.data;
};

export const getIsSubUser = async () => {
  const res = await Apimanager.get("/accounts/is_user_sub_account");
  return res.data;
};

export const getSubUserBilling = async () => {
  const res = await Apimanager.get("/accounts/sub_account_billing");
  return res.data;
};
