import Apimanager from "./axios";

export const getSubUsers = async (params) => {
  const res = await Apimanager.get(`/master/accounts/get_subusers`, {
    params: params,
  });
  return res.data;
};

export const getDomain = async () => {
  const res = await Apimanager.get(`/master/accounts/get_domain`);
  return res.data;
};

export const postCreateSubUserInternal = async (data) => {
  const res = await Apimanager.post(
    `/master/accounts/create_subuser_internal`,
    data
  );
  return res.data;
};

export const getPerformance = async (order) => {
  const res = await Apimanager.get(`/master/accounts/get_performance`, {
    params: {
      order: order,
    },
  });
  return res.data;
};

export const postAddSubuserInvite = async (data) => {
  const res = await Apimanager.post(
    `/master/accounts/add_subuser_invite`,
    data
  );
  return res.data;
};

export const getOrganizationLoginCheckvalid = async (id) => {
  const res = await Apimanager.get(
    `/master/accounts/${id}/organization_login_checkvalid`
  );

  return res.data;
};

export const postOrganizationLogin = async (id, data) => {
  const res = await Apimanager.post(
    `/master/accounts/${id}/organization_login`,
    data
  );

  return res.data;
};

export const postOrganizationLoginGoogle = async (id, data) => {
  const res = await Apimanager.post(
    `/master/accounts/${id}/organization_login_google`,
    data
  );

  return res.data;
};

export const getMasterStats = async () => {
  const res = await Apimanager.get(`/master/accounts/get_stats`);
  return res.data;
};

export const postLoginAsSubuser = async (data) => {
  const res = await Apimanager.post(`/master/accounts/login_as_subuser`, data);
  return res.data;
};

export const getMasterStatsGraph = async (sort) => {
  const res = await Apimanager.get(
    `/master/accounts/ai_review_posted_aggregation`,
    {
      params: {
        sort: sort,
      },
    }
  );

  return res.data;
};

export const postSubUsersStats = async (data) => {
  const res = await Apimanager.post(`/master/accounts/subuser_stats`, data);
  return res.data;
};

export const putSubuserAccountUpdate = async ({ id, data }) => {
  const res = await Apimanager.put(
    `/master/accounts/${id}/subuser_account_update`,
    data
  );
  return res.data;
};

export const postUpdateCreditLimit = async ({ id, data }) => {
  const res = await Apimanager.put(
    `/master/accounts/${id}/subuser_edit_limit`,
    data
  );
  return res.data;
};

export const getBillingStats = async () => {
  const res = await Apimanager.get(`/master/accounts/billing_stats`);
  return res.data;
};

export const putUpdateSubUserProfile = async ({ id, data }) => {
  const res = await Apimanager.put(
    `/master/accounts/${id}/subuser_profile_change`,
    data,
    {
      headers: {
        "Content-Type": "multipart/formdata",
      },
    }
  );

  return res.data;
};

export const getSubUserTheme = async (id) => {
  const res = await Apimanager.get(
    `/master/accounts/${id}/subuser_theme_change`
  );

  return res.data;
};

export const putUpdateSubUserTheme = async ({ id, data }) => {
  const res = await Apimanager.put(
    `/master/accounts/${id}/subuser_theme_change`,
    data
  );

  return res.data;
};

export const deleteSubUserTheme = async (id) => {
  const res = await Apimanager.delete(
    `/master/accounts/${id}/subuser_theme_change`
  );

  return res.data;
};
