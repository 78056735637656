import React, { useState } from "react";
import Layout from "../component/Layout";
import { EllipsisVertical } from "lucide-react";
import { Table, TableHeadCell } from "flowbite-react";
const KnowledgeBase = () => {
  const [files, setFiles] = useState([]);
  const tableHead = [
    { title: "File name" },
    { title: "Date uploaded" },
    { title: "Last updated" },
    { title: "Owner" },
  ];

  const data = [
    {
      name: "file1",
      date: "2021-10-10",
      updated: "2021-10-10",
      owner: "Admin",
    },
    {
      name: "file2",
      date: "2021-10-10",
      updated: "2021-10-10",
      owner: "Admin",
    },
    {
      name: "file3",
      date: "2021-10-10",
      updated: "2021-10-10",
      owner: "Admin",
    },
    {
      name: "file4",
      date: "2021-10-10",
      updated: "2021-10-10",
      owner: "Admin",
    },
  ];

  const handleFileUpload = (e) => {
    if (e.target.files) {
      setFiles([...files, ...Array.from(e.target.files)]);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setFiles([...files, ...Array.from(e.dataTransfer.files)]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <Layout title={"Knowledge Base"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-320px)] px-5 lg:px-[30.2px]">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="rounded-lg p-10 text-center hover:bg-gray-100 dark:hover:bg-darkBg cursor-pointer mt-10 flex justify-center items-center flex-col"
        >
          <img src="/pngegg.png" className="w-40 h-40 my-5" />
          <h1 className="text-2xl font-medium mb-5">Upload your Files</h1>
          <p className="text-gray-500">
            Drag and drop files here or{" "}
            <label
              htmlFor="fileInput"
              className="text-blue-500 cursor-pointer underline"
            >
              click to upload
            </label>
          </p>
          <input
            type="file"
            multiple
            onChange={handleFileUpload}
            className="hidden"
            id="fileInput"
          />
        </div>

        <h1 className="font-medium text-xl mt-5">All Documents</h1>
      <p className="text-gray-500">
        Overview of every files or documnets that you have stored.
      </p>

      <Table
        className="mt-5 w-full"
        theme={{
          root: {
            base: "w-full text-left text-sm lg:text-base ",
            shadow:
              "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white",
          },
          head: {
            base: "group/head font-pro dark:text-darkGray",
            cell: {
              base: " bg-slate-200 dark:bg-darkBg px-6 py-3 group-first/head:first:rounded-tl-xl group-first/head:last:rounded-tr-xl",
            },
          },
          body: {
            base: "group/body",
            cell: {
              base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-xl group-first/body:group-first/row:last:rounded-tr-xl group-last/body:group-last/row:first:rounded-bl-xl group-last/body:group-last/row:last:rounded-br-xl",
            },
          },
        }}
      >
        <Table.Head className="border-b ">
          {tableHead.map((head, index) => (
            <Table.HeadCell key={index}>{head.title}</Table.HeadCell>
          ))}
          <TableHeadCell></TableHeadCell>
        </Table.Head>
        <Table.Body>
          {data.map((file, index) => (
            <Table.Row key={index} className="bg-white dark:bg-darkBg border-b border-t">
              <Table.Cell>
                <div className="flex items-center">
                  <div className="w-5 h-5 bg-gray-200 rounded-md mr-3"></div>
                  <p className="font-medium">{file.name}</p>
                </div>
              </Table.Cell>
              <Table.Cell>{file.date}</Table.Cell>
              <Table.Cell>{file.updated}</Table.Cell>
              <Table.Cell>
                <div className="flex items-center">
                 <img src="/blogpro4.svg" className="w-10 h-10 rounded-full mr-3" />
                  <p className="font-medium">{file.owner}</p>
                </div>
              </Table.Cell>
              <Table.Cell>
                <EllipsisVertical size={20} />
                </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

        {/* <div className="mt-5">
          <h3 className="text-lg font-semibold">Uploaded Files:</h3>
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div> */}
      </div>
   
    </Layout>
  );
};

export default KnowledgeBase;
