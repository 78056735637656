/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Button, Modal, Tooltip } from "flowbite-react";
import { toast } from "sonner";
import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  useDeleteEntityQuestion,
  useDeleteEntityQuestionsV2,
  useDeleteQuestionCategories,
  useDeleteQuestionKeyword,
  useGetQuestionCategories,
  useGetQuestionKeywords,
  useGetQuestionSets,
  usePostAddEntityQuestion,
  usePostAddQuestionCategories,
  usePostAddQuestionKeyword,
  usePostAddQuestionSet,
  usePostUpdateEntityQuestionsV2,
  usePutEditQuestionCategories,
  usePutEditQuestionKeyword,
  usePutUpdateEntityQuestion,
} from "../../../query/useEntity";
import {
  LoadingButton,
  LogoLoading,
} from "../../../components/loading/loading";
import { CirclePlus, CircleX, Pencil, X } from "lucide-react";
import { Switch } from "@headlessui/react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
export default function QuestionsModal({
  openModal,
  setOpenModal,
  questions,
  setQuestions,
}) {
  const Questions = ["Excellent", "Good", "Average", "Bad"];
  const [editableIndex, setEditableIndex] = useState(null);
  const { mutate, isLoading } = usePostUpdateEntityQuestionsV2();
  const queryClient = useQueryClient();
  const [question, setQuestion] = useState("");
  const handlePush = () => {
    setQuestions((prev) => [...prev, question]);
    mutate(
      {
        questions: [...questions, question],
      },
      {
        onSuccess: (res) => {
          toast.success("Question added successfully");
          queryClient.invalidateQueries(["getDefaultQuestions"]);
        },
        onError: (err) => {
          toast.error("Failed to add question");
        },
      }
    );
  };

  const handleAnswerFocus = (index) => {
    setEditableIndex(index);
  };

  return (
    <>
      <Modal
        dismissible
        show={openModal}
        onClose={() => {
          setEditableIndex(null);
          setOpenModal(false);
        }}
        size={"4xl"}
        className="z-[1000]"
        theme={{
          content: {
            base: "relative p-4 h-auto",
          },
        }}
      >
        <Modal.Body className="pt-[35px] px-[24px] sm:px-[35px] flex flex-col">
          <header className="flex items-center">
            <img
              src="/orangearc2.png"
              alt=""
              className="w-12 h-12 object-contain"
            />
            <h1 className="text-[#333] text-[24px] font-bold font-pro ml-[15px]">
              Add your Questions
            </h1>
          </header>

          <textarea
            type="text"
            placeholder="Enter your question"
            className="w-full border border-[#C2C2C2] rounded-[9px] p-3 mt-3  font-montserrat resize-none"
            rows={3}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <div className="py-2 flex gap-[19px] flex-wrap">
            {Questions.map((answer, index) => (
              <div className="relative" key={index}>
                <span
                  className={`w-5 h-5 border rounded-full mr-2 bg-white border-[#BBBBBB] absolute top-5 left-3`}
                ></span>
                <input
                  key={answer}
                  value={answer}
                  onFocus={() => handleAnswerFocus(index)}
                  onBlur={() => setEditableIndex(null)}
                  onChange={(e) => {
                    // Handle changes to the answer if needed
                    // You can use this value or update your state accordingly
                  }}
                  className={`flex items-center px-10 py-4 border-2 rounded-lg sm:max-w-[150px] w-full text-[#4A4A4A]  font-pro text-base
                ${
                  editableIndex === index
                    ? "border-[#4F46BA]"
                    : "border-[#BBBBBB]"
                }
               `}
                />
              </div>
            ))}
          </div>

          <div className="flex flex-wrap gap-2 sm:justify-end">
            <button
              className="flex items-center rounded-[7px] border border-[#AAA] text-white text-base text-center font-medium py-[10px]  px-[40px] bg-[#4F46BA] gap-[6px] mt-[20px]"
              onClick={() => {
                handlePush();
              }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Save"}
            </button>

            <button
              className="flex items-center rounded-[7px] border border-[#4F46BA] text-[#4F46BA] text-base text-center font-medium py-[10px]  px-[40px] bg-white gap-[6px] mt-[20px]"
              onClick={() => {
                setEditableIndex(null);
                setOpenModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export const DeleteQuestionModal = ({
  openModal,
  setOpenModal,
  questionIndex,
}) => {
  const { mutate, isLoading } = useDeleteEntityQuestion();
  const queryClient = useQueryClient();

  const handleDelete = () => {
    mutate(questionIndex, {
      onSuccess: (res) => {
        toast.success("Question deleted successfully");
        queryClient.invalidateQueries({ queryKey: ["getDefaultQuestions"] });
        queryClient.invalidateQueries({
          queryKey: ["getDefaultQuestionsForSets"],
        });
        setOpenModal(false);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
        setOpenModal(false);
      },
    });
  };

  return (
    <>
      <Modal
        popup
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="z-[1000]"
        theme={{
          content: {
            base: "relative p-4 h-auto",
            inner: "bg-white dark:bg-darkBg rounded-lg",
          },
          header: {
            close: {
              base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
            },
          },
        }}
      >
        <Modal.Header>
          <header className="flex items-center">
            <h1 className=" text-[20px] font-montserrat ml-[15px] pt-5">
              Are you sure you want to delete the question?
            </h1>
          </header>
        </Modal.Header>
        <Modal.Body className="px-[24px] sm:px-[35px] flex flex-col">
          <div className="flex gap-2 lg:justify-end justify-center">
            <button
              className="flex items-center rounded-[7px] border border-[#FF0000]  text-white text-base text-center font-medium py-[10px]  px-[40px] bg-[#FF0000] gap-[6px] mt-[20px] hover:bg-white hover:text-[#FF0000]"
              onClick={() => {
                handleDelete();
              }}
              disabled={isLoading}
            >
              {isLoading ? <LoadingButton /> : "Delete"}
            </button>

            <button
              className="flex items-center rounded-[7px] border border-secondary text-secondary text-base text-center font-medium py-[10px]  px-[40px] bg-white gap-[6px] mt-[20px]"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const DeleteModal = ({
  openModal,
  setOpenModal,
  handleDelete,
  title,
  deleteConfirmButtonText,
  deleteCancelButtonText,
  isLoading,
}) => {
  // if (isLoading) {
  //   return <LogoLoading />;
  // }
  return (
    <>
      <Modal
        popup
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="z-[1000]"
        theme={{
          content: {
            base: "relative p-4 h-auto",
            inner: "dark:bg-darkBg bg-white rounded-lg",
          },
          base: "fixed inset-x-0 top-0 z-50 h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
          show: {
            on: "flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80",
            off: "hidden",
          },
          header: {
            close: {
              base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
            },
          },
        }}
        size={"lg"}
      >
        <Modal.Header>
          <header className="flex items-center pt-[20px] pl-[24px] sm:pl-[35px]">
            <h1 className=" text-[20px]  font-montserrat">
              {title ? title : "Are you sure you want to delete the card?"}
            </h1>
          </header>
        </Modal.Header>
        <Modal.Body className=" px-[24px] sm:px-[35px] flex flex-col">
          <div className="flex flex-wrap gap-2 justify-center lg:justify-end">
            <button
              className="flex justify-center items-center rounded-[7px]  text-white text-base text-center font-medium py-[10px] border border-[#ff0000]  px-[40px] bg-[#FF0000] gap-[6px] mt-[20px] w-[120px] hover:bg-white hover:text-[#ff0000] dark:hover:bg-darkLightBg transition-all duration-200"
              onClick={handleDelete}
              disabled={isLoading}
            >
              {isLoading ? (
                <LoadingButton />
              ) : deleteConfirmButtonText ? (
                deleteConfirmButtonText
              ) : (
                "Delete"
              )}
              {/* {deleteConfirmButtonText ? deleteConfirmButtonText : "Delete"} */}
            </button>

            <button
              className="flex items-center rounded-[7px] border border-newPrimary text-newPrimary text-base text-center font-medium py-[10px]  px-[40px] bg-white dark:bg-darkLightBg gap-[6px] mt-[20px]"
              onClick={() => setOpenModal(false)}
            >
              {deleteCancelButtonText ? deleteCancelButtonText : "Cancel"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
