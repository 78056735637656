import { useRef, useState } from "react";
import Layout from "../component/Layout";
import { useUpdateEntityInfo } from "../../../query/useEntity";
import { useUser } from "../../../query/useAuthentication";
import { toast } from "sonner";
import TagsInput from "react-tagsinput";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import {
  useDeleteMustSeoKeyword,
  useDeleteSeoKeyword,
  useGetMustSeoKeywords,
  useGetSeoKeywords,
  usePostAddMustSeoKeyword,
  usePostAddSeoKeyword,
} from "../../../query/useKeywords";
import { X } from "lucide-react";
import { DeleteModal } from "../component/modal";
import { LogoLoading } from "../../../components/loading/loading";

const Keywords = () => {
  const [seoKeyword, setSeoKeyword] = useState("");
  const [isOpenDeleteSeoModal, setIsOpenDeleteSeoModal] = useState(false);
  const [toDeleteSeo, setToDeleteSeo] = useState("");
  const [isShowMoreSeo, setIsShowMoreSeo] = useState(false);

  const [mustSeoKeyword, setMustSeoKeyword] = useState("");
  const [isOpenDeleteMustSeoModal, setIsOpenDeleteMustSeoModal] =
    useState(false);
  const [toDeleteMustSeo, setToDeleteMustSeo] = useState("");
  const [isShowMoreMuchSeo, setIsShowMoreMuchSeo] = useState(false);

  const { data: seoKeywords, refetch: refetchSeo } = useGetSeoKeywords();
  const { mutate: addSeo, isLoading: addSeoLoading } = usePostAddSeoKeyword();
  const { mutate: deleteSeo, isLoading: deleteSeoLoading } =
    useDeleteSeoKeyword();

  const { data: mustSeoKeywords, refetch: refetchMustSeo } =
    useGetMustSeoKeywords();
  const { mutate: addMustSeo, isLoading: addMustSeoLoading } =
    usePostAddMustSeoKeyword();
  const { mutate: deleteMustSeo, isLoading: deleteMustSeoLoading } =
    useDeleteMustSeoKeyword();

  const handleAddSeoKeyword = () => {
    addSeo(
      {
        seo_keyword: seoKeyword,
      },
      {
        onSuccess: (res) => {
          if (res.message) {
            toast.success(res.message);
          } else {
            toast.success("SEO keyword has been added");
          }
          refetchSeo();
          setSeoKeyword("");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteSeoKeyword = () => {
    deleteSeo(
      {
        seo_keyword: toDeleteSeo,
      },
      {
        onSuccess: (res) => {
          if (res.message) {
            toast.success(res.message);
          } else {
            toast.success("SEO keyword has been deleted");
          }
          refetchSeo();
          setToDeleteSeo("");
          setIsOpenDeleteSeoModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleAddMustSeoKeyword = () => {
    addMustSeo(
      {
        must_seo_keyword: mustSeoKeyword,
      },
      {
        onSuccess: (res) => {
          if (res.message) {
            toast.success(res.message);
          } else {
            toast.success("Must Have SEO keyword has been added");
          }
          refetchMustSeo();
          setMustSeoKeyword("");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteMustSeoKeyword = () => {
    deleteMustSeo(
      {
        must_seo_keyword: toDeleteMustSeo,
      },
      {
        onSuccess: (res) => {
          if (res.message) {
            toast.success(res.message);
          } else {
            toast.success("Must Have SEO keyword has been deleted");
          }
          refetchMustSeo();
          setToDeleteMustSeo("");
          setIsOpenDeleteMustSeoModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (addSeoLoading || addMustSeoLoading) {
    return <LogoLoading />;
  }

  return (
    <Layout title="SEO Keywords">
      <div className="my-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 lg:py-8 min-h-[calc(100vh-140px)] px-5 lg:px-[30.2px]">
        <div className="flex justify-between items-center flex-wrap gap-[20px] mb-5">
          <p className="font-pro font-semibold text-base">Seo Keywords</p>
        </div>
        {/* <hr className="my-6" /> */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-x-10">
          <div className="relative w-full">
            <input
              type="text"
              className="w-full h-12 lg:h-full border font-pro border-[#e7e4e4] dark:border-[#454545] bg-white rounded-lg px-3 py-1 dark:bg-darkLightBg"
              value={seoKeyword}
              onChange={(e) => setSeoKeyword(e.target.value)}
            />
          </div>
          <button
            className="bg-newPrimary border border-newPrimary rounded-[7px] text-sm font-medium text-white hover:bg-white dark:hover:bg-darkLightBg hover:text-newPrimary transition-all duration-200 w-[200px] h-[50px] px-3 font-montserrat"
            onClick={handleAddSeoKeyword}
            disabled={addSeoLoading}
          >
            {/* {addSeoLoading ? (
              <div className="flex items-center justify-center">
                <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
              </div>
            ) : (
              "Add Keyword"
            )} */}
            Add Keyword
          </button>
          <div className="flex items-center gap-3 flex-wrap">
            {seoKeywords?.seo_keywords?.length > 8
              ? seoKeywords?.seo_keywords
                  ?.slice(
                    0,
                    isShowMoreSeo ? seoKeywords?.seo_keywords?.length : 8
                  )
                  ?.map((item) => (
                    <div
                      className=" border border-[rgba(107,141,208,0.41)] rounded-[5px] py-2 px-2 w-fit text-secondary flex items-center gap-x-2 text-[13px] font-normal"
                      key={item}
                    >
                      <p>{item}</p>
                      <X
                        className="size-4 cursor-pointer"
                        onClick={() => {
                          setIsOpenDeleteSeoModal(true);
                          setToDeleteSeo(item);
                        }}
                      />
                    </div>
                  ))
              : seoKeywords?.seo_keywords?.map((item) => (
                  <div
                    className=" border border-[rgba(107,141,208,0.41)] rounded-[5px] py-2 px-2 w-fit text-secondary flex items-center gap-x-2 text-[13px] font-normal"
                    key={item}
                  >
                    <p>{item}</p>
                    <X
                      className="size-4 cursor-pointer"
                      onClick={() => {
                        setIsOpenDeleteSeoModal(true);
                        setToDeleteSeo(item);
                      }}
                    />
                  </div>
                ))}
            {seoKeywords?.seo_keywords?.length > 8 ? (
              !isShowMoreSeo ? (
                <p
                  className="text-secondary hover:underline cursor-pointer"
                  onClick={() => setIsShowMoreSeo(true)}
                >
                  See more
                </p>
              ) : (
                <p
                  className="text-secondary hover:underline cursor-pointer"
                  onClick={() => setIsShowMoreSeo(false)}
                >
                  See less
                </p>
              )
            ) : null}
          </div>
        </div>

        <hr className="my-6" />

        {/* Must Have Keywords */}
              {/* loading icon */}
         {/* {addMustSeoLoading ? (
              <div className="flex items-center justify-center">
                <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
              </div>
            ) : (
              "Add Keyword"
            )} */}
        {/* <div className="flex justify-between items-center flex-wrap gap-[20px] mb-5">
          <p className="font-pro font-medium text-xl">Must Have Seo Keywords</p>
        </div> */}
          
        {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-x-10">
          <div className="relative w-full">
            <input
              type="text"
              className="w-full h-12 lg:h-full border font-pro border-[#e7e4e4] dark:border-[#454545] bg-white rounded-lg px-3 py-1 dark:bg-darkLightBg"
              value={mustSeoKeyword}
              onChange={(e) => setMustSeoKeyword(e.target.value)}
            />
          </div>
          <button
            className="bg-newPrimary border border-newPrimary rounded-[7px] text-white hover:bg-white dark:hover:bg-darkLightBg hover:text-newPrimary transition-all duration-200 w-[200px] h-[50px] px-3 font-montserrat"
            onClick={handleAddMustSeoKeyword}
            disabled={addMustSeoLoading}
          >
           
            Add Keyword
          </button>
          <div className="flex gap-3 flex-wrap items-center">
            {mustSeoKeywords?.must_have_seo_keywords?.length > 8
              ? mustSeoKeywords?.must_have_seo_keywords
                  ?.slice(
                    0,
                    isShowMoreMuchSeo
                      ? mustSeoKeywords?.must_have_seo_keywords?.length
                      : 8
                  )
                  ?.map((item) => (
                    <div
                      className="border border-[rgba(107,141,208,0.41)] rounded-[5px] py-2 px-2 w-fit text-secondary flex items-center gap-x-2"
                      key={item}
                    >
                      <p>{item}</p>
                      <X
                        className="size-4 cursor-pointer"
                        onClick={() => {
                          setIsOpenDeleteMustSeoModal(true);
                          setToDeleteMustSeo(item);
                        }}
                      />
                    </div>
                  ))
              : mustSeoKeywords?.must_have_seo_keywords?.map((item) => (
                  <div
                    className="border border-[rgba(107,141,208,0.41)] rounded-[5px] py-2 px-2 w-fit text-secondary flex items-center gap-x-2"
                    key={item}
                  >
                    <p>{item}</p>
                    <X
                      className="size-4 cursor-pointer"
                      onClick={() => {
                        setIsOpenDeleteMustSeoModal(true);
                        setToDeleteMustSeo(item);
                      }}
                    />
                  </div>
                ))}
            {mustSeoKeywords?.must_have_seo_keywords?.length > 8 ? (
              !isShowMoreMuchSeo ? (
                <p
                  className="text-secondary hover:underline cursor-pointer"
                  onClick={() => setIsShowMoreMuchSeo(true)}
                >
                  See more
                </p>
              ) : (
                <p
                  className="text-secondary hover:underline cursor-pointer"
                  onClick={() => setIsShowMoreMuchSeo(false)}
                >
                  See less
                </p>
              )
            ) : null}
          </div>
        </div> */}
      </div>
      <DeleteModal
        openModal={isOpenDeleteSeoModal}
        setOpenModal={setIsOpenDeleteSeoModal}
        title={`Are you sure you want to delete the SEO keyword "${toDeleteSeo}"?`}
        isLoading={deleteSeoLoading}
        handleDelete={handleDeleteSeoKeyword}
      />
      <DeleteModal
        openModal={isOpenDeleteMustSeoModal}
        setOpenModal={setIsOpenDeleteMustSeoModal}
        title={`Are you sure you want to delete the Must Have SEO keyword "${toDeleteMustSeo}"?`}
        isLoading={deleteMustSeoLoading}
        handleDelete={handleDeleteMustSeoKeyword}
      />
    </Layout>
  );
};

export default Keywords;
