import MainNav from "../components/mainNavbar/MainNav";
import Footer from "../components/footer/footer";
import { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import WordBreak from "../components/wordbreak";
import { Helmet } from "react-helmet";
import HomepageGirl from "/homepage-girl.png";
import { useNavigate } from "react-router";
import IconPlay from "../assets/icons/IconPlay";
import { useGetPlans } from "../query/useSubscription";
import RightArrow from "../assets/icons/RightArrow";
import IconImg1 from "/iconImg1.png";
import IconImg2 from "/iconImg2.png";
import walletSvg from "/walletSvg.svg";
import mouseSvg from "/mouseSvg.svg";
import rankSvg from "/rankSvg.svg";
import magnetSvg from "/magnetSvg.svg";
import writingSvg from "/writingSvg.svg";
import upwardSvg from "/upwardSvg.svg";
import features1 from "/features1.png";
import features2 from "/features2.png";
import features3 from "/features3.png";
import section1 from "/section1.png";
import section2 from "/section2.png";
import section3 from "/section3.png";
import section4 from "/section4.png";
import { v4 as uuidv4 } from "uuid";
import HomeSlider from "../components/slider/homeslider";
import IconRightArrow from "../assets/icons/IconRightArrow";
import { Link } from "react-router-dom";
import ClientSlider from "../components/slider/clientslider";
import CookieConsent from "../components/ui/CookieConsent";

const Home = () => {
  const home = useRef(null);
  const overview = useRef(null);
  const navigate = useNavigate();
  const keyFeatures = useRef(null);
  const containerRef = useRef(null);
  const whyChooseUs = useRef(null);
  const { data: plans } = useGetPlans();
  const faqs = useRef(null);

  const UpdatedPlans = Array.isArray(plans?.data)
    ? plans.data.sort((a, b) => a.price - b.price)
    : [];
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItem((prevItem) => (prevItem + 1) % scrollData.length);
    }, 3000); // Change item every 2 seconds

    return () => clearInterval(interval); // Clean up on component unmount
  }, []);
  const metaKeywords =
    "Google Review NFC Card, Reviews tool, Review Cards, NFC Review Card, Google Reviews With AI, AI Generated Google Reviews, AI Generated Reviews, AI Google Reviews, AI Review Application, AI Reviews, Get More Google Review, Automated Google Reviews, Automated online Reviews, AI Google review tool, Generate AI Google Reviews, Google Reviews, Online Review";
  const pricingData = [
    "Instant tap to get AI generated reviews",
    "Real-Time Tracking of Customer Reviews",
    "Hassle-Free Review Collection under 30 seconds",
    "Faster Reviews Without Manual Typing",
  ];

  const reviewTapData = [
    {
      icon: walletSvg,
      desc: (
        <div>
          Switch from emails and texts to <WordBreak /> MagicReview's AI review
          cards, featuring <WordBreak /> NFC and QR technology.
        </div>
      ),
    },
    {
      icon: mouseSvg,
      desc: (
        <div>
          Customers can easily leave reviews <WordBreak /> with a tap or scan on
          their phones, <WordBreak /> enabling you to gather reviews under 30{" "}
          <WordBreak /> seconds and lower marketing costs with <WordBreak />{" "}
          word-of-mouth promotion.
        </div>
      ),
    },
  ];
  const reviewData2 = [
    {
      value: "73%",
      desc: "people trust online reviews as much as personal suggestions.",
    },
    {
      value: "92%",
      desc: "of customers read online reviews before a purchase.",
    },
    {
      value: "17%",
      desc: "increase in conversion rate is noticed with positive reviews.",
    },
    {
      value: "46%",
      desc: "increase in revenue is seen with an average rating of 4.7",
    },
  ];
  const featuresData = [
    {
      title: "Google Review Cards",
      img: features3,
      desc: "NFC & QR technology used in the MagicReview Cards allows customers to easily leave reviews by tapping or scanning the AI Review Cards on their phones. The easy process enhances customer experience and boosts participation",
    },
    {
      title: "Human-Like AI Reviews",
      img: features2,
      desc: "MagicReview uses AI technology to create well-structured and engaging reviews based on customer inputs, eliminating the need for manual review writing. Customers can easily share their feedback using MagicReview’s AI Review Cards.",
    },
    {
      title: "Real-Time Tracking",
      img: features1,
      desc: "MagicReview provides you with a personalized dashboard, allowing you to track reviews generated using Google review cards effortlessly. Easily customize review forms and monitor progress with insightful analytics and data.",
    },
  ];
  const scrollData = [
    "Easy Tap and Scan Process",
    "Rapid Feedback in Just 30 Seconds",
    "Rank 1st on Google Search",
    "Human-like AI Reviews",
    "Real-Time Review Tracking",
  ];
  const handleNavigate = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  const cardPrice = () => {
    switch (UpdatedPlans[selectedPlan]?.currency) {
      case "AED":
        return <span>30 AED</span>;
      case "USD":
        return <span>10 &#36;</span>;
      case "GBP":
        return <span>7 &#163;</span>;
      case "INR":
        return <span>500 &#8377;</span>;
    }
  };

  return (
    <>
    <CookieConsent />
      <Helmet>
        <title>
          MagicReview | AI powered Google review Card for your business
        </title>
        <meta
          name="title"
          content=" MagicReview | AI powered Google review Card for your business"
        />
        <meta name="keywords" content={metaKeywords} />
        <meta
          name="description"
          content="Boost sales and your business reputation with MagicReview's AI review cards. Increase Google Reviews effortlessly. Start your free plan now."
        />
        <link rel="canonical" href="https://www.magicreview.ai/" />
      </Helmet>

      <div className="w-full bg-white">
        {/* first section*/}
        <ul className="bg-newPrimary w-full px-2 h-[50px] rounded-sm hidden md:flex justify-center text-white items-center overflow-hidden list-disc font-poppins gap-[43px]">
          <li
            key={currentItem}
            className="font-poppins text-base animate-fadeIn"
          >
            {scrollData[currentItem]}
          </li>
          <div className="flex items-center gap-2">
            <img src="stargroup.svg" className="" />
            <p className="font-poppins font-medium text-base">
              Used by 1,000+ Happy Users
            </p>
            <Link
              to="/pricing"
              className="text-[#4F75FA] text-base text-center rounded-[10px] bg-white px-4 py-1 ml-5"
            >
              Checkout
            </Link>
          </div>
        </ul>
        {/* header */}
        <motion.div
          className="w-full sticky top-0 z-50 right-0"
          initial={{ opacity: 0, translateY: -50 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ duration: 0.5, ease: "easeIn" }}
        >
          <MainNav />
        </motion.div>

        <div className="container  mt-[1.2rem] lg:mt-[2.2rem]  relative text-black">
          {/* <div className="bg-black absolute right-4 bottom-4 z-30 ">
            <img
              src="/whatsapp.svg"
              className="w-14 "
            />
          </div> */}
          {/* <img
            src="/whatsapp.svg"
            className="absolute right-3 md:right-20 bottom-0 z-30 w-14 "
          /> */}

          <div className="w-full flex items-center gap-12 justify-center  lg:gap-0 flex-col lg:flex-row">
            <div className="lg:w-[55%] w-full space-y-7 flex flex-col  md:items-center lg:items-start">
              <h1 className="font-poppins font-semibold text-2xl xxs:text-4xl md:text-[55px] xxs:leading-[2.8rem] md:leading-[3.9rem] text-[#333333]">
                <span className="text-[#4E81EE]">G</span>
                <span className="text-[#E1183A]">o</span>
                <span className="text-[#F5C228]">o</span>
                <span className="text-[#4E81EE]">g</span>
                <span className="text-[#24BF5A]">l</span>
                <span className="text-[#E1183A]">e</span> Reviews <WordBreak />{" "}
                made easy with
                <WordBreak /> MagicReview AI
              </h1>
              <p className="font-poppins text-base md:text-[19px] font-normal h-fit flex gap-5 items-center leading-relaxed text-black">
                <div className="w-1 h-[4.8rem] bg-newPrimary"></div>
                Help your customers craft better reviews <WordBreak /> And
                increase the visibility of your <WordBreak /> business on Google
                search.
              </p>
              <div className="flex flex-wrap justify-center xxs:justify-start items-center gap-4">
                <button
                  className="flex items-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white"
                  onClick={() => handleNavigate("/sign-up")}
                >
                  Get Started <RightArrow />
                </button>
                <div
                  className="flex items-center font-semibold gap-2 px-6 py-3 text-[#2D2D2D] bg-[#EEF1FF] rounded-[12px] hover:text-newPrimary transition-all duration-300 hover:bg-white cursor-pointer"
                  onClick={() => handleNavigate("/how-it-works")}
                >
                  <IconPlay className="text-newPrimary" />
                  How It Works
                </div>
              </div>
            </div>
            {/* small screen */}
            <div className="relative xxs:hidden">
              <img
                loading="lazy"
                src={HomepageGirl}
                className="relative rounded-e-full rounded-es-full bottom-0 z-10 w-full"
                alt="homepage girl"
              />
              <div className="bg-newPrimary absolute m-auto w-[15rem] h-[15rem] rounded-full bottom-0"></div>
            </div>

            <div className="relative xxs:flex hidden justify-center items-center lg:w-[45%] w-full">
              {/* <div className="fixed right-0 bottom-5 z-30">
                <div className="container">
                  <a href="https://wa.me/+447776698333" target="blank">
                    <img src="/whatsapp.svg" className="w-14" />
                  </a>
                </div>
              </div> */}

              <div className="bg-[#F1F4FF] bottom-0 w-[23rem] h-[23rem] md:w-[32rem] rounded-full md:h-[32rem]"></div>
              <div className="bg-white absolute m-auto w-[20rem] h-[20rem] md:w-[29rem] rounded-full md:h-[29rem]">
                <img
                  loading="lazy"
                  src={HomepageGirl}
                  className="absolute rounded-e-full rounded-es-full bottom-0 z-10 w-full"
                  alt="homepage girl"
                />
              </div>
              <div className="bg-newPrimary absolute m-auto w-[17rem] h-[17rem] md:w-[26rem] rounded-full md:h-[26rem]"></div>
              <div className="absolute right-0 top-[115px] shadow-md  gap-3 font-poppins  rounded-[19px] bg-white z-20 flex items-center px-4 py-2 sm:py-4">
                <div>
                  <img
                    loading="lazy"
                    src={IconImg1}
                    className="w-[19px] h-[19px] sm:w-[42px] sm:h-[42px] object-contain"
                  />
                </div>
                <div className="sm:text-[15px] text-[8px]">
                  <div className="w-[45px] sm:w-[92px]">
                    <img src="/ratingstar.png" className="w-full" />
                  </div>
                  Emily Parker
                </div>
              </div>
              <div className="absolute shadow-md left-1 sm:-left-16 bottom-20 gap-3 space-y-2 font-poppins rounded-[19px] bg-white z-20 px-5 py-2 sm:py-4">
                <div className="flex items-center gap-3">
                  <div>
                    <img
                      loading="lazy"
                      src={IconImg2}
                      className="flex-shrink-0 w-[19px] h-[19px] sm:w-[42px] sm:h-[42px] object-contain"
                    />
                  </div>
                  <div className="sm:text-[15px] text-[8px]">
                    {/* <Rating className="w-[45px] sm:w-[92px]">
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                    </Rating> */}
                    <div className="w-[45px] sm:w-[92px]">
                      <img src="/ratingstar.png" className="w-full" />
                    </div>
                    Leena Mac
                  </div>
                </div>
                <p className="text-[8px] sm:text-sm">
                  Using Magicreview card helped <br /> our restaurant Generate
                  3x <br /> More Revenue
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* stats */}

        {/* client section */}
        <div className="mt-20 client bg-[#EEF2FF] py-10 text-black">
          <h1 className="text-[30px] md:text-[50px] text-center font-medium font-poppins my-2">
            Our Major Clients
          </h1>
          <p className="text-[#2D2D2D] text-base md:text-lg font-poppins text-center mb-10">
            Trusted by Industry Leaders.
          </p>

          <ClientSlider />
        </div>

        {/* second section */}
        <div className="w-full bg-white">
          <div className="w-full font-poppins container lg:py-20 space-y-10 py-14 flex flex-row flex-wrap items-center justify-between">
            <div className="lg:w-[40%] w-full space-y-5 md:space-y-7">
              <div className="bg-newPrimary rounded-full p-4 flex-shrink-0 w-fit">
                <img
                  loading="lazy"
                  src={walletSvg}
                  alt=""
                  className="w-[30px] h-[30px]"
                />
              </div>
              <h2 className="font-semibold text-[24px] md:text-[45px] text-[#333333]">
                Reviews in a Tap
              </h2>
              <p className="tracking-wide text-sm md:text-[17px] text-[#2D2D2D] font-normal md:leading-[26px]">
                Switch From Emails And Texts To MagicReview's AI Review Cards,
                Featuring NFC And QR Technology. Customers Can Easily Leave
                Reviews With A Tap Or Scan On Their Phones, Enabling You To
                Gather Reviews Under 30 Seconds And Lower Marketing Costs With
                Word-Of-Mouth Promotion.
              </p>
              <button
                className="flex items-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white"
                onClick={() => handleNavigate("/sign-up")}
              >
                Get Started <RightArrow />
              </button>
            </div>
            <div className="lg:w-[55%] w-full">
              <div className="bg-[#4F75FA]  rounded-[30px] w-full text-center">
                <img
                  loading="lazy"
                  src={section1}
                  className="mx-auto pt-10 w-[85%]"
                />
              </div>
            </div>
          </div>
        </div>

        {/* third section */}
        <div className="w-full bg-[#F1F4FF]">
          <div
            className=" font-poppins container space-y-10 lg:py-20 py-14  flex flex-row flex-wrap-reverse items-center justify-between gap-[45px]
          "
          >
            <div className="lg:w-[50%] w-full space-y-7">
              <div className="bg-white  rounded-[30px] ">
                <img
                  loading="lazy"
                  src={section2}
                  className="mx-auto pt-10 max-w-[378.22px] w-full"
                />
              </div>
            </div>
            <div className="lg:w-[40%] w-full space-y-4">
              <div className="bg-newPrimary rounded-full p-4 flex-shrink-0 w-fit">
                <img
                  loading="lazy"
                  className="w-[30px] h-[30px]"
                  src={rankSvg}
                />
              </div>
              <h2 className="font-semibold text-[24px] md:text-[45px] leading-tight text-[#333333]">
                Rank 1st on Search Engine Result Page
              </h2>
              <p className="tracking-wide text-[14px] md:text-lg text-[#2D2D2D]">
                Boost your online presence with MagicReview and top search
                engine results. Our AI-generated, SEO-optimized reviews push
                your business to the top of search pages, attracting new
                customers to discover your business
              </p>
              <button
                className="flex items-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white"
                onClick={() => handleNavigate("/sign-up")}
              >
                Get Started <RightArrow />
              </button>
            </div>
          </div>
        </div>

        {/* fourth section */}
        <div className="w-full bg-white">
          <div className="w-full font-poppins container space-y-10 lg:py-20 py-14 flex flex-row flex-wrap items-center justify-between">
            <div className="lg:w-[40%] w-full space-y-4">
              <div className="bg-newPrimary rounded-full p-4 flex-shrink-0 w-fit">
                <img
                  loading="lazy"
                  className="w-[30px] h-[30px]"
                  src={magnetSvg}
                />
              </div>
              <h2 className="font-semibold text-[25px] md:text-[45px] leading-tight text-[#333333]">
                Attract More <WordBreak /> Customers
              </h2>
              <p className="tracking-wide text-sm md:text-lg text-[#2D2D2D]">
                Encourage more feedback with MagicReview&apos;s simplified
                review tool. By streamlining the review process, you can
                increase the number of reviewers, ultimately attracting more
                customers who are in search of trustworthy businesses with
                strong online reputations.
              </p>
              <button
                className="flex items-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white"
                onClick={() => handleNavigate("/sign-up")}
              >
                Get Started <RightArrow />
              </button>
            </div>
            <div className="lg:w-[55%] w-full">
              <div className="bg-[#4F75FA] rounded-[30px] ">
                <img
                  loading="lazy"
                  src={section3}
                  className="mx-auto pt-10 object-contain max-w-[492px] w-full"
                />
              </div>
            </div>
          </div>
        </div>

        {/* fifth section */}
        <div className="w-full bg-[#F1F4FF]">
          <div className="font-poppins container space-y-10 lg:py-20 py-14 flex  flex-row flex-wrap-reverse items-center  justify-between gap-[45px]">
            <div className="lg:w-[55%] w-full">
              <div className="bg-white rounded-[30px] ">
                <img
                  loading="lazy"
                  src={section4}
                  className="mx-auto pt-10 w-[80%]"
                />
              </div>
            </div>
            <div className="lg:w-[40%] w-full space-y-4">
              <div className="bg-newPrimary rounded-full p-4 flex-shrink-0 w-fit">
                <img
                  loading="lazy"
                  className="w-[30px] h-[30px]"
                  src={writingSvg}
                />
              </div>
              <h2 className="font-semibold text-[25px] md:text-[45px] leading-tight text-[#333333]">
                Eliminate <WordBreak /> Human Writing
              </h2>
              <p className="tracking-wide text-sm md:text-lg text-[#2D2D2D]">
                Simplify your review process with MagicReview's AI-powered
                technology. MagicReview uses AI to create well-structured and
                engaging reviews based on customer inputs. Customers can select
                from various review drafts to ensure authenticity, eliminating
                the need for manual review writing.
              </p>
              <button
                className="flex items-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white"
                onClick={() => handleNavigate("/sign-up")}
              >
                Get Started <RightArrow />
              </button>
            </div>
          </div>
        </div>

        {/* sixth section */}
        <div className="w-full min-h-0 font-poppins container flex lg:flex-row flex-col lg:py-20 py-10 gap-10">
          <div className="lg:w-[26%] w-full px-8 py-[3.4rem] rounded-[18px] space-y-12 h-full bg-newPrimary text-white flex flex-col">
            <h2 className="font-semibold text-[22px] xxs:text-[32px] xl:text-[40px] xxs:leading-[52px]">
              More <WordBreak /> Reviews, <WordBreak /> More <WordBreak />{" "}
              Customers
            </h2>
            <div className="flex-1 hidden lg:flex"></div>
            <h3>
              Authentic feedback builds trust and brings in more customers.
            </h3>
          </div>
          <div className="flex w-full lg:w-[70%]">
            <div className="w-full h-full grid md:grid-cols-2 gap-[29px]">
              {reviewData2?.map((item) => {
                return (
                  <div
                    key={uuidv4()}
                    className="w-full bg-[#F1F4FF]  h-full px-2 xxs:px-10 xxs:pr-[60px] py-8 rounded-[18px] border-2 border-[#BDCBFF]"
                  >
                    <div className="flex items-start text-[50px] font-semibold gap-4 mb-[10px] font-heebo text-[#2D2D2D]">
                      {item?.value}{" "}
                      <img
                        loading="lazy"
                        src={upwardSvg}
                        alt="upward"
                        className="-mt-2"
                      />
                    </div>
                    <p className="font-poppins text-[#434343] text-lg">
                      {item?.desc}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* seventh section */}
        <div className="w-full font-poppins container lg:py-20 py-10">
          <h2 className="text-center font-semibold text-[24px] md:text-4xl text-[#333333]">
            Features and Offerings
          </h2>
          <h3 className="text-center text-base text-[#2D2D2D] mt-[10px]">
            Trusted By Top B2C Companies Over UAE
          </h3>
          <div className="flex flex-row flex-wrap gap-8 justify-center lg:justify-between mt-[81px]">
            {featuresData?.map((item) => {
              return (
                <div
                  key={uuidv4()}
                  className="w-full md:w-[45%] lg:w-[30%] space-y-4 border-2 border-[#CCD7FF] hover:shadow-features transition-all duration-200 ease-in-out rounded-2xl py-8 xxs:px-10 px-5"
                >
                  <h4 className="text-center font-semibold text-xl text-[#242331]">
                    {item?.title}
                  </h4>

                  <img
                    loading="lazy"
                    src={item?.img}
                    className="mx-auto w-full object-contain"
                    // className={`absolute bottom-0 w-full ${item?.img == (features1 || features2) && "h-full"} px-2 ${item?.img == features2 && "bottom-0"}`}
                    alt="features"
                  />

                  <p className="text-base tracking-wide leading-relaxed text-[#474646]">
                    {item?.desc}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        {/* eighth section */}
        <div className="w-full font-poppins container pb-[50px] md:pb-[150px] mt-[50px] md:mt-[100px]">
          <h2 className="font-semibold  leading-snug text-[24px] md:text-[40px] text-center mb-5 text-[#333333]">
            Here is why you'll love <br /> working with us
          </h2>
          <p className="text-center text-[14px] md:text-base mb-[30px] text-[#2D2D2D]">
            Authentic feedback builds trust and brings in more customers.
          </p>
          <HomeSlider />
        </div>
        {/* ninth section */}
        <div className="w-full bg-[#F1F4FF] py-[100px] flex flex-col justify-center items-center">
          <h2 className="text-center font-poppins md:text-[43px] xxs:text-[24px] text-lg font-medium md:leading-[58px] mb-[11px] text-[#333333]">
            Choose a Plan to Simplify <br /> Your Review Collection
          </h2>
          <h3 className="font-poppins text-[#333333] xxs:text-[18px] text-sm mb-[31px] text-center">
            Pick the plan that suits your needs the most.
          </h3>

          {/* card */}
          <section className="w-full relative font-poppins container text-[#333333] space-y-6 pt-10 z-10 pb-10 mt-10">
            <div className="bg-white shadow-sm rounded-[19px] max-w-[716px] w-full px-5 space-y-6 py-[70px] m-auto">
              <div className="w-[90%] lg:w-[70%] mx-auto flex flex-col justify-center items-center">
                <div className=" bg-lightpurple w-full m-auto px-1 py-2 justify-center  rounded-[10px] sm:rounded-[16px] flex flex-wrap xms:flex-nowrap gap-1 md:gap-2 items-center text-xs sm:text-sm md:text-base lg:text-lg text-nowrap">
                  {UpdatedPlans?.map((item, id) => {
                    return (
                      <span
                        key={id}
                        className={`rounded-[5px] sm:rounded-[8px] flex justify-center items-center px-3 sm:px-5 py-2 sm:py-3 ${selectedPlan == id && "bg-newPrimary text-white"} cursor-pointer transition-all w-full xms:w-1/4 text-center duration-500`}
                        onClick={(e) => setSelectedPlan(id)}
                      >
                        {item?.short_text}
                      </span>
                    );
                  })}
                </div>

                <div className="px-3 sm:px-5 py-2 text-[#FF0000] border border-[#FF0000] rounded-[4px] sm:rounded-[18px] mt-4 sm:mt-[44px] font-poppins text-[12px] sm:text-base mb-[13px] sm:mb-[15px]">
                  {UpdatedPlans[selectedPlan]?.secondary_text}
                </div>

                <h2 className="text-[#333333] font-poppins text-[32px] sm:text-[40px] lg:text-[67px] font-extrabold text-center sm:mt-3">
                  {(UpdatedPlans[selectedPlan]?.discount !== 0
                    ? UpdatedPlans[selectedPlan]?.price_after_discount
                    : UpdatedPlans[selectedPlan]?.price
                  )?.toLocaleString("en-US", {
                    style: "currency",
                    currency: UpdatedPlans[selectedPlan]?.currency,
                  })}
                </h2>
                {UpdatedPlans[selectedPlan]?.discount !== 0 ? (
                  UpdatedPlans[selectedPlan]?.is_relative_discount_price ? (
                    <div className="flex gap-2 sm:gap-[31px] items-center font-poppins mb-[27px]">
                      <h2 className="text-base sm:text-[32px] text-[#333333] line-through">
                        {UpdatedPlans[selectedPlan]?.price?.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: UpdatedPlans[selectedPlan]?.currency,
                          }
                        )}
                      </h2>
                      <button className="bg-[#73DF07] rounded-[3px] sm:rounded-[9px] px-2 sm:px-5 py-1 text-white text-[10px] sm:text-[20px] ">
                        {UpdatedPlans[selectedPlan]?.discount}% OFF{" "}
                      </button>
                    </div>
                  ) : (
                    <div className="flex gap-[31px] items-center font-poppins mb-[27px]">
                      <h2 className="text-[26px] md:text-[32px] text-[#333333] line-through text-nowrap">
                        {UpdatedPlans[selectedPlan]?.price?.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: UpdatedPlans[selectedPlan]?.currency,
                          }
                        )}
                      </h2>
                      <button className="bg-[#73DF07] rounded-[9px] px-5 py-1 text-white text-base  md:text-[20px] text-nowrap ">
                        {/* {UpdatedPlans[selectedPlan]?.discount?.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: UpdatedPlans[selectedPlan]?.currency,
                          }
                        )}{" "} */}
                        {UpdatedPlans[selectedPlan]?.discount_percent}% OFF{" "}
                      </button>
                    </div>
                  )
                ) : null}
              </div>

              <div className="flex gap-x-3 justify-center items-center lg:text-lg text-xs">
                <p className="text-[#FF0000]">NOTE:</p>
                <p className="text-[#1F1E1E] font-medium">
                  MagicReview Card: {cardPrice()}/ Card
                </p>
              </div>

              <div className="max-w-[476.79px] mx-auto w-full">
                {pricingData.map((item, index) => (
                  <div
                    key={uuidv4()}
                    className={`
      ${index === pricingData.length - 1 ? "border-none" : "border-b border-[#EAEAEA]"}
      flex font-poppins text-[#1F1E1E] lg:items-center items-start text-start gap-[13px] lg:text-lg text-xs w-full mb-3 pb-2`}
                  >
                    <img
                      src="/check.svg"
                      alt="check"
                      className="w-[24px] h-[24px]"
                    />
                    {item}
                  </div>
                ))}

                <button
                  className="bg-[#4F75FA] border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white border  font-poppins w-full mt-10 py-3 rounded-[10px] text-white"
                  onClick={() => handleNavigate("/sign-up")}
                >
                  Subscribe Now
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Footer
        home={home}
        overview={overview}
        keyFeatures={keyFeatures}
        whyChooseUs={whyChooseUs}
        faqs={faqs}
      />
    </>
  );
};

export default Home;
