/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";
import {
  usePostAddAiQuestions,
  usePostGenerateAiQuestions,
} from "../../../query/useEntity";
import { toast } from "sonner";
import { LogoLoading } from "../../../components/loading/loading";
import QuestionCardForAiQuestions from "./QuestionCardForAiQuestions";
import { useQueryClient } from "@tanstack/react-query";
import { Sparkles, Info } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { useGetQuestionSets } from "../../../query/useEntity.js";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

const AIQuestions = ({ openAddModal, setOpenAddModal, questionSet }) => {
  const [tab, setTab] = useState(1);

  const handleTabChange = (tab) => {
    setTab(tab);
  };
  const [aboutCompany, setAboutCompany] = useState("");
  const [catalog, setCatalog] = useState("");
  const [instructions, setInstructions] = useState("");
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedQuestionSet, setSelectedQuestionSet] = useState();
  const queryClient = useQueryClient();

  const { data: questionSets } = useGetQuestionSets();

  const { mutate: generateAiQuestions, isLoading: generateAiQuestionsLoading } =
    usePostGenerateAiQuestions();

  const { mutate: addAiQuestions, isLoading: addAiQuestionsLoading } =
    usePostAddAiQuestions();

  const handleGenerateAiQuestions = () => {
    generateAiQuestions(
      {
        // about: aboutCompany,
        catalog: catalog,
        misc_instructions: instructions || null,
      },
      {
        onSuccess: (res) => {
          toast.success("Generated AI assissted questions successfully");
          setGeneratedQuestions(res.suggestions);
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleAddAiQuestions = () => {
    const modifiedQuestions = selectedQuestions?.map((item) => {
      return {
        question: item?.question,
        response_options: item?.possible_responses,
        category: item?.category,
        keyword: item?.keyword,
      };
    });
    addAiQuestions(
      {
        set: selectedQuestionSet,
        data: {
          ai_questions: modifiedQuestions,
        },
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setOpenAddModal(false);
          queryClient.invalidateQueries({ queryKey: ["getDefaultQuestions"] });
          queryClient.invalidateQueries({
            queryKey: ["getDefaultQuestionsForSets"],
          });
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  // Function to toggle individual selection
  const toggleSelection = (item) => {
    setSelectedQuestions((prev) => {
      const current = [...prev];
      if (current.some((e) => e?.question === item?.question)) {
        return current.filter((e) => e?.question !== item?.question);
      } else {
        return [...prev, item];
      }
    });
  };

  // Function to toggle "Select All"
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedQuestions([]); // Deselect all
    } else {
      setSelectedQuestions(generatedQuestions); // Select all
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setSelectedQuestionSet(questionSet);
  }, []);

  if (generateAiQuestionsLoading || addAiQuestionsLoading) {
    return <LogoLoading />;
  }

  return (
    <Modal
      popup
      dismissible
      show={openAddModal}
      onClose={() => {
        setOpenAddModal(false);
      }}
      size={"5xl"}
      className="z-[1000]"
      theme={{
        content: {
          base: "relative p-4 h-auto overflow-y-hidden",
          inner: "bg-white dark:bg-darkBg rounded-lg",
        },
        sizes: {
          sm: "max-w-sm",
          md: "max-w-md",
          lg: "max-w-lg",
          xl: "max-w-xl",
          "2xl": "max-w-2xl",
          "3xl": "max-w-3xl",
          "4xl": "max-w-4xl",
          "5xl": "max-w-5xl",
          "6xl": "max-w-6xl",
          "7xl": "max-w-7xl",
        },
        header: {
          base: "flex items-start justify-between rounded-t-lg border-b dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
          close: {
            base: "ml-auto inline-flex items-center rounded-full bg-transparent mt-5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
          },
        },
      }}
    >
      {
          !generatedQuestions?.length >  0 && (
            <Modal.Header className="px-[15px] md:px-[50px]">
            <header className="flex items-center mb-3 mt-5">
              <h1 className="text-lg md:text-2xl font-medium font-pro ">
                Generate custom questions with AI
              </h1>
            </header>
          </Modal.Header>
          )
      }

      <Modal.Body className="flex flex-col scrollbar-none font-pro lg:w-[950px] px-0 pt-6 pb-10">
        {/* <input
                  type="checkbox"
                  value={item}
                  onChange={() => {
                    setSelectedQuestions((prev) => {
                      const current = [...prev];
                      if (current.some((e) => e?.question === item?.question)) {
                        return current.filter(
                          (e) => e?.question !== item?.question
                        );
                      } else {
                        return [...prev, item];
                      }
                    });
                  }}
                  name="aiquestions"
                  id={item?.question}
                /> */}
        {/* Generated Questions */}
        {
          generatedQuestions?.length > 0 ? (
            <div className="max-h-[90vh] space-y-3 w-full px-[15px] md:px-[50px]">
              <div className="flex sm:flex-row flex-col gap-2 items-center justify-between mb-6">
                <div className="flet lg:flex-row flex-col lg:items-center lg:justify-start gap-3 w-full">
                  <p className="text-lg text-[#252323] dark:text-white font-medium font-pro">
                    Question Set
                  </p>
                  <Select
                    value={selectedQuestionSet}
                    onValueChange={(item) => setSelectedQuestionSet(item)}
                  >
                    <SelectTrigger className="max-w-sm">
                      <SelectValue placeholder="Select question set" />
                    </SelectTrigger>
                    <SelectContent>
                      {questionSets?.map((item) => (
                        <SelectItem key={item?.id} value={item?.id}>
                          {item?.name === "default" ? "Default" : item?.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <button
                  className="px-4 py-2 bg-newPrimary text-white rounded-lg flex-shrink-0"
                  onClick={toggleSelectAll}
                >
                  {selectAll ? "Deselect All" : "Select All"}
                </button>
              </div>

              <div  className="overflow-y-auto scrollbar-thin h-[50vh] space-y-3">
                {generatedQuestions?.map((item, i) => (
                  <div
                    key={item?.question}
                    className="flex items-center gap-3 w-full cursor-pointer"
                    onClick={() => toggleSelection(item)}
                  >
                    <label
                      htmlFor={item?.question}
                      className="w-full cursor-pointer"
                    >
                      <QuestionCardForAiQuestions
                        item={item}
                        questionIndex={i}
                        selected={selectedQuestions.some(
                          (e) => e?.question === item?.question
                        )}
                      />
                    </label>
                  </div>
                ))}
              </div>

              <div className="flex flex-wrap lg:gap-2 justify-between lg:justify-end">
                <button
                  className="flex items-center justify-center rounded-[7px] border border-newPrimary text-newPrimary text-sm sm:text-base text-center font-medium py-[10px] px-[5px]  sm:px-[40px] bg-white dark:bg-darkLightBg gap-[6px] mt-[20px] w-[49.5%] lg:w-auto"
                  onClick={() => {
                    setGeneratedQuestions([]); 
                  }}
                >
                  Back to Generate
                </button>
                <button
                  className="flex items-center justify-center rounded-[7px] border border-newPrimary text-white text-sm sm:text-base text-center font-medium py-[10px] px-[5px]  sm:px-[40px] bg-newPrimary hover:bg-white hover:text-newPrimary gap-[6px] mt-[20px] w-[49.5%] lg:w-auto transition-all duration-200 dark:hover:bg-darkLightBg"
                  onClick={() => {
                    handleAddAiQuestions();
                  }}
                >
                  Add to your questions
                </button>
              </div>
            </div>
          ) : (
            <div className="h-[60vh] overflow-y-auto scrollbar-thin w-full px-[15px] md:px-[50px] py-5">
              <div className="border border-newPrimary dark:bg-darkLightBg dark:border-none p-3 bg-[#f6f6ff] rounded-[7px] mb-[34px]">
                <div className="flex gap-2">
                  <Info size={20} color="#4f75fa" className=" shrink-0" />
                  <div className="-mt-1">
                    <h1 className="text-base text-[#252323] dark:text-white font-semibold font-pro">
                      For Best Results
                    </h1>
                    <p className="text-xs md:text-sm text-[#4A4A4A] dark:text-white">
                      Provide as much detail as possible. Mention your audience
                      if relevant. Describe the context of your form (e.g if
                      it&apos;s an event registration form, mention details like
                      event date, name of the event ..)
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex w-full md:flex-row flex-col justify-between gap-5">
                <div className="md:w-[50%] flex flex-col w-full font-pro">
                  <h1 className="text-[#252323] dark:text-white  text-base mb-3">
                    Describe the question you have in mind
                  </h1>

                  <textarea
                    className="border border-[#EBEBEB] px-3 py-3 rounded-lg focus:outline-none focus:ring-1 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6] dark:text-white dark:bg-darkLightBg dark:border-none resize-none"
                    value={catalog}
                    onChange={(e) => setCatalog(e.target.value)}
                    placeholder="E.g.. A form for my potential customers to request a demo of my product"
                    rows={4}
                  ></textarea>

                  <h1 className="text-[#252323] dark:text-white   text-base mt-[34px]">
                    List your specific questions or topics, if any
                  </h1>

                  <textarea
                    className="border border-[#EBEBEB] px-3 py-3 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6] dark:text-white dark:bg-darkLightBg dark:border-none resize-none"
                    value={instructions}
                    onChange={(e) => setInstructions(e.target.value)}
                    placeholder=""
                    rows={4}
                  ></textarea>
                </div>

                <div className="md:w-[40%] flex flex-col w-full font-pro">
                  <h1 className="text-[#252323] dark:text-white font-semibold text-base mb-[12px]">
                    Suggestions for you
                  </h1>

                  <ul className="list-disc pl-5 space-y-4 mb-5">
                    <li>
                      Skill assessment form evaluating the technical skills of
                      candidates
                    </li>
                    <li>
                      Culturally fit survey conforming to the company values
                    </li>
                    <li>
                      Quiz measuring the competence of a candidate on the basis
                      of past roles
                    </li>
                  </ul>

                  <button
                    className="flex items-center justify-end rounded-[7px] border border-newPrimary text-base text-center font-medium py-[10px]  px-[40px] bg-newPrimary gap-[6px] mt-auto ml-auto text-white"
                    onClick={handleGenerateAiQuestions}
                    disabled={!catalog}
                  >
                    Generate
                  </button>
                </div>
              </div>
            </div>
          )
          //
        }

        {/* {generatedQuestions?.length > 0 ? (
          <div className="h-[70vh] overflow-y-auto scrollbar-thin p-1 space-y-3">
            {generatedQuestions?.map((item, i) => (
              <div
                key={item?.question}
                className="flex items-center gap-3 w-full"
              >
                <input
                  type="checkbox"
                  value={item}
                  onChange={() => {
                    setSelectedQuestions((prev) => {
                      const current = [...prev];
                      if (current.some((e) => e?.question === item?.question)) {
                        return current.filter(
                          (e) => e?.question !== item?.question
                        );
                      } else {
                        return [...prev, item];
                      }
                    });
                  }}
                  name="aiquestions"
                  id={item?.question}
                />
                <label htmlFor={item?.question} className="w-full">
                  <QuestionCardForAiQuestions item={item} questionIndex={i} />
                </label>
              </div>
            ))}
            <div className="flex flex-wrap lg:gap-2 justify-between lg:justify-end">
              <button
                className="flex items-center justify-center rounded-[7px] border border-newPrimary text-newPrimary text-base text-center font-medium py-[10px]  px-[40px] bg-white dark:bg-darkLightBg gap-[6px] mt-[20px] w-[49.5%] lg:w-auto"
                onClick={() => {
                  setGeneratedQuestions([]);
                }}
              >
                Back to Generate
              </button>
              <button
                className="flex items-center justify-center rounded-[7px] border border-newPrimary text-white text-base text-center font-medium py-[10px]  px-[40px] bg-newPrimary hover:bg-white hover:text-newPrimary gap-[6px] mt-[20px] w-[49.5%] lg:w-auto transition-all duration-200 dark:hover:bg-darkLightBg"
                onClick={() => {
                  handleAddAiQuestions();
                }}
              >
                Add to your questions
              </button>
            </div>
          </div>
        ) : (
          <div className=" overflow-y-auto scrollbar-thin p-1">
            <div className="space-y-5">
              <div className="space-y-3">
                <p className="text-base lg:text-[20px] dark:text-white">
                  About Company or Store
                </p>
                <textarea
                  autoFocus={false}
                  value={aboutCompany}
                  onChange={(e) => setAboutCompany(e.target.value)}
                  placeholder="About Company or Store"
                  className="border border-gray-300 px-3 py-3 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6] dark:text-white dark:bg-darkLightBg dark:border-none resize-none"
                />
              </div>
              <div className="space-y-3">
                <p className="text-base lg:text-[20px] dark:text-white">
                  Catalog
                </p>
                <textarea
                  autoFocus={false}
                  value={catalog}
                  onChange={(e) => setCatalog(e.target.value)}
                  placeholder="Catalog"
                  className="border border-gray-300 px-3 py-3 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6] dark:text-white dark:bg-darkLightBg dark:border-none resize-none"
                />
              </div>
              <div className="space-y-3">
                <p className="text-base lg:text-[20px] dark:text-white">
                  Additional Instructions
                </p>
                <textarea
                  autoFocus={false}
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                  placeholder="Additional instructions"
                  className="border border-gray-300 px-3 py-3 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6] dark:text-white dark:bg-darkLightBg dark:border-none resize-none"
                />
              </div>
              <div className="flex flex-wrap lg:gap-2 justify-between lg:justify-end">
                <button
                  className="flex items-center justify-center rounded-[7px] border border-newPrimary text-newPrimary text-base text-center font-medium py-[10px]  px-[40px] bg-white dark:bg-darkLightBg gap-[6px] mt-[20px] w-[49.5%] lg:w-auto"
                  onClick={() => {
                    setOpenAddModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="flex items-center justify-center rounded-[7px] border border-newPrimary text-white text-base text-center font-medium py-[10px]  px-[40px] bg-newPrimary hover:bg-white hover:text-newPrimary gap-[6px] mt-[20px] w-[49.5%] lg:w-auto transition-all duration-200 dark:hover:bg-darkLightBg"
                  onClick={() => {
                    handleGenerateAiQuestions();
                  }}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        )} */}
      </Modal.Body>
    </Modal>
  );
};

export default AIQuestions;
