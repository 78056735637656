/* eslint-disable react/prop-types */

export const Input = ({ label, placeholder, value, setValue, disabled }) => {
  return (
    <div className="w-full">
      <label className="text-sm font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
        {label}
      </label>
      <input
        className="border-[1px] border-[#E7E4E4] dark:border-[#454545] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5] h-12 focus:ring-2 focus:ring-newPrimary  focus:ring-offset-1"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
      />
    </div>
  );
};

export const UploadInput = ({ label, placeholder }) => {
  return (
    <div className="w-full">
      <label className="text-[#000] text-xl font-pro mb-[10px] flex gap-1">
        {label}
      </label>
      <div className="w-full flex relative z-0">
        <img
          src="/upload.svg"
          alt=""
          className=" w-6 h-6 absolute right-4 top-4"
        />
        <input
          className="border-[1px] border-[#E7E4E4] rounded-[8px] py-[15px] px-[15px] text-[#A7A5A5] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full"
          type="text"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export const TextAreaInput = ({ label, placeholder }) => {
  return (
    <div className="w-full">
      <label className="text-[#000] text-xl font-pro font-medium mb-[10px] flex gap-1">
        {label}
      </label>
      <textarea
        className="border-[1px] border-[#E7E4E4] rounded-[8px] py-[15px] px-[15px] text-[#A7A5A5] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full resize-none pb-[30px]"
        rows={5}
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
};
