import {
  Bot,
  Check,
  ChevronLeft,
  ChevronRight,
  Download,
  Link,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import LoaderIcon from "react-loader-icon";
import Skeleton from "react-loading-skeleton";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "swiper/css";
import "swiper/css/bundle";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Booking from "../../../assets/reviewservices/booking.svg";
import Google from "../../../assets/reviewservices/google.svg";
import TripAdvisor from "../../../assets/reviewservices/tripadvisor.svg";
import TrustPilot from "../../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../../assets/reviewservices/yelp.svg";
import { useGetEntityReviews } from "../../../query/useReview";
import { dayFormatter } from "../../../utils/formatter";
import Layout from "../component/Layout";
import DownloadModal from "./components/DownloadModal";
import { useDarkMode } from "../../../context/DarkModeProvider";
import CardStack from "./components/CardStack";
import ReviewsDropdown from "../component/ReviewsDropdown";
import { ReviewsIllustration } from "../../onboarding/components/illustrations/Reviews";
const platforms = [
  {
    name: "Google",
    value: "google",
    image: Google,
  },
  {
    name: "Trustpilot",
    value: "trustpilot",
    image: TrustPilot,
  },
  {
    name: "Yelp",
    value: "yellowpage",
    image: Yelp,
  },
  {
    name: "Booking",
    value: "booking",
    image: Booking,
  },
  {
    name: "TripAdvisor",
    value: "tripadvisor",
    image: TripAdvisor,
  },
];

const Reviews = () => {
  const { inView, ref } = useInView();
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Redirected Reviews");
  const [isOpenDownload, setIsOpenDownload] = useState(false);
  const { darkMode } = useDarkMode();
  const entityId =
    localStorage.getItem("entityUuid") && localStorage.getItem("entityUuid");

  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const {
    data: reviews,
    hasNextPage,
    fetchNextPage,
    isLoading: isReviewsLoading,
  } = useGetEntityReviews({
    entityId: entityId,
    limit: 9,
    platforms: selectedPlatforms.join(","),
    is_posted_ai_review: selectedOption === "Redirected Reviews" ? true : null,
  });

  const handleSelectedServices = (platform) => {
    if (selectedPlatforms.includes(platform)) {
      setSelectedPlatforms((prev) => prev.filter((item) => item !== platform));
    } else {
      setSelectedPlatforms((prev) => [...prev, platform]);
    }
  };

  useEffect(() => {
    if (hasNextPage && inView) {
      fetchNextPage();
    }
  }, [inView]);

  const groupArrayElements = (array) => {
    const groupedArray = [];

    for (let i = 0; i < array?.length; i += 3) {
      // Slice the array in chunks of three elements
      groupedArray.push(array?.slice(i, i + 3));
    }

    return groupedArray;
  };

  const flattenedReviews = reviews?.pages?.flatMap((page) => page?.results);
  console.log(flattenedReviews);
  const groupedReviews = groupArrayElements(flattenedReviews);

  const handleGoToMagiclink = (link) => {
    window.open(link);
  };

  return (
    <Layout title={"Reviews"}>
      <div className="w-full dark:bg-darkLightBg rounded-[16px] py-5 md:py-[31px] min-h-[calc(100vh-140px)] mt-5 my-5 px-5 lg:px-[30.2px]">
        <div className="hidden">
          <div className=" justify-between items-center w-full lg:w-[72%] ">
            <div
              className="prev-service cursor-pointer border border-[#f6f6ff] hover:border-[#F1F4FF] hover:bg-[#F1F4FF] w-fit rounded-full lg:p-2 active:scale-95 text-[#615e83] hover:text-secondary hidden lg:block xl:hidden"
              onClick={handlePrev}
            >
              <ChevronLeft />
            </div>
            <Swiper
              ref={swiperRef}
              modules={[Navigation]}
              navigation={{
                nextEl: "next-service",
                prevEl: "prev-service",
              }}
              // breakpoints={{
              //   200: {
              //     slidesPerView: 2.5,
              //   },
              //   768: {
              //     slidesPerView: 3,
              //   },
              //   1280: {
              //     slidesPerView: 4,
              //   },
              //   1440: {
              //     slidesPerView: 5,
              //   },
              // }}
              slidesPerView={"auto"}
              spaceBetween={10}
              className="py-3 lg:px-2 w-full"
            >
              {platforms.map((item) => (
                <SwiperSlide
                  key={item.value}
                  className={`py-2 px-3 text-center rounded-full cursor-pointer flex justify-center items-center gap-x-2 w-max flex-shrink border ${selectedPlatforms.includes(item.value) ? "bg-[#7190ff1f] border-[rgba(107,141,208,0.41)]" : "bg-white"}`}
                  onClick={() => handleSelectedServices(item.value)}
                >
                  <div className="bg-white rounded-full w-6 h-6 p-0.5 flex-shrink-0 flex justify-center items-center">
                    <img src={item.image} alt="" />
                  </div>
                  <p className="text-sm">{item.name}</p>
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              className="next-service cursor-pointer border border-[#f6f6ff] hover:border-[#F1F4FF] hover:bg-[#F1F4FF] w-fit rounded-full lg:p-2 active:scale-95 text-[#615e83] hover:text-secondary hidden lg:block xl:hidden"
              onClick={handleNext}
            >
              <ChevronRight />
            </div>
          </div>
          {/* <div className="flex justify-center lg:justify-end gap-x-3 items-center w-full lg:w-4/12">
            <select
              name="days"
              id="days"
              className="bg-[#f6f6ff] border border-[#e2e1ea] rounded-lg cursor-pointer"
            >
              <option value={"7days"}>Last 7 Days</option>
              <option value={"30days"}>Last 30 Days</option>
              <option value={"90days"}>Last 90 Days</option>
            </select>
            <select
              name="days"
              id="days"
              className="bg-[#f6f6ff] border border-[#e2e1ea] rounded-lg cursor-pointer"
            >
              <option value={""}>Sort</option>
              <option value={"30days"}>
                <div className="flex items-center">Ascending</div>
              </option>
              <option value={"90days"}>Descending</option>
            </select>
          </div> */}
        </div>
        <div className="flex items-center gap-3 justify-start flex-wrap">
          <ReviewsDropdown
            className={"max-w-[230px] w-full text-nowrap"}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <button
            className="flex items-center w-auto px-5 py-3 rounded-[8px] border border-newPrimary text-white bg-newPrimary hover:bg-white dark:hover:bg-darkLightBg hover:text-newPrimary font-pro text-sm gap-x-2 transition-all duration-200"
            onClick={() => setIsOpenDownload(true)}
          >
            <p className="text-[14px] font-medium">Download</p>
            <Download className="w-5 h-5" />
          </button>
        </div>
        {isReviewsLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 mt-5">
            {new Array(3).fill("").map((_, i) => (
              <div
                className="flex flex-col rounded-[12px] p-9 bg-[#FFF] dark:bg-darkBg gap-y-5"
                key={i}
              >
                <Skeleton
                  circle
                  className="w-[50px] h-[50px]"
                  baseColor={darkMode && "#262626"}
                />
                <Skeleton height={120} baseColor={darkMode && "#262626"} />
                <Skeleton height={20} baseColor={darkMode && "#262626"} />
              </div>
            ))}
          </div>
        ) : flattenedReviews?.length > 0 ? (
          <>
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                200: 1,
                1280: 2,
                1440: 3,
              }}
              className="mt-5"
            >
              <Masonry gutter="20px">
                {flattenedReviews?.map((review, i) => (
                  <CardStack key={i} review={review} index={i} />
                ))}
              </Masonry>
            </ResponsiveMasonry>
            {hasNextPage && (
              <div ref={ref} className="text-center mt-10">
                <LoaderIcon type="cylon" color="#7190FF" />
              </div>
            )}
          </>
        ) : (
          <ReviewsIllustration />
          // <div className="w-full h-[60vh] flex flex-col justify-center items-center">
          //   <img src="/empty/no-review.svg" alt="" />
          //   <p className="font-pro text-[#888] text-sm">
          //     No reviews posted yet
          //   </p>
          // </div>
        )}
      </div>
      <DownloadModal
        openModal={isOpenDownload}
        setOpenModal={setIsOpenDownload}
      />
    </Layout>
  );
};

export default Reviews;
